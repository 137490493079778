import React, { forwardRef } from 'react';
import styled from 'styled-components';

interface EventOutgoingProps {
  size?: string;
}

const SVG = styled.svg`
  overflow: visible;
`;

export const EventOutgoing = forwardRef<SVGSVGElement, EventOutgoingProps>((props, ref) => {
  const size = props.size ?? '20px';

  return (
    <SVG
      {...props}
      ref={ref}
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.6967 4.69688V4.69688C1.76753 7.62604 1.76753 12.3744 4.6967 15.3035V15.3035C7.62587 18.2327 12.3742 18.2327 15.3034 15.3035V15.3035C18.2325 12.3744 18.2325 7.62604 15.3034 4.69688V4.69688C12.3742 1.76771 7.62587 1.76771 4.6967 4.69688Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 7.5L7.5 12.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 11.25V7.5H8.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
});
