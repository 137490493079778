import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Button, Icon, useDidMount } from '@column/column-ui-kit';
import { Accordion } from '~/components';
import { Box, Line } from '~/styles';
import { ROUTE } from '~/app/routes';
import { useSessionStore } from '~/stores/Session';
import { log } from '~/util';

interface GetStartedProps {
  showVerifyEmail: boolean;
  showAddEntity: boolean;
  showAddBankAccount: boolean;
  showSetupMfa: boolean;
  showGenerateApiKeys: boolean;
  showAddWebhook: boolean;
  showGettingStartedGuide: boolean;
}

const StyledAccordion = styled(Accordion)`
  padding: 16px;
`;

const Title = styled.div`
  cursor: pointer;
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const IconCircle = styled.div<{ isActive?: boolean }>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(200)};
  transition: box-shadow 0.2s;

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      box-shadow: inset 0 0 0 1px ${theme.secondary.blendToBackground(75)};
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      box-shadow:
        inset 0 0 0 1px ${({ theme }) => theme.primary.background},
        0 0 0 2px ${({ theme }) => theme.primary.blendToBackground(1000, 200)};
    `}

  svg {
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
    --icon-color: ${({ isActive, theme }) => isActive && theme.primary.background};

    path {
      transition:
        fill 0.2s,
        stroke 0.2s;
    }
  }
`;

const Label = styled.div<{ isActive: boolean }>`
  color: ${({ theme }) => theme.secondary.background};
  color: ${({ isActive, theme }) => isActive && theme.foreground};
  font-weight: 500;
  line-height: 18px;
  transition: color 0.2s;
`;

const Arrow = styled(Icon.ChevronRight)<{ isActive: boolean }>`
  --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  margin-left: auto;
  transform: rotate(var(--arrow-rotate, 0deg)) translateZ(0);
  transition: transform 0.2s;

  ${({ isActive }) =>
    isActive &&
    css`
      --arrow-rotate: 90deg;
    `};
`;

const Content = styled.div`
  padding: 0 28px 8px 56px;
  margin-top: -6px;

  p {
    margin: 0;
    max-width: 680px;
    color: ${({ theme }) => theme.secondary.background};
    font-size: 12px;
    line-height: 18px;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`;

export const GetStarted: React.FC<GetStartedProps> = (props) => {
  const currentUser = useSessionStore((state) => state.currentUser);
  const navigate = useNavigate();
  const [active, setActive] = useState<number>(0);
  const [running, setRunning] = useState<number>(-1);

  const didMount = useDidMount();

  const onClick = (index: number) => {
    setActive(index);
  };

  useEffect(() => {
    if (didMount) {
      setRunning(active);
    }
  }, [active]);

  const entries = [];

  if (props.showVerifyEmail) {
    entries.push({
      label: (
        <Title>
          <IconCircle isActive={active === entries.length}>
            <Icon.AnimationMail onDone={() => setRunning(-1)} running={running === entries.length} />
          </IconCircle>
          <Label isActive={active === entries.length}>Verify your email address</Label>
          <Arrow isActive={active === entries.length} />
        </Title>
      ),
      content: (
        <Content>
          <p>We will send you a link to verify your email address.</p>
          <Button onClick={() => navigate(ROUTE.PROFILE)} icon={<Icon.ArrowRight />} iconRight size="small">
            Profile
          </Button>
        </Content>
      ),
      onClick,
    });
  }
  if (props.showSetupMfa) {
    entries.push({
      label: (
        <Title>
          <IconCircle isActive={active === entries.length}>
            <Icon.AnimationPhone onDone={() => setRunning(-1)} running={running === entries.length} />
          </IconCircle>
          <Label isActive={active === entries.length}>Set up 2-factor authentication</Label>
          <Arrow isActive={active === entries.length} />
        </Title>
      ),
      content: (
        <Content>
          <p>2-factor authentication adds an extra layer of security to your account.</p>
          <Button onClick={() => navigate(ROUTE.PROFILE)} icon={<Icon.ArrowRight />} iconRight size="small">
            Profile
          </Button>
        </Content>
      ),
      onClick,
    });
  }
  if (props.showGenerateApiKeys) {
    entries.push({
      label: (
        <Title>
          <IconCircle isActive={active === entries.length}>
            <Icon.AnimationCode onDone={() => setRunning(-1)} running={running === entries.length} />
          </IconCircle>
          <Label isActive={active === entries.length}>Generate your API keys</Label>
          <Arrow isActive={active === entries.length} />
        </Title>
      ),
      content: (
        <Content>
          <p>Add your first API key to get started.</p>
          <Button onClick={() => navigate(ROUTE.PLATFORM_API_KEYS)} size="small">
            API Keys
          </Button>
        </Content>
      ),
      onClick,
    });
  }
  if (props.showAddEntity) {
    entries.push({
      label: (
        <Title>
          <IconCircle isActive={active === entries.length}>
            <Icon.AnimationSign onDone={() => setRunning(-1)} running={running === entries.length} />
          </IconCircle>
          <Label isActive={active === entries.length}>Add your first entity</Label>
          <Arrow isActive={active === entries.length} />
        </Title>
      ),
      content: (
        <Content>
          <p>Create a person or business entity before you're able to add bank accounts.</p>
          <Button onClick={() => navigate(ROUTE.ENTITIES)} icon={<Icon.ArrowRight />} iconRight size="small">
            Entities
          </Button>
        </Content>
      ),
      onClick,
    });
  }
  if (props.showAddBankAccount) {
    entries.push({
      label: (
        <Title>
          <IconCircle isActive={active === entries.length}>
            <Icon.Bank />
          </IconCircle>
          <Label isActive={active === entries.length}>Add your first bank account</Label>
          <Arrow isActive={active === entries.length} />
        </Title>
      ),
      content: (
        <Content>
          <p>Create your first bank account and assign it to an entity.</p>
          <Button onClick={() => navigate(ROUTE.BANK_ACCOUNTS)} icon={<Icon.ArrowRight />} iconRight size="small">
            Bank Accounts
          </Button>
        </Content>
      ),
      onClick,
    });
  }
  if (props.showAddWebhook) {
    entries.push({
      label: (
        <Title>
          <IconCircle isActive={active === entries.length}>
            <Icon.AnimationCode onDone={() => setRunning(-1)} running={running === entries.length} />
          </IconCircle>
          <Label isActive={active === entries.length}>Add your first webhook</Label>
          <Arrow isActive={active === entries.length} />
        </Title>
      ),
      content: (
        <Content>
          <p>Add your first webhook to receive events.</p>
          <Button onClick={() => navigate(ROUTE.PLATFORM_WEBHOOKS)} size="small">
            Webhooks
          </Button>
        </Content>
      ),
      onClick,
    });
  }
  if (props.showGettingStartedGuide) {
    const handleGettingStartedGuideClick = () => {
      log({
        name: 'Getting started guide clicked',
        context: currentUser,
      });

      window.open('https://column.com/docs/guides/getting-started-with-the-column-api', '_blank');
    };

    entries.push({
      label: (
        <Title>
          <IconCircle isActive={active === entries.length}>
            <Icon.AnimationInfo onDone={() => setRunning(-1)} running={running === entries.length} />
          </IconCircle>
          <Label isActive={active === entries.length}>Getting started with Column’s API</Label>
          <Arrow isActive={active === entries.length} />
        </Title>
      ),
      content: (
        <Content>
          <p>
            Build a basic payment workflow consisting of a wire, a book transfer, and an ACH credit with Column's APIs.
          </p>
          <Button onClick={handleGettingStartedGuideClick} icon={<Icon.External />} iconRight size="small">
            Read
          </Button>
        </Content>
      ),
      onClick,
    });
  }

  return (
    <Box>
      <StyledAccordion entries={entries} active={active} devider={<Line />} />
    </Box>
  );
};
