import { DropdownOption } from '@column/column-ui-kit';
import { client } from './client';
import { PlatformRepository } from './PlatformRepository';
import { Invite, ListInvitesResponse } from './InviteRepository';
import { convertValues } from '~/util';
import {
  AssignPlatformRoleRequest,
  PrecannedRoleName,
  CustomRolePermissionLevel,
  PlatformRolesList,
} from '~/typings/API';

export type PlatformRoleName = PrecannedRoleName;
export type PermissionLevel = CustomRolePermissionLevel;

export const userGroups: Record<PlatformRoleName, string> = {
  owner: 'Owner',
  admin: 'Admin',
  money_mover: 'Money Mover',
  developer: 'Developer',
  readonly: 'Viewer',
  check_depositor: 'Check Depositor',
};

export const userGroupsDropdown: DropdownOption[] = Object.keys(userGroups).map((value) => ({
  label: userGroups[value as PlatformRoleName],
  value,
}));

export type ListPlatformRoles = PlatformRolesList;

export type PlatformRole = ListPlatformRoles['platformRoles'][number];
export type PlatformRolePermissions = PlatformRole['platformLevelPermissions'];
export type PlatformRolePermissionKey = keyof PlatformRolePermissions;

export interface PlatformRolesExtended extends PlatformRole {
  platformName: string;
  isLiveEnabled: boolean;
}

export interface ListPlatformRolesExtended {
  platformRoles: PlatformRolesExtended[];
}

export enum MFAState {
  NONE = 'NONE',
  NOT_ENABLED = 'NOT_ENABLED',
  SETUP_IN_PROGRESS = 'SETUP_IN_PROGRESS',
  ENABLED = 'ENABLED',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export enum MFAStatus {
  NONE = 'NONE',
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED',
  NOT_REQUIRED = 'NOT_REQUIRED',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export enum EmailState {
  EMAILSTATE_NONE = 'EMAILSTATE_NONE',
  NOT_VERIFIED = 'NOT_VERIFIED',
  VERIFY_IN_PROGRESS = 'VERIFY_IN_PROGRESS',
  VERIFIED = 'VERIFIED',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export type ThemeType = 'default' | 'dark' | 'warm';
export const themeTypes: ThemeType[] = ['default', 'dark', 'warm'];

interface TablePageSettings {
  limit: number;
}

type TableSettings = Record<string, TablePageSettings>;

interface UploadSettings {
  rdcFrontImageCrop?: boolean;
  rdcBackImageCrop?: boolean;
}

export interface Settings {
  themeType: ThemeType;
  tableSettings: TableSettings;
  uploadSettings: UploadSettings;
  platformBannerDismissed: boolean;
}

export interface DashboardUser {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  emailState: EmailState;
  dashboardDisplayMode: string;
  defaultPlatformId: string;
  mfaState: MFAState;
  platformRoles: PlatformRole[];
}

export interface DashboardUserRedacted {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  platformId: string;
  platformRole: PlatformRole;
}

export interface CreateDashboardUserRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  platformId: string;
}

export interface GetDashboardUserRequest {
  dashboardUserId: string;
}

export interface UpdateDashboardUserRequest {
  dashboardUserId: string;
  phoneNumber: string;
  email: string;
  firstName: string;
  lastName: string;
  defaultPlatformId: string;
}

export interface ListDashboardUsersOnPlatformResponse {
  dashboardUsers: DashboardUserRedacted[];
}

export interface ListPlatformRolesRequest {
  dashboardUserId: string;
}

export interface ListInvitesRequest {
  dashboardUserId: string;
}

export interface SetupMFAResponse {
  dashboardUserId: string;
}

export interface UpdatePasswordRequest {
  currentPassword: string;
  newPassword: string;
}

export interface AcceptInviteToPlatformRequest {
  platformId: string;
}

export interface VerifyEmailRequest {
  emailVerificationId: string;
}

export interface VerifyEmailResponse {}

export interface DashboardLoginRequest {
  email: string;
  password: string;
}

export interface DashboardLoginResponse {
  dashboardUserId: string;
  sessionId: string;
  defaultPlatformId: string;
  mfaStatus: MFAStatus;
  phoneNumber: string;
}

export interface VerifyMFARequest {
  code: string;
}

export interface VerifyMFAResponse {
  dashboardUserId: string;
  mfaStatus: MFAStatus;
}

export interface CreatePasswordResetRequest {
  email: string;
}

export interface ResetPasswordRequest {
  passwordResetId: string;
  newPassword: string;
}

export interface RemoveUserFromPlatformRequest {
  dashboardUserId: string;
  platformId: string;
}

export class UserRepository {
  static async create(
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    platformId: string,
    reCaptchaToken: string
  ) {
    const request: CreateDashboardUserRequest = {
      firstName,
      lastName,
      email,
      password,
      platformId,
    };

    return client
      .post<CreateDashboardUserRequest, DashboardUser>('/dashboard/dashboard-users', request, {
        reCaptchaToken,
      })
      .then((response) =>
        convertValues<DashboardUser>(response, {
          mfaState: Object.values(MFAState),
          emailState: Object.values(EmailState),
        })
      );
  }

  static async createPasswordReset(email: string, reCaptchaToken: string) {
    const request: CreatePasswordResetRequest = {
      email,
    };

    return client.post<CreatePasswordResetRequest, object>('/dashboard/password_reset_request', request, {
      reCaptchaToken,
    });
  }

  static async passwordReset(newPassword: string, passwordResetId: string, reCaptchaToken: string) {
    const request: ResetPasswordRequest = {
      newPassword,
      passwordResetId,
    };

    return client.post<ResetPasswordRequest, object>(`/dashboard/reset_password/${passwordResetId}`, request, {
      reCaptchaToken,
    });
  }

  static async login(email: string, password: string, reCaptchaToken: string) {
    const request: DashboardLoginRequest = {
      email,
      password,
    };

    return client
      .post<DashboardLoginRequest, DashboardLoginResponse>('/dashboard/login', request, {
        reCaptchaToken,
      })
      .then((response) => convertValues<DashboardLoginResponse>(response, { mfaStatus: Object.values(MFAStatus) }));
  }

  static async updateSettings(request: Partial<Settings>) {
    return client
      .patch<Partial<Settings>, Partial<Settings>>('/dashboard/dashboard-users/settings', request, {
        sandbox: true,
      })
      .then((response) => convertValues<Partial<Settings>>(response));
  }

  static async getSettings() {
    return client
      .get<unknown, Partial<Settings>>('/dashboard/dashboard-users/settings', { sandbox: true })
      .then((response) => convertValues<Partial<Settings>>(response));
  }

  static async verifyMfa(code: string, reCaptchaToken: string) {
    const request: VerifyMFARequest = {
      code,
    };

    return client
      .post<VerifyMFARequest, VerifyMFAResponse>('/dashboard/mfa/verify', request, { sandbox: true, reCaptchaToken })
      .then((response) => convertValues<VerifyMFAResponse>(response, { mfaStatus: Object.values(MFAStatus) }));
  }

  static async logout() {
    return client.post('/dashboard/logout', {});
  }

  static async fetch(userId: string) {
    return client.get<GetDashboardUserRequest, DashboardUserRedacted>(`/dashboard/dashboard-users/${userId}`, {
      sandbox: true,
    });
  }

  static async fetchSelf() {
    return client.get<unknown, DashboardUser>('/dashboard/self', { sandbox: true }).then((response) =>
      convertValues<DashboardUser>(response, {
        mfaState: Object.values(MFAState),
        emailState: Object.values(EmailState),
      })
    );
  }

  static async update(attrs: Partial<UpdateDashboardUserRequest>, reCaptchaToken: string) {
    return client
      .patch<
        Partial<UpdateDashboardUserRequest>,
        DashboardUser
      >(`/dashboard/dashboard-users/${attrs.dashboardUserId}`, attrs, { sandbox: true, reCaptchaToken })
      .then((response) =>
        convertValues<DashboardUser>(response, {
          mfaState: Object.values(MFAState),
          emailState: Object.values(EmailState),
        })
      );
  }

  static async updatePassword(request: UpdatePasswordRequest) {
    return client.patch<UpdatePasswordRequest, object>('/dashboard/dashboard-users/password', request, {
      sandbox: true,
    });
  }

  static async setupMfa(reCaptchaToken: string) {
    return client
      .post<object, SetupMFAResponse>('/dashboard/mfa/setup', {}, { sandbox: true, reCaptchaToken })
      .then((response) => convertValues<SetupMFAResponse>(response));
  }

  static async resendMfa(reCaptchaToken: string) {
    return client.post<object, object>('/dashboard/mfa/resend', {}, { sandbox: true, reCaptchaToken });
  }

  static async createVerifyEmail() {
    return client.post<object, object>('/dashboard/email_verifications', {}, { sandbox: true });
  }

  static async verifyEmail(code: string) {
    return client
      .get<VerifyEmailRequest, VerifyEmailResponse>(`/dashboard/email_verifications/verify/${code}`, { sandbox: true })
      .then((response) => convertValues<VerifyEmailResponse>(response));
  }

  static async acceptInvite(platformId: string) {
    return client.post<AcceptInviteToPlatformRequest, object>(
      `/dashboard/dashboard-users/accept_platform_invite/${platformId}`,
      { platformId },
      { sandbox: true }
    );
  }

  static async getInvites(platformId: string) {
    return client
      .get<ListInvitesRequest, ListInvitesResponse>(`/dashboard/dashboard-users/${platformId}/invites`, {
        sandbox: true,
      })
      .then((response) => ({
        invites: Array.isArray(response?.invites) ? response.invites.map((e) => convertValues<Invite>(e)) : [],
      }));
  }

  static async platformRoles(userId: string) {
    return client
      .get<ListPlatformRolesRequest, ListPlatformRolesExtended>(`/dashboard/dashboard-users/${userId}/platform-roles`, {
        sandbox: true,
      })
      .then((response) => ({
        platformRoles: Array.isArray(response?.platformRoles)
          ? response.platformRoles.map((e) => convertValues<PlatformRole>(e))
          : [],
      }))
      .then(async (response) => {
        const platformRoles = await Promise.all(
          response.platformRoles.map(async (platformRole: PlatformRole) => {
            const platform = await PlatformRepository.get(platformRole.platformId, false);

            return {
              ...platformRole,
              platformName: platform.name,
              isLiveEnabled: platform.isLiveEnabled,
            };
          })
        );

        return {
          platformRoles,
        };
      });
  }

  static async platformRolesWithUser(userId: string) {
    return this.platformRoles(userId).then(async (response) => {
      const platformRoles = await Promise.all(
        response.platformRoles.map(async (platformRole) => {
          const users = await this.getAll(platformRole.platformId);

          return {
            ...platformRole,
            dashboardUsers: users.dashboardUsers,
          };
        })
      );

      return {
        platformRoles,
      };
    });
  }

  static async getAll(platformId?: string) {
    return client.get<unknown, ListDashboardUsersOnPlatformResponse>(
      `/dashboard/dashboard_users?platformId=${platformId}`,
      { platformId, sandbox: true }
    );
  }

  static async updatePlatformRole(platformId: string, request: AssignPlatformRoleRequest) {
    return client
      .post<AssignPlatformRoleRequest, ListPlatformRoles>(
        `/dashboard/dashboard-users/${request.userId}/assign-platform-role`,
        request,
        {
          platformId,
        }
      )
      .then((response) => ({
        platformRoles: Array.isArray(response?.platformRoles)
          ? response.platformRoles.map((e) => convertValues<PlatformRole>(e))
          : [],
      }));
  }

  static async removeUserFromPlatform(request: RemoveUserFromPlatformRequest) {
    return client.delete<object, object>(`/dashboard/dashboard-users/${request.dashboardUserId}`, {
      platformId: request.platformId,
      sandbox: true,
    });
  }
}
