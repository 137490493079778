import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { log } from './log';

type SuccessCallback = (reCaptchaToken: string) => void;
type ErrorCallback = (error: Error) => void;

export const reCaptchaCheck = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptcha = useCallback(
    (onSuccess: SuccessCallback, onError: ErrorCallback) => {
      if (!executeRecaptcha) {
        return;
      }

      executeRecaptcha('submit')
        .then(onSuccess)
        .catch((error) => {
          onError(new Error('reCaptcha error'));

          log({ name: 'reCaptcha error', type: 'error', context: { error } });
        });
    },
    [executeRecaptcha]
  );

  return { handleReCaptcha };
};
