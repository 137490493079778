import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Icon } from '@column/column-ui-kit';
import { ReturnSection } from '../Transfers/View';
import {
  EditBox,
  EditSection,
  EditSectionTitle,
  EditPage,
  EditToolbar,
  EditTitle,
  Headline,
  FormElement,
  FormLabel,
} from '~/styles';
import { Breadcrumb, CopyInput, NotificationList } from '~/components';
import { ROUTE } from '~/app/routes';
import { AchRepository, AchReturn } from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';

interface Params {
  achTransferId: string;
}

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-self: end;
  justify-self: start;
`;

export const PageReturnsView: React.FC = () => {
  const currentUser = useSessionStore((state) => state.currentUser);
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);
  const navigate = useNavigate();
  const { achTransferId } = useParams<keyof Params>() as Params;
  const [achReturn, setAchReturn] = useState<DeepPartial<AchReturn>>({});

  const handlePromiseError = (promise: any, callback: (response: any) => void) => {
    return promise
      .then((response: any) => {
        callback(response);
      })
      .catch((error: any) => {
        navigate(ROUTE.RETURNS);
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    if (achTransferId) {
      handlePromiseError(AchRepository.getReturn(achTransferId), (response) => {
        setAchReturn(response);
      });
    }
  }, [achTransferId]);

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => ({
          isSandbox: state.isSandbox,
          isLoading: state.isLoading,
          defaultPlatformId: state.currentUser?.defaultPlatformId,
        }),
        () => {
          if (!currentUser) {
            return;
          }
          if (achTransferId) {
            handlePromiseError(AchRepository.getReturn(achTransferId), setAchReturn);
          }
        }
      ),
    []
  );

  return (
    <EditPage>
      <EditToolbar>
        <EditTitle>
          <Headline>View return</Headline>
          <Breadcrumb
            entries={[
              {
                label: (
                  <>
                    <Icon.Swap />
                    Transfers
                  </>
                ),
                path: ROUTE.TRANSFERS,
              },
              {
                label: 'Returns',
                path: ROUTE.RETURNS,
              },
              {
                label: 'View',
              },
            ]}
          />
        </EditTitle>
      </EditToolbar>
      <div>
        <NotificationList display="page" />
        <EditPage>
          <EditSection>
            <EditSectionTitle>
              <Icon.CircleInfo />
              Information
            </EditSectionTitle>
            <EditBox>
              <FormElement>
                <FormLabel>ACH Transfer ID</FormLabel>
                <CopyInput value={achTransferId} />
              </FormElement>
              <List>
                <Button
                  variant="secondary"
                  onClick={() => navigate(`${ROUTE.TRANSFERS}/edit/ach/${achTransferId}`)}
                  icon={<Icon.ArrowRight />}
                >
                  View ACH Transfer
                </Button>
              </List>
            </EditBox>
          </EditSection>
          <ReturnSection achReturn={achReturn} />
        </EditPage>
      </div>
      <EditToolbar>
        <Button onClick={() => navigate(-1)} variant="secondary">
          Back
        </Button>
      </EditToolbar>
    </EditPage>
  );
};
