import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { DropdownElement, Icon, Input, Button, Toggle, Dropdown, Chip, Tooltip } from '@column/column-ui-kit';
import {
  Divider,
  EditBox,
  EditSection,
  EditSectionTitle,
  EditPage,
  EditToolbar,
  EditTitle,
  FormElement,
  FormLabel,
  FormText,
  Headline,
} from '~/styles';
import { Breadcrumb, CopyInput, NotificationList, AddressField, RestrictedBanner } from '~/components';
import { ROUTE } from '~/app/routes';
import { CreateCounterparty, CounterpartyRepository } from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { formatString, validateRoutingNumber } from '~/util';
import { useAddress } from '~/hooks';

interface Params {
  id: string;
}

interface RoutingNumberFieldProps {
  routingNumber: string;
  onRoutingNumberChange: (routingNumber: string) => void;
}

const StyledFormElement = styled(FormElement)`
  position: relative;
`;

const SuccessRoutingNumber = styled.div<{ show: boolean }>`
  max-height: 0;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.success.background};
  display: flex;
  align-items: center;
  gap: 4px;
  opacity: 0;
  pointer-events: none;
  margin-top: -4px;
  transition:
    max-height 0.2s,
    opacity 0.2s,
    margin-top 0.2s;

  ${({ show }) =>
    show &&
    css`
      margin-top: 8px;
      max-height: 1000px;
      opacity: 1;
      pointer-events: auto;

      svg {
        animation: scale 0.4s ease 0.2s;
        path {
          &:last-child {
            transition-delay: 0.15s;
          }
        }
      }
    `}

  ${({ show }) =>
    !show &&
    css`
      opacity: 0;
      max-height: 0;

      svg {
        path {
          &:first-child {
            stroke-dashoffset: 48px;
          }
          &:last-child {
            stroke-dashoffset: 9px;
          }
        }
      }
    `}

  @keyframes scale {
    50% {
      transform: scale(1.15);
    }
  }

  svg {
    display: inline-block;
    vertical-align: top;
    --icon-size: 16px;

    path {
      &:first-child {
        stroke-dasharray: 48px;
        transition: stroke-dashoffset 0.15s;
      }

      &:last-child {
        stroke-dasharray: 9px;
        transition: stroke-dashoffset 0.25s;
      }
    }
  }

  span {
    display: inline-block;
    vertical-align: top;
    line-height: 16px;
  }
`;

const Check = styled(Icon.CircleCheck)`
  --icon-size: 16px;
  --icon-color: currentColor;
`;

export const RoutingNumberField: React.FC<RoutingNumberFieldProps> = (props) => {
  const [valid, setValid] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [shortName, setShortName] = useState<string>('');

  const handleChange = (value: string) => {
    if (props.onRoutingNumberChange) {
      props.onRoutingNumberChange(value);
    }
  };

  useEffect(() => {
    if (validateRoutingNumber(props.routingNumber)) {
      CounterpartyRepository.institution(props.routingNumber)
        .then((response) => {
          if (!response.fullName || !response.shortName) {
            return;
          }

          setValid(true);
          setName(response.fullName);
          setShortName(response.shortName);
        })
        .catch(() => {
          setValid(false);
        });
      return;
    }
    setValid(false);
  }, [props.routingNumber]);

  return (
    <StyledFormElement newRow>
      <FormLabel>Routing Number</FormLabel>
      <Input onChange={handleChange} value={props.routingNumber ?? ''} placeholder="Routing number" />
      <SuccessRoutingNumber show={valid}>
        <Check />
        <Tooltip content={shortName}>{name}</Tooltip>
      </SuccessRoutingNumber>
    </StyledFormElement>
  );
};

export const PageCounterpartiesEdit: React.FC = () => {
  const { currentUser, currentPermission } = useSessionStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const navigate = useNavigate();
  const { id } = useParams<keyof Params>() as Params;
  const [counterparty, setCounterparty] = useState<DeepPartial<CreateCounterparty>>({});
  const { states, countries } = useAddress({ types: ['state', 'country'] });
  const stateRef = useRef<DropdownElement>(null);

  const handleSubmit = () => {
    if (!currentUser) {
      return;
    }

    if (id) {
      return;
    }

    CounterpartyRepository.create(counterparty as CreateCounterparty)
      .then(() => {
        navigate(ROUTE.COUNTERPARTIES);
        addSuccessNotification({
          content: 'Counterparty added',
          display: 'page',
        });
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  const fetchCounterparty = () => {
    CounterpartyRepository.get({ id })
      .then((response) => {
        setCounterparty(response);
      })
      .catch((error) => {
        navigate(ROUTE.COUNTERPARTIES);
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    if (id) {
      fetchCounterparty();
    }
  }, [id]);

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => ({
          isSandbox: state.isSandbox,
          isLoading: state.isLoading,
          defaultPlatformId: state.currentUser?.defaultPlatformId,
        }),
        () => {
          if (!currentUser) {
            return;
          }
          if (id) {
            fetchCounterparty();
          }
        }
      ),
    []
  );

  return (
    <EditPage>
      <EditTitle>
        <Headline>{id ? 'View counterparty' : 'New counterparty'}</Headline>
        <Breadcrumb
          entries={[
            {
              label: (
                <>
                  <Icon.Wallet />
                  Accounts
                </>
              ),
              path: ROUTE.BANK_ACCOUNTS,
            },
            {
              label: 'Counterparties',
              path: ROUTE.COUNTERPARTIES,
            },
            {
              label: id ? 'View' : 'Create',
            },
          ]}
        />
      </EditTitle>

      {currentPermission?.counterparties !== 'write' && !id && <RestrictedBanner />}

      <div>
        <NotificationList display="page" />
        <EditPage>
          <EditSection>
            <EditSectionTitle>
              <Icon.CircleInfo />
              Information
            </EditSectionTitle>
            <EditBox data-disabled={currentPermission?.counterparties !== 'write'}>
              <FormElement>
                <FormLabel>Description</FormLabel>
                {id ? (
                  <FormText>{counterparty.description ?? '-'}</FormText>
                ) : (
                  <Input
                    onChange={(value: string) => setCounterparty({ ...counterparty, description: value })}
                    value={counterparty.description ?? ''}
                    placeholder="Description"
                  />
                )}
              </FormElement>
              {id && (
                <FormElement>
                  <FormLabel>ID</FormLabel>
                  <CopyInput value={id} />
                </FormElement>
              )}
              {id ? (
                <FormElement newRow>
                  <FormLabel>Routing Number</FormLabel>
                  <FormText>{counterparty.routingNumber}</FormText>
                </FormElement>
              ) : (
                <RoutingNumberField
                  routingNumber={counterparty.routingNumber ?? ''}
                  onRoutingNumberChange={(routingNumber: string) => setCounterparty({ ...counterparty, routingNumber })}
                />
              )}
              <FormElement>
                <FormLabel>Account Number</FormLabel>
                {id ? (
                  <FormText>{counterparty.accountNumber}</FormText>
                ) : (
                  <Input
                    onChange={(value: string) => setCounterparty({ ...counterparty, accountNumber: value })}
                    value={counterparty.accountNumber ?? ''}
                    placeholder="Account number"
                  />
                )}
              </FormElement>
              <FormElement newRow>
                <FormLabel>Routing Number Type {!id && <small>optional</small>}</FormLabel>
                {id ? (
                  <FormText>{(counterparty?.routingNumberType ?? '-').toUpperCase()}</FormText>
                ) : (
                  <Dropdown
                    variant="muted"
                    fullWidth
                    active={counterparty?.routingNumberType ?? ''}
                    onChange={(value: string) =>
                      setCounterparty({
                        ...counterparty,
                        routingNumberType: value,
                      })
                    }
                    options={[
                      {
                        label: 'ABA',
                        value: 'aba',
                      },
                      {
                        label: 'BIC',
                        value: 'bic',
                      },
                    ]}
                  />
                )}
              </FormElement>
            </EditBox>
          </EditSection>
          <EditSection>
            <EditSectionTitle>
              <Icon.Money />
              Wire
            </EditSectionTitle>
            <EditBox data-disabled={currentPermission?.counterparties !== 'write'}>
              <FormElement newRow>
                <FormLabel>Beneficiary Name</FormLabel>
                {id ? (
                  <FormText>{counterparty.wire?.beneficiaryName ?? '-'}</FormText>
                ) : (
                  <Input
                    onChange={(value: string) =>
                      setCounterparty({ ...counterparty, wire: { ...counterparty.wire, beneficiaryName: value } })
                    }
                    value={counterparty.wire?.beneficiaryName ?? ''}
                    placeholder="Beneficiary name"
                  />
                )}
              </FormElement>
              <FormElement>
                <FormLabel>Wire Drawdown Allowed</FormLabel>
                <FormText>
                  {id ? (
                    <Chip
                      type={counterparty.wireDrawdownAllowed ? 'info' : 'default'}
                      icon={counterparty.wireDrawdownAllowed ? <Icon.CircleCheck /> : <Icon.CircleCross />}
                    >
                      {counterparty.wireDrawdownAllowed ? 'True' : 'False'}
                    </Chip>
                  ) : (
                    <Toggle
                      onCheckedChange={(value: boolean) =>
                        setCounterparty({ ...counterparty, wireDrawdownAllowed: value })
                      }
                      isChecked={counterparty.wireDrawdownAllowed ?? false}
                    />
                  )}
                </FormText>
              </FormElement>
              <FormElement newRow>
                <FormLabel>Beneficiary Email</FormLabel>
                {id ? (
                  <FormText>{counterparty.wire?.beneficiaryEmail ?? '-'}</FormText>
                ) : (
                  <Input
                    onChange={(value: string) =>
                      setCounterparty({ ...counterparty, wire: { ...counterparty.wire, beneficiaryEmail: value } })
                    }
                    value={counterparty.wire?.beneficiaryEmail ?? ''}
                    placeholder="Beneficiary email"
                  />
                )}
              </FormElement>
              <FormElement>
                <FormLabel>Beneficiary Phone</FormLabel>
                {id ? (
                  <FormText>{counterparty.wire?.beneficiaryPhone ?? '-'}</FormText>
                ) : (
                  <Input
                    onChange={(value: string) =>
                      setCounterparty({ ...counterparty, wire: { ...counterparty.wire, beneficiaryPhone: value } })
                    }
                    value={counterparty.wire?.beneficiaryPhone ?? ''}
                    placeholder="Beneficiary phone"
                  />
                )}
              </FormElement>
              <FormElement>
                <FormLabel>Beneficiary Legal ID</FormLabel>
                {id ? (
                  <FormText>{counterparty.wire?.beneficiaryLegalId ?? '-'}</FormText>
                ) : (
                  <Input
                    onChange={(value: string) =>
                      setCounterparty({
                        ...counterparty,
                        wire: { ...counterparty.wire, beneficiaryLegalId: value },
                      })
                    }
                    value={counterparty.wire?.beneficiaryLegalId ?? ''}
                    placeholder="Beneficiary legal ID"
                  />
                )}
              </FormElement>
              <FormElement>
                <FormLabel>Beneficiary Type</FormLabel>
                {id ? (
                  <FormText>{formatString(counterparty.wire?.beneficiaryType ?? '-')}</FormText>
                ) : (
                  <Dropdown
                    variant="muted"
                    fullWidth
                    active={counterparty.wire?.beneficiaryType ?? ''}
                    onChange={(value: string) =>
                      setCounterparty({ ...counterparty, wire: { ...counterparty.wire, beneficiaryType: value } })
                    }
                    options={[
                      {
                        label: 'Business',
                        value: 'business',
                      },
                      {
                        label: 'Nonprofit',
                        value: 'non_profit',
                      },
                      {
                        label: 'Individual',
                        value: 'individual',
                      },
                      {
                        label: 'Sole Proprietor',
                        value: 'sole_proprietor',
                      },
                    ]}
                  />
                )}
              </FormElement>
              <FormElement>
                <FormLabel>Local Bank Code</FormLabel>
                {id ? (
                  <FormText>{counterparty.wire?.localBankCode ?? '-'}</FormText>
                ) : (
                  <Input
                    onChange={(value: string) =>
                      setCounterparty({ ...counterparty, wire: { ...counterparty.wire, localBankCode: value } })
                    }
                    value={counterparty.wire?.localBankCode ?? ''}
                    placeholder="Local bank code"
                  />
                )}
              </FormElement>
              <FormElement>
                <FormLabel>Local Account Number</FormLabel>
                {id ? (
                  <FormText>{counterparty.wire?.localAccountNumber ?? '-'}</FormText>
                ) : (
                  <Input
                    onChange={(value: string) =>
                      setCounterparty({
                        ...counterparty,
                        wire: { ...counterparty.wire, localAccountNumber: value },
                      })
                    }
                    value={counterparty.wire?.localAccountNumber ?? ''}
                    placeholder="Local account number"
                  />
                )}
              </FormElement>
              <Divider fullWidth>Beneficiary Address</Divider>
              {!id && (
                <>
                  <FormElement fullWidth>
                    <AddressField
                      onChange={(a) => {
                        setCounterparty({
                          ...counterparty,
                          wire: {
                            ...counterparty.wire,
                            beneficiaryAddress: a,
                          },
                        });
                        if (!a.state) {
                          stateRef.current?.open();
                        }
                      }}
                    />
                  </FormElement>
                  <Divider fullWidth>or</Divider>
                </>
              )}
              <FormElement>
                <FormLabel>Line 1</FormLabel>
                {id ? (
                  <FormText>{counterparty.wire?.beneficiaryAddress?.line1 ?? '-'}</FormText>
                ) : (
                  <Input
                    onChange={(value: string) =>
                      setCounterparty({
                        ...counterparty,
                        wire: {
                          ...counterparty.wire,
                          beneficiaryAddress: { ...counterparty.wire.beneficiaryAddress, line1: value },
                        },
                      })
                    }
                    value={counterparty.wire?.beneficiaryAddress?.line1 ?? ''}
                    placeholder="Line 1"
                  />
                )}
              </FormElement>
              <FormElement>
                <FormLabel>Line 2</FormLabel>
                {id ? (
                  <FormText>{counterparty.wire?.beneficiaryAddress?.line2 ?? '-'}</FormText>
                ) : (
                  <Input
                    onChange={(value: string) =>
                      setCounterparty({
                        ...counterparty,
                        wire: {
                          ...counterparty.wire,
                          beneficiaryAddress: { ...counterparty.wire.beneficiaryAddress, line2: value },
                        },
                      })
                    }
                    value={counterparty.wire?.beneficiaryAddress?.line2 ?? ''}
                    placeholder="Line 2"
                  />
                )}
              </FormElement>
              <FormElement>
                <FormLabel>City</FormLabel>
                {id ? (
                  <FormText>{counterparty.wire?.beneficiaryAddress?.city ?? '-'}</FormText>
                ) : (
                  <Input
                    onChange={(value: string) =>
                      setCounterparty({
                        ...counterparty,
                        wire: {
                          ...counterparty.wire,
                          beneficiaryAddress: { ...counterparty.wire.beneficiaryAddress, city: value },
                        },
                      })
                    }
                    value={counterparty.wire?.beneficiaryAddress?.city ?? ''}
                    placeholder="City"
                  />
                )}
              </FormElement>
              <FormElement>
                <FormLabel>Country</FormLabel>
                {id ? (
                  <FormText>{counterparty.wire?.beneficiaryAddress?.countryCode ?? '-'}</FormText>
                ) : (
                  <Dropdown
                    variant="muted"
                    fullWidth
                    active={counterparty.wire?.beneficiaryAddress?.countryCode ?? ''}
                    onChange={(value: string) =>
                      setCounterparty({
                        ...counterparty,
                        wire: {
                          ...counterparty.wire,
                          beneficiaryAddress: { ...counterparty.wire?.beneficiaryAddress, countryCode: value },
                        },
                      })
                    }
                    options={countries.map((c) => ({
                      label: c.name,
                      value: c.code,
                    }))}
                    search
                  />
                )}
              </FormElement>
              <FormElement>
                <FormLabel>Postal Code</FormLabel>
                {id ? (
                  <FormText>{counterparty.wire?.beneficiaryAddress?.postalCode ?? '-'}</FormText>
                ) : (
                  <Input
                    onChange={(value: string) =>
                      setCounterparty({
                        ...counterparty,
                        wire: {
                          ...counterparty.wire,
                          beneficiaryAddress: { ...counterparty.wire.beneficiaryAddress, postalCode: value },
                        },
                      })
                    }
                    value={counterparty.wire?.beneficiaryAddress?.postalCode ?? ''}
                    placeholder="Postal code"
                  />
                )}
              </FormElement>
              <FormElement>
                <FormLabel>State</FormLabel>
                {id ? (
                  <FormText>{counterparty.wire?.beneficiaryAddress?.state ?? '-'}</FormText>
                ) : (
                  <Dropdown
                    ref={stateRef}
                    variant="muted"
                    fullWidth
                    active={counterparty.wire?.beneficiaryAddress?.state ?? ''}
                    onChange={(value: string) =>
                      setCounterparty({
                        ...counterparty,
                        wire: {
                          ...counterparty.wire,
                          beneficiaryAddress: { ...counterparty.wire.beneficiaryAddress, state: value },
                        },
                      })
                    }
                    options={
                      counterparty.wire?.beneficiaryAddress?.countryCode
                        ? states
                            .filter((s) => s.country_code === counterparty.wire?.beneficiaryAddress?.countryCode)
                            .map((s) => ({
                              label: `${s.name} (${s.state_code})`,
                              value: s.state_code,
                            }))
                        : []
                    }
                    search
                  />
                )}
              </FormElement>
            </EditBox>
          </EditSection>
        </EditPage>
      </div>

      <EditToolbar>
        <Button onClick={() => navigate(-1)} variant="secondary">
          {id ? 'Back' : 'Cancel'}
        </Button>
        {!id && (
          <Button onClick={handleSubmit} isDisabled={currentPermission?.counterparties !== 'write'}>
            Create
          </Button>
        )}
      </EditToolbar>
    </EditPage>
  );
};
