import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Fade, Dropdown, Chip, Icon, Button } from '@column/column-ui-kit';
import { Table, TableColumn } from '~/components';
import { LogoLoading } from '~/elements';
import { ROUTE } from '~/app/routes';
import { UserRepository, userGroups } from '~/repositories';
import { useSessionStore } from '~/stores/Session';
import { isValidDate } from '~/util';

import { useHelpSidebarStore } from '~/stores/HelpSidebar';
import { useNotificationStore } from '~/stores/Notification';

export interface PlatformList {
  id?: string;
  name?: string;
  role?: string;
  hasPermission?: boolean;
  created?: Date | undefined;
  action?: ReactNode;
}

const Relative = styled.div`
  position: relative;
`;

const StyledLoading = styled(LogoLoading)`
  top: 80px;
`;

const RoleHelpButton = styled(Button)`
  padding: 0;
`;

export const PagePlatforms: React.FC = () => {
  const currentUser = useSessionStore((s) => s.currentUser);
  const openHelpSidebar = useHelpSidebarStore((state) => state.openHelpSidebar);
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);
  const navigate = useNavigate();
  const [platformList, setPlatformList] = useState<PlatformList[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchData = () => {
    if (!currentUser) {
      return;
    }

    UserRepository.platformRoles(currentUser.id)
      .then((response) => {
        const entries: PlatformList[] = [];
        if (response.platformRoles.length) {
          response.platformRoles.map((entry) => {
            entries.push({
              id: entry.platformId,
              name: entry.platformName,
              role: userGroups[entry.name],
              hasPermission: entry.platformLevelPermissions.platformInfo !== 'none',
              created: entry.createdAt as any,
              action: entry.platformLevelPermissions.platformInfo !== 'none' && (
                <Dropdown
                  isInside
                  positionRight
                  options={[
                    {
                      label: 'Edit',
                      onClick: () => navigate(`${ROUTE.PLATFORMS}/edit/${entry.platformId}`),
                    },
                  ]}
                  variant="dots"
                />
              ),
            });
          });
        }
        setPlatformList(entries);
      })
      .then(() => {
        setLoading(false);
      })
      .catch((e) => console.error('UserRepository.platformRolesWithUser', e));
  };

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => ({
          isLoading: state.isLoading,
          defaultPlatformId: state.currentUser?.defaultPlatformId,
        }),
        () => fetchData(),
        { fireImmediately: true }
      ),
    []
  );

  const columns: TableColumn[] = [
    {
      Header: 'Name',
      accessor: 'name',
      percent: 35,
    },
    {
      Header: () => (
        <RoleHelpButton
          icon={<Icon.CircleQuestionmark />}
          onClick={() => openHelpSidebar('Roles')}
          variant="subtle"
          size="tiny"
        >
          Role
        </RoleHelpButton>
      ),
      Cell: (current) => <Chip>{current.value}</Chip>,
      accessor: 'role',
    },
    {
      Header: 'Created',
      Cell: (current) => (isValidDate(current.value) ? current.value.toLocaleDateString('en-US') : '-'),
      accessor: 'created',
      sortType: 'datetime',
    },
    {
      Header: '',
      accessor: 'action',
    },
  ];

  return (
    <Relative>
      <Fade show={loading} base={StyledLoading} />
      <Fade show={!loading}>
        <Table
          columns={columns}
          data={platformList}
          onRowClick={(row) => {
            const originalRow: PlatformList = row.original;

            if (!originalRow.hasPermission) {
              addDangerNotification({
                display: 'page',
                content: "You don't have permissions to view this page.",
                actionLabel: 'Roles',
                onActionClick: () => openHelpSidebar('Roles'),
              });
              return;
            }

            navigate(`${ROUTE.PLATFORMS}/edit/${originalRow.id}`);
          }}
        />
      </Fade>
    </Relative>
  );
};
