import React from 'react';
import styled from 'styled-components';
import { Button, Icon } from '@column/column-ui-kit';
import { useSessionStore } from '~/stores/Session';
import { log } from '~/util';

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const Link = styled.a`
  cursor: pointer;
  color: ${({ theme }) => theme.secondary.blendToBackground(600)};
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  transition: color 0.2s;

  &:hover {
    color: ${({ theme }) => theme.primary.background};
  }
`;

export const Quicklinks: React.FC = () => {
  const currentUser = useSessionStore((state) => state.currentUser);

  const handleClick = () => {
    log({
      name: 'Documentation clicked',
      context: currentUser,
    });

    window.open('https://column.com/docs');
  };

  return (
    <Wrapper>
      <Link onClick={handleClick}>Documentation</Link>
    </Wrapper>
  );
};
