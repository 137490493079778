import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams, useOutletContext } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Icon, Input, Button, Toggle, Dropdown, DropdownOption, ToggleHeight } from '@column/column-ui-kit';
import { BankAccountRouteProps } from './Route';
import {
  EditBox,
  EditPage,
  EditSection,
  EditSectionTitle,
  EditTitle,
  EditToolbar,
  FormElement,
  FormLabel,
  FormParagraphLight,
  FormText,
  Headline,
} from '~/styles';
import { Breadcrumb, CopyInput, Notification, NotificationList } from '~/components';
import { ROUTE } from '~/app/routes';
import {
  AccountNumber,
  CreateBankAccount,
  BankAccount,
  BankAccountRepository,
  UpdateBankAccount,
} from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { useModalStore } from '~/stores/Modal';
import { useSessionStore } from '~/stores/Session';
import { formatNumber } from '~/util';
import { PageTabNavigation } from '~/app/Layout';
import { useBankAccounts, useEntities } from '~/hooks';

interface Params {
  id: string;
}

export const TabNavigation = styled(PageTabNavigation)`
  margin-top: 0;
  margin-bottom: 0;
`;

const AddEntity = styled(Link)`
  display: inline-block;
  text-decoration: none;
  color: ${({ theme }) => theme.primary.background};
`;

const ToggleTitle = styled(EditSectionTitle)`
  cursor: pointer;

  span {
    color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  }
`;

const Up = styled(Icon.ChevronUpLarge)`
  cursor: pointer;
  margin-left: auto;
`;

const Down = styled(Icon.ChevronDownLarge)`
  cursor: pointer;
  margin-left: auto;
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  padding: 20px;
  border-radius: 12px;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(150)};
`;

const StyledToggleHeight = styled(ToggleHeight)`
  --toggle-height-padding: 16px 0 0 0;
`;

const Plus = styled(Icon.Plus)<{ isOpen: boolean }>`
  transition: transform 0.2s;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(45deg);
    `}
`;

const CreateAccountNumber = styled(Button)`
  align-self: end;
  justify-self: start;
`;

const SandboxWarning = styled(Notification)`
  grid-column: span 2;
  margin-top: -12px;
`;

export const Balance = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 20px;
  grid-column: 1 / 3;
`;

export const FormTextAmount = styled(FormText)<{ isNegative?: boolean; isAvailable?: boolean }>`
  font-size: 24px;
  font-weight: 600;
  padding: 2px 0;
  color: ${({ theme }) => theme.secondary.background};

  ${({ isNegative }) =>
    isNegative &&
    css`
      color: ${({ theme }) => theme.danger.background};
    `}

  ${({ isAvailable, isNegative }) =>
    isAvailable &&
    !isNegative &&
    css`
      color: ${({ theme }) => theme.primary.background};
    `}
`;

const Buttons = styled.div`
  display: flex;
  gap: 8px;
`;

export const FormLabelAmount = styled(FormLabel)`
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

export const PageBankAccountsEdit: React.FC = () => {
  const { isSandbox, currentUser, currentPlatform, currentPermission } = useSessionStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const openModal = useModalStore((state) => state.openModal);
  const navigate = useNavigate();
  const { id } = useParams<keyof Params>() as Params;
  const [accountNumbers, setAccountNumbers] = useState<DeepPartial<AccountNumber[]>>([{}]);
  const [accountNumbersOpen, setAccountNumbersOpen] = useState<number>(0);
  const [accountNumbersAdd, setAccountNumbersAdd] = useState<DeepPartial<AccountNumber>>({});
  const [accountNumbersAddOpen, setAccountNumbersAddOpen] = useState<boolean>(false);

  const outletContext = useOutletContext<BankAccountRouteProps>();
  const bankAccount = outletContext?.bankAccount;
  const [bankAccountState, setBankAccountState] = useState<Partial<BankAccount & { entityId: string }>>(
    bankAccount ?? {}
  );

  const { response: bankAccountList } = useBankAccounts({ initialQueryParams: { type: 'OVERDRAFT_RESERVE' } });
  const bankAccountOptions = useMemo<DropdownOption[]>(() => {
    const entries: DropdownOption[] = [];
    bankAccountList?.bankAccounts?.map((entry) => {
      entries.push({
        label: `${
          entry?.displayName && entry?.description
            ? `${entry.displayName} – ${entry.description}`
            : entry?.description || 'Unnamed'
        } (${formatNumber(entry?.balances?.availableAmount)})`,
        small: entry.id,
        value: entry.id,
      });
    });
    return entries;
  }, [bankAccountList]);

  const { response: entityList, setQueryParams: searchEntities } = useEntities();
  const entitiesOptions = useMemo<DropdownOption[]>(() => {
    return (
      entityList?.entities?.map((entity) => ({
        label: entity.name,
        value: entity.id,
      })) ?? []
    );
  }, [entityList]);

  const handleSubmit = () => {
    if (!currentUser) {
      return;
    }

    if (id) {
      const request: Partial<UpdateBankAccount> = {
        description: String(bankAccountState.description),
        displayName: bankAccountState.displayName,
        isOverdraftable: bankAccountState.isOverdraftable,
        overdraftReserveAccountId: bankAccountState.overdraftReserveAccountId,
      };

      if (bankAccountState.isOverdraftable === false) {
        delete request.overdraftReserveAccountId;
      }

      BankAccountRepository.update(id, request)
        .then((response) => {
          addSuccessNotification({
            content:
              response.displayName || response.description
                ? `${response.displayName || response.description} updated`
                : 'Updated',
            display: 'page',
          });
        })
        .catch((error) => {
          addDangerNotification({
            content: error.message,
            display: 'page',
          });
        });
      return;
    }
    const createRequest: Partial<CreateBankAccount> = {
      description: String(bankAccountState.description),
      displayName: bankAccountState.displayName,
      isOverdraftable: bankAccountState.isOverdraftable,
      overdraftReserveAccountId: bankAccountState.overdraftReserveAccountId,
      entityId: bankAccountState.entityId,
    };

    BankAccountRepository.create(createRequest as CreateBankAccount)
      .then((response) => {
        navigate(ROUTE.BANK_ACCOUNTS);
        addSuccessNotification({
          content:
            response.displayName || response.description
              ? `${response.displayName || response.description} created`
              : 'Created',
          display: 'page',
        });
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  const handleNewAccountNumber = () => {
    if (!id) {
      return;
    }
    BankAccountRepository.createAccountNumber(id, accountNumbersAdd as AccountNumber)
      .then(() => {
        BankAccountRepository.getAllAccountNumber(id)
          .then((accountNumberResponse) => {
            setAccountNumbers(accountNumberResponse.accountNumbers);
            setAccountNumbersOpen(accountNumberResponse.accountNumbers.length - 1);
            setAccountNumbersAdd({});
            setAccountNumbersAddOpen(false);
          })
          .catch((e) => console.error('BankAccountRepository.getAllAccountNumber', e));
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  useEffect(() => {
    if (!bankAccount?.id) {
      return;
    }

    BankAccountRepository.getAllAccountNumber(bankAccount?.id).then((accountNumberResponse) => {
      setAccountNumbers(accountNumberResponse.accountNumbers);
    });
  }, [bankAccount]);

  return (
    <EditPage fullWidth>
      {!id && (
        <EditToolbar>
          <EditTitle>
            <Headline>
              {id ? bankAccount?.displayName || bankAccount?.description || 'Account' : 'Create account'}
            </Headline>
            <Breadcrumb
              entries={[
                {
                  label: (
                    <>
                      <Icon.Wallet />
                      Accounts
                    </>
                  ),
                  path: ROUTE.BANK_ACCOUNTS,
                },
                { label: 'Create' },
              ]}
            />
          </EditTitle>
        </EditToolbar>
      )}
      <div>
        <NotificationList display="page" />

        <EditSection>
          <EditSectionTitle>
            <Icon.CircleInfo />
            Information
          </EditSectionTitle>

          <EditBox data-disabled={currentPermission?.bankAccounts !== 'write'}>
            {id && (
              <FormElement>
                <FormLabel>ID</FormLabel>
                <CopyInput value={id} />
              </FormElement>
            )}
            {id && (
              <FormElement>
                <FormLabel>Created</FormLabel>
                <FormText>
                  {bankAccount?.createdAt && (
                    <>
                      {(bankAccount.createdAt as any).toLocaleString('en-US', { weekday: 'long' })},{' '}
                      {(bankAccount.createdAt as any).toLocaleString('en-US', { month: 'long' })}{' '}
                      {(bankAccount.createdAt as any).getDate()}, {(bankAccount.createdAt as any).getFullYear()} —{' '}
                      {(bankAccount.createdAt as any).toLocaleString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                      })}
                    </>
                  )}
                </FormText>
              </FormElement>
            )}
            <FormElement>
              <FormLabel>Description</FormLabel>
              <Input
                onChange={(value: string) => setBankAccountState({ ...bankAccountState, description: value })}
                value={bankAccountState.description ?? ''}
                placeholder="Description"
              />
            </FormElement>
            <FormElement>
              <FormLabel>
                Display Name <small>(Optional)</small>
              </FormLabel>
              <Input
                onChange={(value: string) => setBankAccountState({ ...bankAccountState, displayName: value })}
                value={bankAccountState.displayName ?? ''}
                placeholder="Display Name"
              />
            </FormElement>

            <FormElement newRow>
              <FormLabel>Entity</FormLabel>
              {id ? (
                <FormText>
                  {entitiesOptions.filter((entity) => bankAccountState.owners?.includes(entity.value))[0]
                    ? entitiesOptions.filter((entity) => bankAccountState.owners?.includes(entity.value))[0].label
                    : ''}
                </FormText>
              ) : (
                entitiesOptions.length > 0 && (
                  <Dropdown
                    variant="muted"
                    fullWidth
                    maxWidth="640px"
                    active={bankAccountState.entityId}
                    onChange={(value: string | number | undefined) =>
                      setBankAccountState({ ...bankAccountState, entityId: value?.toString() })
                    }
                    search
                    searchLabel="Business or person name..."
                    onSearchChange={(value: string) => searchEntities({ name: value })}
                    options={entitiesOptions}
                  />
                )
              )}
              {entitiesOptions.length < 1 && (
                <FormParagraphLight>
                  No entities found - <AddEntity to={`${ROUTE.ENTITIES}/edit/business`}>add one first</AddEntity>.
                </FormParagraphLight>
              )}
            </FormElement>
            {entitiesOptions.length > 0 && (
              <FormElement>
                <FormLabel>Entity ID</FormLabel>
                <CopyInput
                  value={
                    id && bankAccountState?.owners ? bankAccountState?.owners[0] : (bankAccountState.entityId ?? '')
                  }
                  placeholder="Select an entity first"
                />
              </FormElement>
            )}
          </EditBox>
        </EditSection>
      </div>

      <EditSection>
        <EditSectionTitle>
          <Icon.OverdraftMoney />
          Overdraft
        </EditSectionTitle>
        <EditBox data-disabled={currentPermission?.bankAccounts !== 'write'}>
          {bankAccountOptions.length < 1 && (
            <FormElement fullWidth>
              <Notification
                color="danger"
                withClose={false}
                variant="light"
                actionLabel="Create"
                onActionClick={() => {
                  if (currentPlatform?.isLiveEnabled) {
                    navigate(ROUTE.PLATFORM);
                    return;
                  }
                  navigate(ROUTE.PLATFORM_ROOT_ENTITY);
                }}
              >
                You will need to create a root entity first to select the overdraft reserve account.
              </Notification>
            </FormElement>
          )}
          {bankAccountState.type === 'OVERDRAFT_RESERVE' && (
            <FormElement fullWidth>
              <Notification color="danger" withClose={false} variant="light">
                Overdraft reserve accounts cannot be overdrafted.
              </Notification>
            </FormElement>
          )}
          <FormElement>
            <FormLabel>Overdraftable</FormLabel>
            <FormText>
              <Toggle
                onCheckedChange={(value: boolean) =>
                  setBankAccountState({ ...bankAccountState, isOverdraftable: value })
                }
                isChecked={bankAccountState.isOverdraftable ?? false}
                isDisabled={bankAccountOptions.length < 1 || bankAccountState.type === 'OVERDRAFT_RESERVE'}
              />
            </FormText>
          </FormElement>
          <FormElement>
            <FormLabel>Reserve Account ID</FormLabel>
            <Dropdown
              options={bankAccountOptions.filter((b) => b.value !== bankAccountState.id)}
              active={bankAccountState.overdraftReserveAccountId}
              fullWidth
              maxWidth="640px"
              search
              variant="muted"
              onChange={(value: string) =>
                setBankAccountState({ ...bankAccountState, overdraftReserveAccountId: value })
              }
              isDisabled={!bankAccountState.isOverdraftable}
            />
          </FormElement>
        </EditBox>
      </EditSection>

      {id && (
        <EditSection>
          <EditSectionTitle>
            <Icon.Document />
            Account Numbers
          </EditSectionTitle>
          <EditBox oneColumn>
            {id &&
              accountNumbers.map((entry: DeepPartial<AccountNumber>, index: number) => (
                <div key={index}>
                  <ToggleTitle onClick={() => setAccountNumbersOpen(index)}>
                    {index + 1}. Account Number {entry.description && <span>({entry.description})</span>}
                    {accountNumbersOpen === index ? <Up /> : <Down />}
                  </ToggleTitle>
                  <StyledToggleHeight isClose={accountNumbersOpen !== index}>
                    <Grid>
                      <FormElement>
                        <FormLabel>Description</FormLabel>
                        <FormText>{entry.description || '-'}</FormText>
                      </FormElement>
                      <FormElement newRow>
                        <FormLabel>Account Number</FormLabel>
                        <CopyInput value={entry.accountNumber} />
                      </FormElement>
                      <FormElement>
                        <FormLabel>Routing Number</FormLabel>
                        <CopyInput value={entry.routingNumber} />
                      </FormElement>

                      {isSandbox && (
                        <SandboxWarning color="warning" withClose={false} variant="light">
                          Account numbers created in sandbox mode cannot be used to receive funds.
                        </SandboxWarning>
                      )}

                      <FormElement>
                        <FormLabel>ID</FormLabel>
                        <CopyInput value={entry.id} />
                      </FormElement>
                      <FormElement>
                        <FormLabel>Created</FormLabel>
                        <FormText>
                          {entry?.createdAt && (
                            <>
                              {(entry.createdAt as any).toLocaleString('en-US', { weekday: 'long' })},{' '}
                              {(entry.createdAt as any).toLocaleString('en-US', { month: 'long' })}{' '}
                              {(entry.createdAt as any).getDate()}, {(entry.createdAt as any).getFullYear()} —{' '}
                              {(entry.createdAt as any).toLocaleString('en-US', {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: true,
                              })}
                            </>
                          )}
                        </FormText>
                      </FormElement>
                    </Grid>
                  </StyledToggleHeight>
                </div>
              ))}
            <div>
              <ToggleTitle onClick={() => setAccountNumbersAddOpen(!accountNumbersAddOpen)}>
                <Plus isOpen={accountNumbersAddOpen} />
                Create Additional Account Number
              </ToggleTitle>
              <StyledToggleHeight isClose={!accountNumbersAddOpen}>
                <Grid>
                  <FormElement>
                    <FormLabel>Description</FormLabel>
                    <Input
                      value={accountNumbersAdd.description ?? ''}
                      onChange={(value: string) => setAccountNumbersAdd({ ...accountNumbersAdd, description: value })}
                      placeholder="Description"
                    />
                  </FormElement>
                  <CreateAccountNumber onClick={handleNewAccountNumber}>Create</CreateAccountNumber>
                </Grid>
              </StyledToggleHeight>
            </div>
          </EditBox>
        </EditSection>
      )}

      <EditToolbar>
        <Button onClick={() => navigate(-1)} variant="secondary">
          {id ? 'Back' : 'Cancel'}
        </Button>
        <Button onClick={handleSubmit} isDisabled={currentPermission?.bankAccounts !== 'write'}>
          {id ? 'Save' : 'Create'}
        </Button>
      </EditToolbar>
    </EditPage>
  );
};
