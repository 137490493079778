import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { IconBase } from '@column/column-ui-kit';

interface EventIncomingProps {
  size?: string;
}

const Wrapper = styled(IconBase)<EventIncomingProps>`
  --icon-size: ${({ size }) => size ?? '20px'};
`;

export const EventIncoming = forwardRef<SVGSVGElement, EventIncomingProps>(({ size }, ref) => {
  return (
    <Wrapper viewportSize={20} ref={ref} size={size}>
      <g stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.6967 15.3031V15.3031C1.76753 12.374 1.76753 7.62562 4.6967 4.69646V4.69646C7.62587 1.76729 12.3742 1.76729 15.3034 4.69646V4.69646C18.2325 7.62562 18.2325 12.374 15.3034 15.3031V15.3031C12.3742 18.2323 7.62587 18.2323 4.6967 15.3031Z"
        />
        <path d="M12.5 12.5L7.5 7.5" />
        <path d="M12.5 8.75V12.5H8.75" />
      </g>
    </Wrapper>
  );
});
