import React, { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { Icon, Radio, Tooltip, Chip } from '@column/column-ui-kit';
import { Form, Rows, StyledPanel } from '../Login';
import { Option, OptionList } from '../Register';
import { ROUTE } from '~/public/routes';
import { NotificationList } from '~/components';
import { Headline, Paragraph } from '~/styles';
import {
  handlePlaidRedirectUrl,
  PlaidParamsType,
  PlaidRepository,
  PlatformRolesExtended,
  UserRepository,
} from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { delay, isValidDate } from '~/util';
import { HandButton } from '~/elements';

const Text = styled.div`
  display: grid;
  grid-gap: 8px;
`;

const StyledOption = styled(Option)`
  * {
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  }
`;

const StyledRadio = styled(Radio)`
  position: absolute;
  left: 14px;
  top: 14px;
  z-index: 1;
`;

const DisabledWrapper = styled(Tooltip)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 11px;

  svg {
    --icon-size: 20px;
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(600)};
  }
`;

const StyledChip = styled(Chip)`
  margin-top: 26px;
  margin-left: -2px;
`;

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const StyledOptionList = styled(OptionList)<{ $isShow: boolean }>`
  opacity: ${({ $isShow }) => ($isShow ? '1' : '0')};
  pointer-events: ${({ $isShow }) => ($isShow ? 'auto' : 'none')};
  transition: opacity 0.2s linear ${({ $isShow }) => ($isShow ? '.2s' : '0s')};
  position: ${({ $isShow }) => ($isShow ? 'relative' : 'absolute')};
  left: 0;
  top: 0;
  width: 100%;
`;

const LoadingListEntry = styled.div`
  height: 74px;
  border-radius: 8px;
  position: relative;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(200)};

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
  }

  &:before {
    left: 14px;
    top: 14px;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    box-shadow: inset 0 0 0 2px ${({ theme }) => theme.secondary.blendToBackground(200)};
  }

  &:after,
  span {
    border-radius: 2px;
    background-size: 300%;
    background-image: linear-gradient(
      to right,
      ${({ theme }) => theme.secondary.blendToBackground(150)} 0%,
      ${({ theme }) => theme.secondary.blendToBackground(150)} 40%,
      ${({ theme }) => theme.secondary.blendToBackground(100)} 50%,
      ${({ theme }) => theme.secondary.blendToBackground(150)} 60%,
      ${({ theme }) => theme.secondary.blendToBackground(150)} 100%
    );
    animation: skeleton 1.5s infinite;
    background-position: 100%;
  }

  &:after {
    left: 42px;
    top: 12px;
    height: 22px;
    width: 30%;
  }

  span {
    display: block;
    position: absolute;
    left: 42px;
    top: 36px;
    height: 24px;
    width: 55%;
    background-image: linear-gradient(
      to right,
      ${({ theme }) => theme.secondary.blendToBackground(100)} 0%,
      ${({ theme }) => theme.secondary.blendToBackground(100)} 40%,
      ${({ theme }) => theme.secondary.blendToBackground(60)} 50%,
      ${({ theme }) => theme.secondary.blendToBackground(100)} 60%,
      ${({ theme }) => theme.secondary.blendToBackground(100)} 100%
    );
  }

  &:nth-child(2) {
    &:after {
      width: 35%;
    }
  }

  @keyframes skeleton {
    0%,
    35% {
      background-position: 100%;
    }
    99.5% {
      background-position: 0%;
    }
    100% {
      background-position: 100%;
    }
  }
`;

export const PagePlaidPlatformSelection: React.FC = () => {
  const { addDangerNotification } = useNotificationStore();
  const { params } = useSessionStore();
  const [platformList, setPlatformList] = useState<PlatformRolesExtended[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [platformId, setPlatformId] = useState<string>('');
  const wrapperRef = useRef<HTMLDivElement>(null);
  const loadingWrapperRef = useRef<HTMLDivElement>(null);
  const contentWrapperRef = useRef<HTMLDivElement>(null);

  const fetchData = (id: string) => {
    setLoading(true);

    UserRepository.platformRoles(id)
      .then((response) => setPlatformList(response.platformRoles))
      .then(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (loading) {
      return;
    }

    gsap.fromTo(
      wrapperRef.current,
      {
        height: loadingWrapperRef.current?.offsetHeight,
      },
      {
        height: contentWrapperRef.current?.offsetHeight,
        duration: 0.3,
      }
    );
  }, [loading]);

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => ({
          isSignedIn: state.isSignedIn(),
          isLoading: state.isLoading,
          currentUser: state.currentUser,
        }),
        ({ isSignedIn, isLoading, currentUser }) => {
          if (isLoading) {
            return;
          }

          if (!isSignedIn || !currentUser?.id) {
            navigate(ROUTE.PLAID_LOGIN, {
              state: {
                from: location,
                action: 'signOut',
              },
            });
            return;
          }

          fetchData(currentUser.id);
        },
        {
          fireImmediately: true,
        }
      ),
    [location.pathname]
  );

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      if (formLoading) {
        return;
      }

      setFormLoading(true);

      if (!params || !platformId) {
        addDangerNotification({
          display: 'page',
          content: 'Please select a platform',
        });
        setFormLoading(false);
        return;
      }

      await delay(1000);

      if (params) {
        PlaidRepository.getTempCode(platformId)
          .then((resp) => {
            handlePlaidRedirectUrl(params as PlaidParamsType, {
              code: resp.tempCode,
            });
          })
          .catch(() => {
            handlePlaidRedirectUrl(params as PlaidParamsType, {
              error: 'temporarily_unavailable',
            });
          })
          .finally(() => setFormLoading(false));
      }
    },
    [params, platformId]
  );

  return (
    <StyledPanel>
      <Text>
        <Headline size="small">Select a platform</Headline>
        <Paragraph>You can only select live enabled&nbsp;platforms.</Paragraph>
      </Text>
      <Form onSubmit={handleSubmit}>
        <NotificationList display="page" />
        <Rows>
          <Wrapper ref={wrapperRef}>
            <StyledOptionList $isShow={loading} ref={loadingWrapperRef}>
              <LoadingListEntry>
                <span />
              </LoadingListEntry>
              <LoadingListEntry>
                <span />
              </LoadingListEntry>
            </StyledOptionList>

            <StyledOptionList $isShow={!loading} ref={contentWrapperRef}>
              {platformList.map((entry) => {
                return (
                  <StyledOption
                    isActive={entry.platformId === platformId}
                    isDisabled={!entry.isLiveEnabled}
                    key={entry.platformId}
                    onClick={() => entry.isLiveEnabled && setPlatformId(entry.platformId)}
                  >
                    <StyledRadio
                      isChecked={entry.platformId === platformId}
                      isDisabled={!entry.isLiveEnabled}
                      onCheckedChange={() => entry.isLiveEnabled && setPlatformId(entry.platformId)}
                      label={entry.platformName}
                    />
                    <StyledChip>
                      Created:{' '}
                      {entry.createdAt && isValidDate(entry.createdAt)
                        ? new Date(entry.createdAt).toLocaleDateString('en-US')
                        : '-'}
                    </StyledChip>
                    {!entry.isLiveEnabled && (
                      <DisabledWrapper
                        content={
                          <>
                            Live mode is not enabled
                            <br />
                            for <strong>{entry.platformName}</strong>
                          </>
                        }
                      >
                        <Icon.CircleInfo />
                      </DisabledWrapper>
                    )}
                  </StyledOption>
                );
              })}
            </StyledOptionList>
          </Wrapper>

          <HandButton isLoading={formLoading}>Select</HandButton>
        </Rows>
      </Form>
    </StyledPanel>
  );
};
