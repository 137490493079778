import React, { FC } from 'react';
import { useOutletContext } from 'react-router-dom';
import { BankAccountRouteProps } from './Route';
import { EmptyState } from '~/components';
import { ReportingTable } from '~/components/ReportingTable';
import { EditPage } from '~/styles';

export const PageBankAccountsStatements: FC = () => {
  const { bankAccount } = useOutletContext<BankAccountRouteProps>();

  return (
    <EditPage fullWidth smallGap>
      <ReportingTable
        tableId="statements"
        bankAccountId={bankAccount.id}
        filter={{ category: 'statement' }}
        visibleFiles={['pdf', 'csv']}
        hiddenFiles={['json', 'parquet', 'bai2']}
        empty={<EmptyState headline="No statements found" text="Wait for your first statements to get generated." />}
      />
    </EditPage>
  );
};
