import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Icon, Button, Chip } from '@column/column-ui-kit';
import { AmountInfo, AmountText } from '../../Transfers/View';
import {
  EditPage,
  EditToolbar,
  EditTitle,
  EditSection,
  EditSectionTitle,
  EditBox,
  Headline,
  FormElement,
  FormLabel,
  FormText,
} from '~/styles';
import { Breadcrumb, NotificationList, CopyInput } from '~/components';
import { ROUTE } from '~/app/routes';
import { useNotificationStore } from '~/stores/Notification';
import { useModalStore } from '~/stores/Modal';
import { Loan, LoanDisbursement, LoanRepository } from '~/repositories';
import { formatNumber, formatString } from '~/util';

interface Params {
  id: string;
}

const List = styled.div`
  display: flex;
  gap: 16px;
`;

export const PageLoansDisbursementEdit: React.FC = () => {
  const openModal = useModalStore((state) => state.openModal);
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const navigate = useNavigate();
  const { id } = useParams<keyof Params>() as Params;
  const [disbursement, setDisbursement] = useState<Partial<LoanDisbursement>>({});
  const [loan, setLoan] = useState<Partial<Loan>>({});

  const handleCancel = () => {
    LoanRepository.cancelDisbursement(id)
      .then(() => {
        fetchDisbursement();
        addSuccessNotification({
          content: 'Disbursement cancelled',
          display: 'page',
        });
      })
      .catch((error: any) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  const fetchDisbursement = () => {
    LoanRepository.getDisbursement(id)
      .then((response) => {
        setDisbursement(response);

        if (!response.loanId) {
          return;
        }

        LoanRepository.get(response.loanId)
          .then((loanResponse) => {
            setLoan(loanResponse);
          })
          .catch((error) => {
            navigate(`${ROUTE.LOANS}/disbursements`);
            addDangerNotification({
              content: error.message,
              display: 'page',
            });
          });
      })
      .catch((error) => {
        navigate(`${ROUTE.LOANS}/disbursements`);
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  useEffect(() => {
    fetchDisbursement();
  }, []);

  return (
    <EditPage>
      <EditToolbar>
        <EditTitle>
          <Headline>View disbursement</Headline>
          <Breadcrumb
            entries={[
              {
                label: (
                  <>
                    <Icon.HandCoins />
                    Loans
                  </>
                ),
                path: ROUTE.LOANS,
              },
              {
                label: loan.description,
                path: `${ROUTE.LOANS}/edit/${loan.id}`,
              },
              {
                label: disbursement.description,
              },
            ]}
          />
        </EditTitle>
        {disbursement.status === 'hold' && (
          <List>
            <Button
              onClick={() =>
                openModal('ClearDisbursement', {
                  disbursementId: id,
                  callback: fetchDisbursement,
                })
              }
            >
              Clear
            </Button>
            <Button
              onClick={() =>
                openModal('UpdateDisbursement', {
                  disbursementId: id,
                  callback: fetchDisbursement,
                })
              }
            >
              Update
            </Button>
            <Button variant="danger" onClick={handleCancel} icon={<Icon.CircleCross />}>
              Cancel
            </Button>
          </List>
        )}
      </EditToolbar>
      <div>
        <NotificationList display="page" />
        <EditPage>
          <EditBox>
            <AmountInfo>
              <AmountText>{formatNumber(disbursement?.amount)}</AmountText>

              <div>
                <Chip>{disbursement.status && formatString(disbursement.status)}</Chip>
              </div>
            </AmountInfo>
            <FormElement>
              <FormLabel>ID</FormLabel>
              <CopyInput value={id} />
            </FormElement>
          </EditBox>
          <EditSection>
            <EditSectionTitle>
              <Icon.CircleInfo />
              Information
            </EditSectionTitle>
            <EditBox>
              <FormElement>
                <FormLabel>Description</FormLabel>
                <FormText>{disbursement?.description ?? ''}</FormText>
              </FormElement>
              <FormElement>
                <FormLabel>Status</FormLabel>
                <FormText>
                  <Chip>{disbursement.status && formatString(disbursement.status)}</Chip>
                </FormText>
              </FormElement>
              <EditBox pale>
                <FormElement>
                  <FormLabel>Bank Account ID</FormLabel>
                  <CopyInput value={disbursement.bankAccountId} />
                </FormElement>
                <FormElement>
                  <FormLabel>Acount Number ID</FormLabel>
                  <CopyInput value={disbursement.accountNumberId} />
                </FormElement>
              </EditBox>
              <FormElement>
                <FormLabel>Created at</FormLabel>
                <FormText>
                  {disbursement?.createdAt && (
                    <>
                      {(disbursement.createdAt as any).toLocaleString('en-US', { weekday: 'long' })},{' '}
                      {(disbursement.createdAt as any).toLocaleString('en-US', { month: 'long' })}{' '}
                      {(disbursement.createdAt as any).getDate()}, {(disbursement.createdAt as any).getFullYear()} —{' '}
                      {(disbursement.createdAt as any).toLocaleString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                      })}
                    </>
                  )}
                </FormText>
              </FormElement>
              <FormElement>
                <FormLabel>Effective at</FormLabel>
                <FormText>
                  {disbursement?.effectiveAt && (
                    <>
                      {(disbursement.effectiveAt as any).toLocaleString('en-US', { weekday: 'long' })},{' '}
                      {(disbursement.effectiveAt as any).toLocaleString('en-US', { month: 'long' })}{' '}
                      {(disbursement.effectiveAt as any).getDate()}, {(disbursement.effectiveAt as any).getFullYear()} —{' '}
                      {(disbursement.effectiveAt as any).toLocaleString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                      })}
                    </>
                  )}
                </FormText>
              </FormElement>
            </EditBox>
          </EditSection>
        </EditPage>
      </div>
      <EditToolbar>
        <Button onClick={() => navigate(-1)} variant="secondary">
          Back
        </Button>

        <Button
          onClick={() => navigate(`${ROUTE.LOANS}/edit/${loan.id}`)}
          variant="secondary"
          icon={<Icon.ChevronRight />}
          iconRight
        >
          Loan: {loan?.description}
        </Button>
      </EditToolbar>
    </EditPage>
  );
};
