import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import { Input, Button } from '@column/column-ui-kit';
import { Form, Rows, StyledLogo, Panel, HintLink } from '../Login';
import { PasswordStrength, NotificationList, PasswordInfo } from '~/components';
import { UserRepository } from '~/repositories';
import { Headline } from '~/styles';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { log, triggerEvent, validateEmail } from '~/util';
import { ROUTE } from '~/public/routes';
import { UnderlineLink } from '~/elements';
import { reCaptchaCheck } from '~/util/reCaptcha';

interface Params {
  platformId: string;
}

export const Legal = styled.div`
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

export const PageRegisterForm: React.FC = () => {
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);
  const currentPlatformState = useSessionStore((state) => state.currentPlatform);
  const { isSignedIn, signIn, setSession, loadPlatform } = useSessionStore();
  const { platformId } = useParams<keyof Params>() as Params;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFilled, setIsFilled] = useState<boolean>(false);
  const [passwordInfo, setPasswordInfo] = useState<PasswordInfo>({
    score: 0,
    suggestions: [],
  });
  const navigate = useNavigate();
  const location = useLocation();

  const { control, getValues, handleSubmit, setValue, watch } = useForm();

  const { handleReCaptcha } = reCaptchaCheck();

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => ({
          currentUser: state.currentUser,
          currentPlatform: state.currentPlatform,
          isLoading: state.isLoading,
        }),
        ({ currentUser, currentPlatform }) => {
          if (isLoading) {
            return;
          }
          if (isSignedIn()) {
            setIsFilled(true);
          }
          setValue('firstName', currentUser?.firstName);
          setValue('lastName', currentUser?.lastName);
          setValue('email', currentUser?.email);
          setValue('companyName', currentPlatform?.companyName ?? '');
        },
        { fireImmediately: true }
      ),
    [location.pathname]
  );

  const onSuccess = () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const companyName = getValues('companyName');

    if (isSignedIn()) {
      currentPlatformState?.updateMeta({ companyName }).then(() => {
        navigate(ROUTE.REGISTER_FINANCIAL_PRODUCTS);

        setIsLoading(false);
      });
      return;
    }

    if (passwordInfo.score < 2) {
      addDangerNotification({
        display: 'page',
        content: 'Please choose a stronger password',
      });
      return;
    }

    handleReCaptcha(
      (reCaptchaToken: string) => {
        UserRepository.create(
          getValues('firstName'),
          getValues('lastName'),
          getValues('email'),
          getValues('password'),
          platformId,
          reCaptchaToken
        )
          .then(async () => {
            log({
              name: 'User registered',
              context: {
                firstName: getValues('firstName'),
                lastName: getValues('lastName'),
                email: getValues('email'),
              },
            });

            triggerEvent({
              category: 'User',
              action: 'Register',
            });

            handleReCaptcha(
              (tokenLogin: string) => {
                UserRepository.login(getValues('email'), getValues('password'), tokenLogin)
                  .then((response) => {
                    setSession(response.sessionId, response.dashboardUserId);

                    signIn()
                      .then((signInResponse) => {
                        if (!signInResponse) {
                          setIsLoading(false);

                          navigate(ROUTE.LOGIN);

                          addDangerNotification({
                            content: 'Error signing in, please try again',
                            display: 'page',
                          });

                          return;
                        }

                        loadPlatform(true)
                          .then((loadPlatformResponse) => {
                            loadPlatformResponse?.updateMeta({ companyName }).then(() => {
                              navigate(ROUTE.REGISTER_FINANCIAL_PRODUCTS);
                            });
                          })
                          .catch((error) => {
                            addDangerNotification({
                              content: error.message,
                              display: 'page',
                            });
                            navigate(ROUTE.LOGIN);
                          });
                      })
                      .catch((error) => {
                        addDangerNotification({
                          content: error.message,
                          display: 'page',
                        });
                      });
                  })
                  .catch((error) => {
                    setIsLoading(false);

                    addDangerNotification({
                      content: error.message,
                      display: 'page',
                    });
                  });
              },
              (err) => {
                setIsLoading(false);

                addDangerNotification({
                  content: err.message,
                  display: 'page',
                });
              }
            );
          })
          .catch((error) => {
            setIsLoading(false);

            addDangerNotification({
              content: error.message,
              display: 'page',
            });
          });
      },
      (err) => {
        setIsLoading(false);

        addDangerNotification({
          content: err.message,
          display: 'page',
        });
      }
    );
  };

  return (
    <Panel>
      <StyledLogo size="24px" />
      <Headline size="small">Create an account</Headline>
      <Form onSubmit={handleSubmit(onSuccess)}>
        <NotificationList display="page" />
        <Rows>
          <Controller
            name="firstName"
            control={control}
            defaultValue=""
            rules={{
              required: true,
            }}
            render={({ field, fieldState: { error, isTouched } }) => (
              <Input placeholder="First name" hasError={isTouched && !!error} isDisabled={isFilled} {...field} />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            defaultValue=""
            rules={{
              required: true,
            }}
            render={({ field, fieldState: { error, isTouched } }) => (
              <Input placeholder="Last name" hasError={isTouched && !!error} isDisabled={isFilled} {...field} />
            )}
          />
          <Controller
            name="companyName"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error, isTouched } }) => (
              <Input placeholder="Company (Optional)" hasError={isTouched && !!error} {...field} />
            )}
          />
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              validate: validateEmail,
            }}
            render={({ field, fieldState: { error, isTouched } }) => (
              <Input
                autoComplete="username"
                placeholder="Email address"
                hasError={isTouched && !!error}
                isDisabled={isFilled}
                {...field}
              />
            )}
          />
          {!isFilled && (
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field, fieldState: { error, isTouched } }) => (
                <PasswordStrength
                  hasError={
                    (isTouched || (watch('password') && watch('password').length > 0)) &&
                    (!!error || passwordInfo.score < 2)
                  }
                  {...field}
                  disallowedStrings={[watch('firstName'), watch('lastName'), watch('email')].filter(
                    (s) => s && s.length > 0
                  )}
                  onStrengthChange={setPasswordInfo}
                />
              )}
            />
          )}
        </Rows>
        <Rows>
          <Button isLoading={isLoading}>{isFilled ? 'Next' : 'Sign up'}</Button>
          <Legal>
            By signing up you agree to our{' '}
            <UnderlineLink href="https://column.com/legal/api-terms-of-service" newTab withoutArrow>
              API Terms of Service
            </UnderlineLink>{' '}
            and{' '}
            <UnderlineLink href="https://column.com/legal/privacy-policy" newTab withoutArrow>
              Privacy Policy
            </UnderlineLink>
            .
          </Legal>
          <HintLink as={Link} to={ROUTE.LOGIN}>
            Back to Login
          </HintLink>
        </Rows>
      </Form>
    </Panel>
  );
};
