import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

interface PageHeaderProps {
  title: string;
  noBorder?: boolean;
}

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
`;

const Inner = styled.div<PageHeaderProps>`
  padding: 0 0 16px;
  display: flex;
  gap: 24px;
  align-items: flex-start;
  position: relative;
  z-index: 1;

  ${({ noBorder }) =>
    noBorder &&
    css`
      box-shadow: 0;
    `};
`;

const PageTitle = styled.h1`
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -0.02em;
  margin: 0;
  flex: auto;
`;

export const Actions = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
  max-height: 24px;
`;

const Mask = styled.div`
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  height: 16px;
  background: ${({ theme }) => theme.body};
  z-index: -1;
`;

export const PageHeader: React.FC<PropsWithChildren<PageHeaderProps>> = (props) => {
  return (
    <>
      <Wrapper>
        <Inner {...props}>
          <PageTitle>{props.title}</PageTitle>
          <Actions>{props.children}</Actions>
        </Inner>
        <Mask />
      </Wrapper>
    </>
  );
};
