import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Icon, Button, Chip } from '@column/column-ui-kit';
import { AmountInfo, AmountText } from '../../Transfers/View';
import {
  EditPage,
  EditToolbar,
  EditTitle,
  EditSection,
  EditSectionTitle,
  EditBox,
  Headline,
  FormElement,
  FormLabel,
  FormText,
} from '~/styles';
import { Breadcrumb, NotificationList, CopyInput } from '~/components';
import { ROUTE } from '~/app/routes';
import { useNotificationStore } from '~/stores/Notification';
import { Loan, LoanPayment, LoanRepository } from '~/repositories';
import { formatNumber, formatString } from '~/util';

interface Params {
  id: string;
}

export const PageLoansPaymentView: React.FC = () => {
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);
  const navigate = useNavigate();
  const { id } = useParams<keyof Params>() as Params;
  const [payment, setPayment] = useState<Partial<LoanPayment>>({});
  const [loan, setLoan] = useState<Partial<Loan>>({});

  useEffect(() => {
    LoanRepository.getPayment(id)
      .then((response) => {
        setPayment(response);

        if (!response.loanId) {
          return;
        }

        LoanRepository.get(response.loanId)
          .then((loanResponse) => {
            setLoan(loanResponse);
          })
          .catch((error) => {
            navigate(`${ROUTE.LOANS}/payments`);
            addDangerNotification({
              content: error.message,
              display: 'page',
            });
          });
      })
      .catch((error) => {
        navigate(`${ROUTE.LOANS}/payments`);
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  }, []);

  return (
    <EditPage>
      <EditToolbar>
        <EditTitle>
          <Headline>View payment</Headline>
          <Breadcrumb
            entries={[
              {
                label: (
                  <>
                    <Icon.HandCoins />
                    Loans
                  </>
                ),
                path: ROUTE.LOANS,
              },
              {
                label: loan.description,
                path: `${ROUTE.LOANS}/edit/${loan.id}`,
              },
              {
                label: payment.description,
              },
            ]}
          />
        </EditTitle>
      </EditToolbar>
      <div>
        <NotificationList display="page" />
        <EditPage>
          <EditBox>
            <AmountInfo>
              <AmountText>{formatNumber(payment?.principalAmount)}</AmountText>

              <div>
                <Chip>{payment.status && formatString(payment.status)}</Chip>
              </div>
            </AmountInfo>
            <FormElement>
              <FormLabel>ID</FormLabel>
              <CopyInput value={id} />
            </FormElement>
          </EditBox>
          <EditSection>
            <EditSectionTitle>
              <Icon.CircleInfo />
              Information
            </EditSectionTitle>
            <EditBox>
              <FormElement>
                <FormLabel>Description</FormLabel>
                <FormText>{payment?.description ?? ''}</FormText>
              </FormElement>
              <FormElement>
                <FormLabel>Status</FormLabel>
                <FormText>
                  <Chip>{payment.status && formatString(payment.status)}</Chip>
                </FormText>
              </FormElement>
              <EditBox pale>
                <FormElement>
                  <FormLabel>Bank Account ID</FormLabel>
                  <CopyInput value={payment.bankAccountId} />
                </FormElement>
                <FormElement>
                  <FormLabel>Acount Number ID</FormLabel>
                  <CopyInput value={payment.accountNumberId} />
                </FormElement>
              </EditBox>
              <FormElement>
                <FormLabel>Created at</FormLabel>
                <FormText>
                  {payment?.createdAt && (
                    <>
                      {(payment.createdAt as any).toLocaleString('en-US', { weekday: 'long' })},{' '}
                      {(payment.createdAt as any).toLocaleString('en-US', { month: 'long' })}{' '}
                      {(payment.createdAt as any).getDate()}, {(payment.createdAt as any).getFullYear()} —{' '}
                      {(payment.createdAt as any).toLocaleString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                      })}
                    </>
                  )}
                </FormText>
              </FormElement>
              <FormElement>
                <FormLabel>Effective at</FormLabel>
                <FormText>
                  {payment?.effectiveAt && (
                    <>
                      {(payment.effectiveAt as any).toLocaleString('en-US', { weekday: 'long' })},{' '}
                      {(payment.effectiveAt as any).toLocaleString('en-US', { month: 'long' })}{' '}
                      {(payment.effectiveAt as any).getDate()}, {(payment.effectiveAt as any).getFullYear()} —{' '}
                      {(payment.effectiveAt as any).toLocaleString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                      })}
                    </>
                  )}
                </FormText>
              </FormElement>
            </EditBox>
          </EditSection>
        </EditPage>
      </div>
      <EditToolbar>
        <Button onClick={() => navigate(-1)} variant="secondary">
          Back
        </Button>

        <Button
          onClick={() => navigate(`${ROUTE.LOANS}/edit/${loan.id}`)}
          variant="secondary"
          icon={<Icon.ChevronRight />}
          iconRight
        >
          Loan: {loan?.description}
        </Button>
      </EditToolbar>
    </EditPage>
  );
};
