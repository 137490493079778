import { useNavigate } from 'react-router-dom';
import { gsap } from 'gsap';
import MorphSVGPlugin from 'gsap/MorphSVGPlugin';
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { Icon } from '@column/column-ui-kit';
import { MenuItem } from './MenuItem';
import { Platform } from './Toolbar';
import { ROUTE } from '~/app/routes';
import { Logo } from '~/elements/Logo';

gsap.registerPlugin(MorphSVGPlugin);

interface SidebarProps {
  className?: string;
}

export const PROFILE_ENTRIES = [
  {
    label: 'Profile',
    path: ROUTE.PROFILE,
  },
  {
    label: 'Your Platforms',
    path: ROUTE.PLATFORMS,
    activePath: `${ROUTE.PLATFORMS}/edit`,
  },
];

export const PLATFORM_ENTRIES = [
  {
    label: 'Info',
    path: ROUTE.PLATFORM,
    activePath: ROUTE.PLATFORM,
  },
  {
    label: 'Settings',
    path: ROUTE.PLATFORM_SETTINGS,
    activePath: ROUTE.PLATFORM_SETTINGS,
  },
  {
    label: 'API Keys',
    path: ROUTE.PLATFORM_API_KEYS,
    activePath: `${ROUTE.PLATFORM_API_KEYS}/edit`,
  },
  {
    label: 'Webhooks',
    path: ROUTE.PLATFORM_WEBHOOKS,
    activePath: `${ROUTE.PLATFORM_WEBHOOKS}/edit`,
  },
  {
    label: 'Root Entity (Sandbox)',
    path: ROUTE.PLATFORM_ROOT_ENTITY,
    isSandboxOnly: true,
  },
];

export const LOANS_ENTRIES = [
  {
    label: 'Overview',
    path: ROUTE.LOANS,
    activePath: `${ROUTE.LOANS}/edit`,
  },
  {
    label: 'Disbursements',
    path: `${ROUTE.LOANS}/disbursements`,
    activePath: `${ROUTE.LOANS}/disbursements`,
  },
  {
    label: 'Payments',
    path: `${ROUTE.LOANS}/payments`,
    activePath: `${ROUTE.LOANS}/payments`,
  },
];

export const DOCUMENT_ENTRIES = [
  {
    label: 'Statements',
    path: ROUTE.STATEMENTS,
    activePath: ROUTE.STATEMENTS,
  },
  {
    label: 'Reporting',
    path: ROUTE.REPORTING,
    activePath: ROUTE.REPORTING,
  },
];

export const EVENTS = [
  {
    label: 'Overview',
    path: ROUTE.EVENTS,
  },
  {
    label: 'Webhook',
    path: ROUTE.EVENTS_WEBHOOK,
  },
];

const Wrapper = styled.div`
  --sidebar-o: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: var(--sidebar-width);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: ${({ theme }) => theme.secondary.blendToBackground(75)};
  border-right: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      background: ${theme.background};
    `}
`;

const Nav = styled.div<{ $showSandboxBanner?: boolean }>`
  --sidebar-scrollbar-background: transparent;
  --sidebar-thumb-background: transparent;

  display: flex;
  flex-direction: column;
  opacity: var(--sidebar-o);
  margin: 0;
  padding: 12px;

  scrollbar-width: thin;
  scrollbar-color: var(--sidebar-thumb-background) var(--sidebar-scrollbar-background);
  overflow-y: scroll;

  &:hover {
    --sidebar-thumb-background: ${({ theme }) => theme.secondary.blendToBackground(150)};
  }

  @media (min-height: 820px) {
    scrollbar-width: none;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--sidebar-scrollbar-background);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--sidebar-thumb-background);
    border-radius: 6px;
    border: 3px solid var(--sidebar-scrollbar-background);
  }
`;

const Section = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.secondary.blendToBackground(200)};
  padding-bottom: 8px;
  margin-bottom: 8px;
  gap: 2px;
  display: flex;
  flex-direction: column;

  &:last-child {
    border: 0;
  }

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      border-bottom: 1px solid ${theme.secondary.blendToBackground(150)};
    `}
`;

const LogoLink = styled.button`
  background: none;
  padding: 0;
  margin: 24px 16px 4px;
  outline: none;
  border: none;
  cursor: pointer;
`;

export const Sidebar = forwardRef<HTMLDivElement, SidebarProps>((props, ref) => {
  const navigate = useNavigate();

  return (
    <Wrapper className={props.className} ref={ref}>
      <div>
        <LogoLink onClick={() => navigate(ROUTE.ROOT)}>
          <Logo variant="word" size="16" />
        </LogoLink>
      </div>

      <Platform />

      <Nav>
        <Section>
          <MenuItem label="Home" icon={<Icon.AnimationHome />} path={ROUTE.ROOT} exact />
          <MenuItem
            label="Platform"
            icon={<Icon.AnimationLayers />}
            path={ROUTE.PLATFORM}
            entries={PLATFORM_ENTRIES}
            activePath={ROUTE.PLATFORM_ROOT}
          />
          <MenuItem label="User" icon={<Icon.AnimationUser />} path={ROUTE.PROFILE} entries={PROFILE_ENTRIES} />
        </Section>
        <Section>
          <MenuItem label="Transfers" icon={<Icon.AnimationArrows />} path={ROUTE.TRANSFERS} />
          <MenuItem label="Loans" icon={<Icon.AnimationHandCoins />} path={ROUTE.LOANS} entries={LOANS_ENTRIES} />
          <MenuItem label="Accounts" icon={<Icon.AnimationWallet />} path={ROUTE.BANK_ACCOUNTS} />
          <MenuItem label="Entities" icon={<Icon.AnimationDocument />} path={ROUTE.ENTITIES} />
        </Section>
        <Section>
          <MenuItem
            label="Documents"
            icon={<Icon.AnimationDocumentEdit />}
            path={ROUTE.STATEMENTS}
            activePath={ROUTE.DOCUMENTS}
            entries={DOCUMENT_ENTRIES}
          />
          <MenuItem
            label="Events"
            icon={<Icon.AnimationCalendar />}
            path={ROUTE.EVENTS}
            entries={EVENTS}
            activePath={ROUTE.EVENTS}
          />
        </Section>
      </Nav>
    </Wrapper>
  );
});
