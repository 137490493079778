export const testDateFormat = (value: string | undefined) => {
  return value && /^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$/i.test(value);
};

export const getDateFormatFromValues = (year: string | number, month: string | number, day: string | number) => {
  return `${year.toString()}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
};

export const getDateFormat = (date: Date) => {
  return `${date.getFullYear().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
    .getDate()
    .toString()
    .padStart(2, '0')}`;
};

export const checkDateParts = (date: any) => {
  if (typeof date !== 'string') {
    return false;
  }

  const parts = date.split('-');

  if (!testDateFormat(date)) {
    return false;
  }

  if (
    Number(parts[1]) < 13 &&
    Number(parts[1]) > 0 &&
    Number(parts[2]) < 32 &&
    Number(parts[2]) > 0 &&
    Number(parts[0]) > 0
  ) {
    return true;
  }

  return false;
};

export const getDateParts = (date: string) => {
  const parts = date.split('-');

  return { year: parts[0], month: parts[1], day: parts[2] };
};

// Day of the week, Month Day, Year - 00:00 PM

export const getDateText = (object: Date): string => {
  return `${object.toLocaleString('en-US', { weekday: 'long' })}, ${object.toLocaleString('en-US', {
    month: 'long',
  })} ${object.getDate()}, ${object.getFullYear()} - ${object.toLocaleString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  })}`;
};
