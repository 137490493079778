import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Icon } from '@column/column-ui-kit';
import styled from 'styled-components';
import { BusinessForm, PersonForm, EntityStatus } from './Partial';
import { EmptyState, PageHeader } from '~/components';
import { Headline, EditBox } from '~/styles';
import {
  EntityType,
  EntityTypes,
  EntityTypeOption,
  EntityTypeOptionCircle,
  EntityTypeOptionLabel,
  EntityTypeIcons,
} from '~/app/pages/Entities/Edit';
import { useSessionStore } from '~/stores/Session';
import { ROUTE } from '~/public/routes';

const EntityTypeSelection = styled.div`
  display: grid;
  grid-gap: 24px;
`;

export const PagePlatformInfo: React.FC = () => {
  const rootEntity = useSessionStore((state) => state.rootEntity);
  const location = useLocation();
  const navigate = useNavigate();
  const [entityType, setEntityType] = useState<EntityTypes>('business');

  useEffect(() => {
    setEntityType(rootEntity?.personDetails ? 'person' : 'business');
  }, [rootEntity]);

  return (
    <>
      <EntityStatus status={rootEntity?.verificationStatus} />

      {!rootEntity && (
        // <EntityTypeSelection>
        //   <EntityType>
        //     {['Person', 'Business'].map((name: string) => {
        //       const nameLower = name.toLowerCase() as EntityTypes;
        //       return (
        //         <EntityTypeOption
        //           key={name}
        //           isChecked={entityType === nameLower}
        //           onCheckedChange={(value: boolean) => value && setEntityType(nameLower)}
        //         >
        //           <EntityTypeOptionCircle isChecked={entityType === nameLower}>
        //             {EntityTypeIcons[nameLower]}
        //           </EntityTypeOptionCircle>
        //           <EntityTypeOptionLabel isChecked={entityType === nameLower}>{name}</EntityTypeOptionLabel>
        //         </EntityTypeOption>
        //       );
        //     })}
        //   </EntityType>
        // </EntityTypeSelection>
        <div>
          <EmptyState headline="No root entity" text="Create your Root Entity by opening a Commercial Account.">
            <Button onClick={() => navigate(`${ROUTE.REGISTER_OPEN_BANK_ACCOUNT}`)} size="small" icon={<Icon.Plus />}>
              Create Commercial Account
            </Button>
          </EmptyState>
        </div>
      )}

      {rootEntity && entityType === 'person' && (
        <div>
          <PageHeader title="Root Entity" />
          <EditBox oneColumn>
            <PersonForm personEntity={rootEntity?.personDetails} id={rootEntity?.id} disableForm />
          </EditBox>
        </div>
      )}
      {rootEntity && entityType === 'business' && (
        <div>
          <PageHeader title="Root Entity" />
          <EditBox oneColumn>
            <BusinessForm businessEntity={rootEntity?.businessDetails} id={rootEntity?.id} disableForm />
          </EditBox>
        </div>
      )}
    </>
  );
};
