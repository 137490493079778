import React, { FC, useEffect, useLayoutEffect } from 'react';
import { useNavigate, useParams, Outlet, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Icon, Button, Fade, InputElement } from '@column/column-ui-kit';
import { CustomPageContext } from '../../Layout/Route/CustomPage';
import { PageTabNavigation } from '../../Layout/Route/Page';
import {
  EditBox,
  EditSection,
  EditPage,
  EditToolbar,
  EditTitle,
  FormElement,
  FormLabel,
  FormText,
  Headline,
} from '~/styles';
import { Breadcrumb, Copy, CopyInput, NotificationList, RestrictedBanner } from '~/components';
import { ROUTE } from '~/app/routes';
import { useNotificationStore } from '~/stores/Notification';
import { useModalStore } from '~/stores/Modal';
import { useSessionStore } from '~/stores/Session';
import { formatNumber } from '~/util';
import { useBankAccount } from '~/hooks';
import { LogoLoading } from '~/elements';
import { BankAccount } from '~/repositories';

interface Params {
  id: string;
}

export interface BankAccountRouteProps {
  bankAccount: BankAccount;
}

const TabNavigation = styled(PageTabNavigation)`
  margin-bottom: 0;
  margin-top: 32px;
`;

const StyledFade = styled(Fade)`
  margin-top: 10%;
`;

const Balance = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 20px;
  grid-column: 1 / 3;
`;

const FormTextAmount = styled(FormText)<{ isNegative?: boolean; isAvailable?: boolean }>`
  font-size: 24px;
  font-weight: 600;
  padding: 2px 0;
  color: ${({ theme }) => theme.secondary.background};

  ${({ isNegative }) =>
    isNegative &&
    css`
      color: ${({ theme }) => theme.danger.background};
    `}

  ${({ isAvailable, isNegative }) =>
    isAvailable &&
    !isNegative &&
    css`
      color: ${({ theme }) => theme.primary.background};
    `}
`;

const Buttons = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-end;
`;

const FormLabelAmount = styled(FormLabel)`
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

const QuickNumber = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

const StyledCopyInput = styled(CopyInput)`
  padding: 8px 8px 8px 1px;
  background-color: ${({ theme }) => theme.body};
  max-width: 112px;

  ${InputElement} {
    background-color: ${({ theme }) => theme.body};
    position: relative;
    overflow: hidden;
    margin-right: -8px;
    direction: rtl;
    cursor: auto;
    mask-image: linear-gradient(-90deg, rgb(0 0 0 / 100%) 75%, transparent 97.5%);
    font-size: 12px;
  }

  ${Copy} {
    z-index: 2;
  }
`;

export const PageBankAccountRoute: FC<CustomPageContext> = ({ entries }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    response: bankAccount,
    createRequest: fetchBankAccount,
    isLoading,
  } = useBankAccount({
    onError: (error) => {
      navigate(ROUTE.BANK_ACCOUNTS);

      addDangerNotification({
        content: error.message,
        display: 'page',
      });
    },
  });

  const { isSandbox, currentUser, currentPermission } = useSessionStore();
  const { addDangerNotification } = useNotificationStore();
  const openModal = useModalStore((state) => state.openModal);
  const { id } = useParams<keyof Params>() as Params;

  useLayoutEffect(() => {
    if (id) {
      fetchBankAccount({ id });
    }
  }, [id]);

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => ({
          isSandbox: state.isSandbox,
          isLoading: state.isLoading,
          defaultPlatformId: state.currentUser?.defaultPlatformId,
        }),
        () => {
          if (!currentUser) {
            return;
          }
          fetchBankAccount({ id });
        }
      ),
    []
  );

  return (
    <>
      <StyledFade show={isLoading || !bankAccount}>
        <LogoLoading />
      </StyledFade>
      <Fade show={!isLoading && !!bankAccount}>
        <EditPage fullWidth>
          <div>
            <EditToolbar>
              <EditTitle>
                <Headline>
                  {id ? bankAccount?.displayName || bankAccount?.description || 'Account' : 'Create account'}
                </Headline>
                <Breadcrumb
                  entries={[
                    {
                      label: (
                        <>
                          <Icon.Wallet />
                          Accounts
                        </>
                      ),
                      path: ROUTE.BANK_ACCOUNTS,
                    },
                    { label: bankAccount?.displayName || bankAccount?.description || 'Account' },
                    location.pathname === `${ROUTE.BANK_ACCOUNTS}/edit/${id}`
                      ? { label: 'Transfers' }
                      : {
                          label: id
                            ? location.pathname.includes('statements')
                              ? 'Statements'
                              : 'Account Details'
                            : 'Create',
                        },
                  ]}
                />
              </EditTitle>

              <Buttons>
                {id && (
                  <>
                    <QuickNumber>
                      <Label>Account ID</Label>
                      <StyledCopyInput value={bankAccount?.id} disabled showValueHover />
                    </QuickNumber>
                    <QuickNumber>
                      <Label>Routing</Label>
                      <StyledCopyInput value={bankAccount?.routingNumber} disabled showValueHover />
                    </QuickNumber>
                    <QuickNumber>
                      <Label>Account</Label>
                      <StyledCopyInput value={bankAccount?.defaultAccountNumber} disabled showValueHover />
                    </QuickNumber>
                  </>
                )}

                {id && isSandbox && (
                  <Button
                    onClick={() =>
                      openModal('SandboxTransfer', {
                        accountNumberId: bankAccount?.defaultAccountNumberId,
                        callback: () => {
                          setTimeout(() => {
                            fetchBankAccount({ id });

                            navigate(location.pathname, { state: { trigger: 'delayedFetch' } });
                          }, 200);
                        },
                      })
                    }
                    size="small"
                    icon={<Icon.Sandbox />}
                  >
                    Simulate Transfer
                  </Button>
                )}
              </Buttons>
            </EditToolbar>

            <TabNavigation entries={entries} />
          </div>
          {currentPermission?.bankAccounts !== 'write' && <RestrictedBanner />}

          <EditPage fullWidth>
            {id && (
              <EditSection>
                <EditBox>
                  <Balance>
                    <FormElement>
                      <FormTextAmount isNegative={(bankAccount?.balances?.availableAmount ?? 0) < 0} isAvailable>
                        {formatNumber(bankAccount?.balances?.availableAmount)}
                      </FormTextAmount>
                      <FormLabelAmount>Available</FormLabelAmount>
                    </FormElement>
                    <FormElement>
                      <FormTextAmount isNegative={(bankAccount?.balances?.pendingAmount ?? 0) < 0}>
                        {formatNumber(bankAccount?.balances?.pendingAmount)}
                      </FormTextAmount>
                      <FormLabelAmount>Pending</FormLabelAmount>
                    </FormElement>
                    <FormElement>
                      <FormTextAmount isNegative={(bankAccount?.balances?.lockedAmount ?? 0) < 0}>
                        {formatNumber(bankAccount?.balances?.lockedAmount)}
                      </FormTextAmount>
                      <FormLabelAmount>Locked</FormLabelAmount>
                    </FormElement>
                    <FormElement>
                      <FormTextAmount isNegative={(bankAccount?.balances?.holdingAmount ?? 0) < 0}>
                        {formatNumber(bankAccount?.balances?.holdingAmount)}
                      </FormTextAmount>
                      <FormLabelAmount>Holding</FormLabelAmount>
                    </FormElement>
                  </Balance>
                </EditBox>
              </EditSection>
            )}
            {bankAccount && <Outlet context={{ bankAccount }} />}
          </EditPage>
        </EditPage>
      </Fade>
    </>
  );
};
