import React, { FC, useLayoutEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { ROUTE } from '~/app/routes';
import { ROUTE as PUBLIC_ROUTE } from '~/public/routes';
import { PlatformRolePermissions } from '~/repositories';
import { useSessionStore } from '~/stores/Session';

interface ProtectedProps {
  checkPermissions?: boolean;
}

export const Protected: FC<ProtectedProps> = ({ checkPermissions }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentPermission, signIn, signOut, authRequired, isSignedIn, setLoading } = useSessionStore();

  useLayoutEffect(() => {
    if (isSignedIn() && !authRequired()) {
      signIn().catch(signOut);
    } else {
      setTimeout(() => setLoading(false), 50);
    }

    const unsubscribe = useSessionStore.subscribe(
      (state) => [state.currentPermission, state.isLoading],
      (state) => {
        const currentPermissionState = state[0] as PlatformRolePermissions | undefined;
        const isLoading = state[1] as boolean | undefined;

        if (isLoading) {
          return;
        }

        if (!currentPermissionState) {
          navigate(`${PUBLIC_ROUTE.LOGIN}?redirect=${location.pathname}`, {
            state: {
              action: 'loginToViewThisPage',
            },
            replace: true,
          });
          return;
        }

        if (checkPermissions && currentPermissionState.platformInfo === 'none') {
          navigate(ROUTE.ROOT, {
            state: {
              from: location,
              action: 'platformInfoPermissionError',
            },
            replace: true,
          });
        }
      },
      {
        fireImmediately: true,
        equalityFn: shallow,
      }
    );
    return unsubscribe;
  }, [checkPermissions]);

  return !currentPermission || (checkPermissions && currentPermission?.platformInfo === 'none') ? null : <Outlet />;
};
