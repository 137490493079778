import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Icon, Input, Button, Textarea, Dropdown, Chip, SegmentedControl } from '@column/column-ui-kit';
import {
  EditBox,
  EditSection,
  EditSectionTitle,
  EditPage,
  EditToolbar,
  EditTitle,
  FormElement,
  FormLabel,
  FormText,
  Headline,
} from '~/styles';
import { ApiKeyInput, Breadcrumb, RestrictedBanner } from '~/components';
import { ROUTE } from '~/app/routes';
import { UpdateWebhook, Webhook, WebhookVerify, WebhookRepository, EventTypes, EventType } from '~/repositories';
import { useModalStore } from '~/stores/Modal';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';

interface Params {
  id: string;
  URLType: string;
}

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-self: end;
  justify-self: start;
`;

export const PageWebhooksEdit: React.FC = () => {
  const { currentPermission, currentUser, currentPlatform } = useSessionStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const openModal = useModalStore((state) => state.openModal);
  const navigate = useNavigate();
  const { id, URLType } = useParams<keyof Params>() as Params;
  const [webhook, setWebhook] = useState<Partial<Webhook>>();
  const [type, setType] = useState<string>('sandbox');
  const [allEvents, setAllEvents] = useState<boolean>(true);
  const [testEventType, setTestEventType] = useState<string>('');
  const [webhookResult, setWebhookResult] = useState<WebhookVerify | undefined>(undefined);

  const eventTypes = Object.values(EventTypes).filter((name: string) => !['UNRECOGNIZED'].includes(name));

  const wildcards = [
    ...new Set(
      eventTypes
        .map((name: string) => {
          const elements = name.split('.');
          const array = [];
          if (typeof elements[1] !== 'undefined') {
            array.push(`${elements[0]}.*`);
          }
          if (typeof elements[2] !== 'undefined') {
            array.push(`${elements[0]}.${elements[1]}.*`);
          }
          return array;
        })
        .flat()
    ),
  ];

  const testableEvents = useMemo(() => {
    return (
      allEvents
        ? eventTypes
        : [
            ...new Set(
              webhook?.enabledEvents
                ?.filter((eventId: string) => eventId !== '*')
                .flatMap((eventId: string) => {
                  if (eventId.includes('*')) {
                    return eventTypes.filter((name: string) => name.startsWith(eventId.replace('*', '')));
                  }
                  return eventId;
                }) ?? []
            ),
          ]
    ).map((name: string) => ({
      label: name,
      value: name,
    }));
  }, [allEvents, webhook]);

  const availableEvents = useMemo(() => {
    return [...eventTypes, ...wildcards].sort().filter((eventId: string) => {
      return (
        !webhook?.enabledEvents?.includes(eventId) && !webhook?.enabledEvents?.includes(`${eventId.split('.')[0]}.*`)
      );
    });
  }, [webhook]);

  const handleModalOpen = (eventType: string, webhookResultProps?: WebhookVerify) => {
    openModal('WebhookResult', {
      id,
      webhookResult: webhookResultProps ?? webhookResult,
      testableEvents,
      testEventType: eventType,
    });
  };

  const handleSubmit = () => {
    if (!currentUser || !webhook) {
      return;
    }

    if (id) {
      handleSave();
      return;
    }

    const data = {
      ...webhook,
      enabledEvents: allEvents ? ['*'] : webhook.enabledEvents,
    };

    WebhookRepository.create(data as any, type === 'sandbox')
      .then((response) => {
        navigate(`${ROUTE.PLATFORM_WEBHOOKS}/edit/${type === 'sandbox' ? 'Sandbox' : 'Production'}/${response.id}`);
        addSuccessNotification({
          content: 'Webhook added',
          display: 'page',
        });
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  const handleSave = (callback?: () => void) => {
    if (!id || !currentUser || !webhook) {
      return;
    }

    const data = {
      ...webhook,
      enabledEvents: allEvents ? ['*'] : webhook?.enabledEvents?.filter((eventId: string) => eventId !== '*'),
    };

    delete data.createdAt;
    delete data.updatedAt;
    delete data.secret;

    WebhookRepository.update(id, data as UpdateWebhook, URLType === 'Sandbox')
      .then(() => {
        if (callback) {
          callback();
          return;
        }
        addSuccessNotification({
          content: 'Webhook saved',
          display: 'page',
        });
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  const handleTestWebhook = () => {
    if (!currentUser) {
      return;
    }

    if (!testEventType) {
      addDangerNotification({
        content: 'Select an event type first',
        display: 'page',
      });
      return;
    }

    handleSave(() => {
      WebhookRepository.verify(
        id,
        {
          eventType: testEventType as EventType,
        },
        URLType === 'Sandbox'
      )
        .then((response) => {
          setWebhookResult(response);

          if (response.success) {
            addSuccessNotification({
              content: `${testEventType} successfully sent`,
              display: 'page',
              actionLabel: 'View Result',
              onActionClick: () => handleModalOpen(testEventType, response),
            });
            return;
          }

          addDangerNotification({
            content: `${response.responseCode} error`,
            display: 'page',
            actionLabel: 'View Result',
            onActionClick: () => handleModalOpen(testEventType, response),
          });
        })
        .catch((error) => {
          addDangerNotification({
            content: error.message,
            display: 'page',
          });
        });
    });
  };

  const handleAddEvent = (eventId: string) => {
    const events = webhook?.enabledEvents ?? [];
    events.push(eventId);
    setWebhook({ ...webhook, enabledEvents: events });
  };

  const handleRemoveEvent = (eventId: string) => {
    const events = webhook?.enabledEvents ?? [];
    setWebhook({ ...webhook, enabledEvents: events.filter((e: string) => e !== eventId) });
  };

  const fetchData = () => {
    WebhookRepository.get(id, URLType === 'Sandbox')
      .then((response) => {
        setWebhook(response);
        setAllEvents(!!response.enabledEvents?.includes('*'));
      })
      .catch((error: any) => {
        navigate(ROUTE.PLATFORM_WEBHOOKS);
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  useEffect(() => {
    if (id && currentUser) {
      fetchData();
    }
  }, [id]);

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => ({
          isLoading: state.isLoading,
          defaultPlatformId: state.currentUser?.defaultPlatformId,
        }),
        () => {
          if (id && currentUser) {
            fetchData();
          }
        }
      ),
    []
  );

  return (
    <EditPage fullWidth>
      <EditTitle>
        <Headline>{id ? 'Edit webhook' : 'Create webhook'}</Headline>
        <Breadcrumb
          entries={[
            {
              label: (
                <>
                  <Icon.Layers />
                  Platform
                </>
              ),
              path: ROUTE.PLATFORM,
            },
            {
              label: 'Webhooks',
              path: ROUTE.PLATFORM_WEBHOOKS,
            },
            {
              label: id ? 'Edit ' : 'Create ',
            },
          ]}
        />
      </EditTitle>

      {currentPermission?.webhooks !== 'write' && <RestrictedBanner />}

      <EditPage fullWidth>
        <EditSection>
          <EditSectionTitle>
            <Icon.List />
            General
          </EditSectionTitle>
          <EditBox data-disabled={currentPermission?.webhooks !== 'write'}>
            <FormElement newRow>
              <FormLabel>URL</FormLabel>
              <Input
                value={webhook?.url ?? ''}
                onChange={(value: string) => setWebhook({ ...webhook, url: value })}
                placeholder="URL"
              />
            </FormElement>
            {id && (
              <>
                <FormElement newRow>
                  <FormLabel>Test</FormLabel>
                  <Dropdown
                    search
                    active={testEventType}
                    fullWidth
                    placeholder="Select event type"
                    variant="muted"
                    options={testableEvents}
                    onChange={(value: any) => setTestEventType(value)}
                  />
                </FormElement>
                <List>
                  <Button onClick={handleTestWebhook} variant="secondary" icon={<Icon.Bell />}>
                    Send
                  </Button>
                  {webhookResult && (
                    <Button
                      variant="secondary"
                      onClick={() => handleModalOpen(testEventType)}
                      icon={<Icon.CircleInfo />}
                    >
                      Result
                    </Button>
                  )}
                </List>
              </>
            )}
            <FormElement fullWidth>
              <FormLabel>Environment</FormLabel>
              {id ? (
                <FormText>
                  <Chip icon={URLType === 'Production' ? <Icon.CircleCheck /> : <Icon.Sandbox />}>{URLType}</Chip>
                </FormText>
              ) : (
                <SegmentedControl
                  options={[
                    {
                      label: (
                        <>
                          <Icon.CircleCheck />
                          Production
                        </>
                      ),
                      value: 'production',
                    },
                    {
                      label: (
                        <>
                          <Icon.Sandbox />
                          Sandbox
                        </>
                      ),
                      value: 'sandbox',
                    },
                  ]}
                  active={type}
                  onOptionChange={setType}
                  withIcon
                  isDisabled={!currentPlatform?.isLiveEnabled}
                />
              )}
            </FormElement>
            <FormElement fullWidth>
              <FormLabel>Description</FormLabel>
              <Textarea
                value={webhook?.description}
                onChange={(value: string) => setWebhook({ ...webhook, description: value })}
                placeholder="Description"
              />
            </FormElement>
            <FormElement fullWidth>
              <FormLabel>Events</FormLabel>
              <List>
                <SegmentedControl
                  options={[
                    {
                      label: 'All',
                      value: true,
                    },
                    {
                      label: 'Custom',
                      value: false,
                    },
                  ]}
                  active={allEvents}
                  onOptionChange={(value: any) => setAllEvents(value)}
                />
                <Dropdown
                  search
                  label="Please select"
                  isDisabled={allEvents}
                  options={availableEvents.map((name: string) => ({
                    label: name,
                    onClick: () => handleAddEvent(name),
                  }))}
                />
                {(webhook?.enabledEvents ?? [])
                  .filter((eventId: string) => eventId !== '*')
                  .map((eventId: string) => (
                    <Button
                      onClick={() => handleRemoveEvent(eventId)}
                      variant="muted"
                      icon={<Icon.Cross />}
                      isDisabled={allEvents}
                      iconRight
                      key={eventId}
                    >
                      {eventId}
                    </Button>
                  ))}
              </List>
            </FormElement>
            {id && (
              <>
                <FormElement fullWidth>
                  <FormLabel>Secret</FormLabel>
                  <ApiKeyInput value={webhook ? (webhook.secret ?? '') : ''} placeholder="Secret Will be Generated" />
                </FormElement>
                <FormElement>
                  <FormLabel>Status</FormLabel>
                  <SegmentedControl
                    active={webhook ? (webhook.isDisabled ?? false) : false}
                    options={[
                      {
                        label: 'Enabled',
                        value: false,
                      },
                      {
                        label: 'Disabled',
                        value: true,
                      },
                    ]}
                    onOptionChange={(option: any) => setWebhook({ ...webhook, isDisabled: option })}
                  />
                </FormElement>
              </>
            )}
          </EditBox>
        </EditSection>
        <EditToolbar>
          <Button onClick={() => navigate(-1)} variant="secondary">
            {id ? 'Back' : 'Cancel'}
          </Button>
          <Button onClick={handleSubmit} isDisabled={currentPermission?.webhooks !== 'write'}>
            {id ? 'Save' : 'Create'}
          </Button>
        </EditToolbar>
      </EditPage>
    </EditPage>
  );
};
