import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Icon } from '@column/column-ui-kit';
import { EventRepository, getEventType, getEventStatus, EventTypes } from '~/repositories';
import {
  EmptyState,
  PaginationWrapper,
  PaginationWrapperRefProps,
  PopoverFilterEntry,
  NotificationList,
  TableColumn,
  PageHeader,
} from '~/components';
import { ROUTE } from '~/app/routes';
import { useModalStore } from '~/stores/Modal';
import { isValidDate } from '~/util';
import { JSON } from '~/elements';

export const PageEvents: React.FC = () => {
  const openModal = useModalStore((state) => state.openModal);
  const navigate = useNavigate();
  const paginationRef = useRef<PaginationWrapperRefProps>(null);

  const handleFetch = async (params: any) => {
    return EventRepository.getAll(params).then((response) => {
      const entries: any = [];
      if (response.events.length) {
        response.events.map((entry: any) => {
          entries.push({
            id: entry.id,
            type: entry.type,
            status: entry.type,
            created: entry.createdAt,
            data: entry.data,
            action: (
              <div>
                <Button
                  onlyIcon
                  icon={<JSON />}
                  size="small"
                  variant="secondary"
                  onClick={() => {
                    openModal('EventDetail', {
                      data: entry.data,
                    });
                  }}
                />
              </div>
            ),
          });
        });
      }
      return { entries, hasMore: Boolean(response?.hasMore) };
    });
  };

  const eventTypes = Object.values(EventTypes).filter((name: string) => !['UNRECOGNIZED'].includes(name));

  const filter: PopoverFilterEntry[] = [
    {
      id: 'types',
      label: 'Type',
      type: 'select',
      list: eventTypes.map((eventType) => ({
        label: eventType,
        value: eventType,
      })),
    },
    {
      id: 'created',
      label: 'Created',
      type: 'date',
    },
  ];

  const columns: TableColumn[] = [
    {
      Header: 'Type',
      accessor: 'type',
      percent: 40,
      Cell: (current) => getEventType(current.value),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: (current) => getEventStatus(current.value),
    },
    {
      Header: 'Created',
      Cell: (current) => (isValidDate(current.value) ? current.value.toLocaleDateString('en-US') : '-'),
      accessor: 'created',
      sortType: 'datetime',
    },
    {
      Header: '',
      accessor: 'action',
    },
  ];

  return (
    <>
      <PageHeader title="Events" />
      <NotificationList display="page" />
      <PaginationWrapper
        tableId="events"
        ref={paginationRef}
        fetch={handleFetch}
        columns={columns}
        filter={filter}
        rowClick={(entry: any) => {
          openModal('EventDetail', { data: entry.original.data });
        }}
        empty={
          <EmptyState headline="No events found" text="Create your first person or a business entity.">
            <Button onClick={() => navigate(`${ROUTE.ENTITIES}/edit/person`)} size="small" icon={<Icon.User />}>
              Create Entity
            </Button>
          </EmptyState>
        }
      />
    </>
  );
};
