import React, { useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Icon } from '@column/column-ui-kit';
import { SandboxIcon, LiveIcon } from '~/components/Popover/PlatformStatus/PlatformStatus';
import { PopoverPlatformStatus, PopoverPlatformSandbox } from '~/components';
import { useSessionStore } from '~/stores/Session';

const Wrapper = styled.div`
  position: relative;
`;

const Action = styled.div<{ isSandbox: boolean; $isLiveEnabled: boolean }>`
  font-size: 14px;
  font-weight: 500;
  display: flex;
  cursor: pointer;
  align-items: center;
  position: relative;
  align-items: center;
  padding: 8px 8px 8px 12px;
  box-sizing: border-box;
  border-radius: 8px;
  background: transparent;
  color: ${({ theme }) => theme.secondary.background};
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(150)};
  transition:
    box-shadow 0.2s,
    background 0.2s;

  &:hover {
    background: ${({ theme }) => theme.secondary.blendToBackground(75)};
  }

  ${({ isSandbox }) =>
    isSandbox &&
    css`
      padding: 8px 8px 8px 12px;
      gap: 4px;
      background: ${({ theme }) => theme.orange.blendToBackground(200)};
      box-shadow: none;

      ${Arrow} {
        svg {
          --icon-color: ${({ theme }) => theme.orange.background};
        }
      }

      ${ActionLabel} {
        color: ${({ theme }) => theme.orange.blendToBackground(1200)};
      }

      &:hover {
        background: ${({ theme }) => theme.orange.blendToBackground(275)};
      }
    `}

  ${({ $isLiveEnabled }) =>
    !$isLiveEnabled &&
    css`
      padding: 8px 12px;
      gap: 0px;
    `};

  ${({ theme }) =>
    theme.id !== 'default' &&
    css<{ isSandbox: boolean }>`
      box-shadow: inset 0 0 0 1px ${theme.secondary.blendToBackground(100)};

      &:hover {
        background: ${theme.secondary.blendToBackground(25)};
        box-shadow: inset 0 0 0 1px ${theme.secondary.blendToBackground(150)};
      }
      ${({ isSandbox }) =>
        isSandbox &&
        css`
          background: ${theme.orange.blendToBackground(25)};
          box-shadow: none;

          &:hover {
            background: ${theme.orange.blendToBackground(50)};
            box-shadow: none;
          }

          ${Arrow} {
            svg {
              --icon-color: ${theme.orange.background};
            }
          }
        `};
    `};
`;

const ActionLabel = styled.div`
  display: flex;
  flex: auto;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  transition: color 0.2s;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 4px;
  color: ${({ theme }) => theme.secondary.background};

  & > svg {
    --icon-size: 16px;
  }
`;

const Arrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;

  & > svg {
    --icon-size: 20px;
    z-index: 10;
    position: relative;
    cursor: pointer;

    path {
      transition:
        stroke 0.2s,
        fill 0.2s;
    }
  }
`;

export const PlatformStatus: React.FC = () => {
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const actionRef = useRef<HTMLDivElement>(null);
  const { isSandbox, currentPlatform } = useSessionStore();

  const handleTriggerClick = useCallback(() => {
    setShowPopover((s) => !s);
  }, []);

  const handlePopoverClose = useCallback(
    (event: MouseEvent) => {
      if (!(event.target instanceof Element)) {
        return;
      }
      if (actionRef.current && (event.target === actionRef.current || actionRef.current.contains(event.target))) {
        return;
      }

      setShowPopover(false);
    },
    [actionRef, currentPlatform?.isLiveEnabled]
  );

  return (
    <Wrapper>
      <Action
        ref={actionRef}
        onClick={handleTriggerClick}
        isSandbox={isSandbox || false}
        $isLiveEnabled={currentPlatform?.isLiveEnabled || false}
      >
        <ActionLabel>
          {currentPlatform?.isLiveEnabled ? (
            <>
              {isSandbox && currentPlatform?.isLiveEnabled ? <SandboxIcon /> : <LiveIcon />}
              {isSandbox ? 'Sandbox' : 'Live'}
            </>
          ) : (
            <>
              <Icon.Lock /> Sandbox
            </>
          )}
        </ActionLabel>

        <Arrow>{showPopover ? <Icon.ChevronUp /> : <Icon.ChevronDown />}</Arrow>
      </Action>

      {currentPlatform?.isLiveEnabled ? (
        <PopoverPlatformStatus show={showPopover} onClose={handlePopoverClose} />
      ) : (
        <PopoverPlatformSandbox show={showPopover} onClose={handlePopoverClose} />
      )}
    </Wrapper>
  );
};
