import { client } from '../client';
import { uploadFile } from '../util/upload';
import { convertValues } from '~/util';
import {
  CheckTransfer,
  CheckReturnReason,
  CheckIssueRequest,
  CheckTransferImages,
  CheckTransferList,
  CheckDepositRequest,
  CheckFrontImageRequest as CheckFrontImageRequestAPI,
  CheckBackImageRequest,
  CheckFrontImage,
  CheckBackImage,
} from '~/typings/API';
import { API_URL } from '~/constants';
import { useSessionStore } from '~/stores/Session';

export type {
  CheckTransfer,
  CheckReturnReason,
  CheckIssueRequest,
  CheckDepositRequest,
  CheckTransferImages,
  CheckFrontImage,
  CheckBackImage,
};

interface CheckReturnRequest {
  return_reason: CheckReturnReason;
}

interface CheckServiceListChecksParams {
  limit?: number;
  startingAfter?: string;
  endingBefore?: string;
  'created.gt'?: string;
  'created.gte'?: string;
  'created.lt'?: string;
  'created.lte'?: string;
}

const checkTransferCast = ({ checkNumber, micrLine }: CheckTransfer) => ({
  checkNumber,
  micrLine,
});

const captureFrontImageCast = ({ micrLine }: CheckFrontImage) => ({
  micrLine,
});

interface ConvertImageRequest {
  fileName: string;
  image: string;
}

interface ConvertImageResponse {
  image: string;
}

export type CheckFrontImageRequest = CheckFrontImageRequestAPI & { bypassDocCropping?: boolean };

export class CheckRepository {
  static async issue(checkTransfer: CheckIssueRequest, idempotency?: string) {
    return client
      .post<CheckIssueRequest, CheckTransfer>('/transfers/checks/issue', checkTransfer, { idempotency })
      .then((response) => convertValues<CheckTransfer>(response, undefined, checkTransferCast(response)));
  }

  static async deposit(checkTransfer: CheckDepositRequest) {
    return client
      .post<CheckDepositRequest, CheckTransfer>('/transfers/checks/deposit', checkTransfer)
      .then((response) => convertValues<CheckTransfer>(response, undefined, checkTransferCast(response)));
  }

  static async captureFrontImage(request: CheckFrontImageRequest) {
    return uploadFile<CheckFrontImageRequest, CheckFrontImage>('/transfers/checks/image/front', request).then(
      (response) => convertValues<CheckFrontImage>(response, undefined, captureFrontImageCast(response))
    );
  }

  static async captureBackImage(request: CheckBackImageRequest) {
    return uploadFile<CheckBackImageRequest, CheckBackImage>('/transfers/checks/image/back', request).then((response) =>
      convertValues<CheckBackImage>(response)
    );
  }

  static async convertImage(request: ConvertImageRequest) {
    return client
      .post<ConvertImageRequest, ConvertImageResponse>('/transfers/checks/image/convert-to-jpg', request)
      .then((response) => convertValues<ConvertImageResponse>(response));
  }

  static async createReturn(checkTransferId: string, request: CheckReturnRequest) {
    return client.post<CheckReturnRequest, object>(`/transfers/checks/${checkTransferId}/return`, request);
  }

  static async stopPayment(checkTransferId: string) {
    return client
      .post<object, CheckTransfer>(`/transfers/checks/${checkTransferId}/stop-payment`, {})
      .then((response) => convertValues<CheckTransfer>(response, undefined, checkTransferCast(response)));
  }

  static async get(request: GetRequestType) {
    return client
      .get<unknown, CheckTransfer>(`/transfers/checks/${request.id}`)
      .then((response) => convertValues<CheckTransfer>(response, undefined, checkTransferCast(response)));
  }

  static async getImages(checkTransferId: string) {
    return client
      .get<unknown, CheckTransferImages>(`/transfers/checks/${checkTransferId}/images`)
      .then((response) => convertValues<CheckTransferImages>(response));
  }

  static async previewPDF(checkTransferId: string): Promise<string> {
    const controller = new AbortController();

    const timeoutId = setTimeout(() => controller.abort(), 360000);

    return fetch(`${API_URL}/transfers/checks/${checkTransferId}/preview-pdf`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Live-Mode': useSessionStore.getState().isSandbox ? 'No' : 'Yes',
        'Platform-Id': useSessionStore.getState().currentUser?.defaultPlatformId ?? '',
      },
      credentials: 'include',
      signal: controller.signal,
    })
      .then(async (response) => {
        clearTimeout(timeoutId);

        if (!response.ok) {
          throw new Error('Error while getting the preview PDF');
        }

        const blob = await response.blob();
        return URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
      })
      .catch((response) => {
        clearTimeout(timeoutId);
        return response;
      });
  }

  static async getAll(query?: Partial<CheckServiceListChecksParams>) {
    return client
      .get<CheckServiceListChecksParams, CheckTransferList>('/transfers/wire', { query })
      .then((response) => ({
        transfers: Array.isArray(response?.transfers)
          ? response.transfers.map((e) => convertValues<CheckTransfer>(e, undefined, checkTransferCast(e)))
          : [],
        hasMore: Boolean(response?.hasMore),
      }));
  }
}
