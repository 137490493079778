import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { shallow } from 'zustand/shallow';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Icon, Fade } from '@column/column-ui-kit';
import { ROUTE } from '~/app/routes';
import { GetStarted, SandboxBanner } from '~/app/Layout';
import { Headline, EditSection, EditSectionTitle } from '~/styles';
import { PaginationWrapper, NotificationList, TableColumn, EmptyState, PaginationWrapperRefProps } from '~/components';
import { LogoLoading, JSON } from '~/elements';
import { useModalStore } from '~/stores/Modal';
import { useSessionStore } from '~/stores/Session';
import {
  clientWrapper,
  EntityRepository,
  BankAccountRepository,
  ApiKeyRepository,
  WebhookRepository,
  EventRepository,
  PlatformRolePermissions,
  getEventType,
  getEventStatus,
} from '~/repositories';
import { isValidDate } from '~/util';
import { useNotificationStore } from '~/stores/Notification';
import { useHelpSidebarStore } from '~/stores/HelpSidebar';

interface LocationState {
  action?: string;
}

const Grid = styled.div`
  display: grid;
  grid-gap: 20px;
`;

const Relative = styled(EditSection)`
  position: relative;
  grid-gap: 0;
`;

const StyledEditSectionTitle = styled(EditSectionTitle)`
  margin: 16px 0;
`;

const StyledFade = styled(Fade)`
  margin-top: 10%;
`;

export const PageHome: React.FC = () => {
  const { isSandbox, currentUser, currentPermission } = useSessionStore();
  const location = useLocation();
  const openModal = useModalStore((s) => s.openModal);
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);
  const openHelpSidebar = useHelpSidebarStore((s) => s.openHelpSidebar);
  const [entities, setEntities] = useState<boolean>(false);
  const [bankAccounts, setBankAccounts] = useState<boolean>(false);
  const [apiKeys, setApiKeys] = useState<boolean>(false);
  const [webhooks, setWebhooks] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const state = location.state as LocationState;

  const fetchData = (defaultPlatformId: string, currentPermissionState: PlatformRolePermissions) => {
    const promises = [];

    promises.push(
      EntityRepository.getAll().then((response) => {
        setEntities(response.entities.length > 0);
      })
    );

    promises.push(
      BankAccountRepository.getAll().then((response) => {
        setBankAccounts(response.bankAccounts.length > 0);
      })
    );

    if (currentPermissionState?.apiKeys === 'read' || currentPermissionState?.apiKeys === 'write') {
      promises.push(
        ApiKeyRepository.getAll(defaultPlatformId).then((response) => {
          setApiKeys(response.apiKeys.length > 0);
        })
      );
    } else {
      setApiKeys(false);
    }

    if (currentPermissionState?.webhooks === 'read' || currentPermissionState?.webhooks === 'write') {
      promises.push(
        WebhookRepository.getAll().then((response) => {
          setWebhooks(response.webhookEndpoints.length > 0);
        })
      );
    } else {
      setWebhooks(false);
    }

    clientWrapper(undefined, (e) => console.error('HomeFetchData', e), Promise.all(promises)).finally(() =>
      setLoading(false)
    );
  };

  useEffect(
    () =>
      useSessionStore.subscribe(
        (s) => [s.isLoading, s.isSandbox, s.currentUser?.defaultPlatformId, s.currentPermission],
        (s) => {
          const isLoading = s[0] as boolean | undefined;
          const defaultPlatformId = s[2] as string | undefined;
          const currentPermissionState = s[3] as PlatformRolePermissions | undefined;

          if (isLoading || !defaultPlatformId || !currentPermissionState) {
            return;
          }

          fetchData(defaultPlatformId, currentPermissionState);
        },
        {
          fireImmediately: true,
          equalityFn: shallow,
        }
      ),
    []
  );

  useEffect(() => {
    if (state && state.action === 'platformInfoPermissionError') {
      addDangerNotification({
        display: 'page',
        content: "You got redirected, because you don't have permissions to view this page.",
        actionLabel: 'Roles',
        onActionClick: () => openHelpSidebar('Roles'),
      });
    }
  }, [location]);

  const handleFetch = async (params: any) => {
    return EventRepository.getAll(params).then((response) => {
      const entries: any = [];
      if (response.events.length) {
        response.events.map((entry: any) => {
          entries.push({
            id: entry.id,
            type: entry.type,
            status: entry.type,
            created: entry.createdAt,
            data: entry.data,
            action: (
              <div>
                <Button
                  onlyIcon
                  icon={<JSON />}
                  size="small"
                  variant="secondary"
                  onClick={() => {
                    openModal('EventDetail', {
                      data: entry.data,
                    });
                  }}
                />
              </div>
            ),
          });
        });
      }
      return { entries, hasMore: false };
    });
  };

  const columns: TableColumn[] = [
    {
      Header: 'Type',
      accessor: 'type',
      percent: 40,
      Cell: (current) => getEventType(current.value),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: (current) => getEventStatus(current.value),
    },
    {
      Header: 'Created',
      Cell: (current) => (isValidDate(current.value) ? current.value.toLocaleDateString('en-US') : '-'),
      accessor: 'created',
      sortType: 'datetime',
    },
    {
      Header: '',
      accessor: 'action',
    },
  ];

  const navigate = useNavigate();
  const paginationRef = useRef<PaginationWrapperRefProps>(null);

  return (
    <>
      <StyledFade show={loading}>
        <LogoLoading />
      </StyledFade>
      <Fade show={!loading}>
        <div>
          <Headline withMargin>Get started, {currentUser?.firstName}!</Headline>

          <NotificationList display="page" />

          {isSandbox && <SandboxBanner />}
        </div>

        <Grid>
          <GetStarted
            showVerifyEmail={!currentUser?.isEmailVerified}
            showGenerateApiKeys={
              !apiKeys && currentPermission?.apiKeys !== 'none' && currentPermission?.apiKeys !== 'default'
            }
            showSetupMfa={!(currentUser?.isMfaVerified ?? false)}
            showAddBankAccount={
              !bankAccounts &&
              entities &&
              currentPermission?.bankAccounts !== 'none' &&
              currentPermission?.bankAccounts !== 'default'
            }
            showAddEntity={!entities && currentPermission?.entities === 'read'}
            showAddWebhook={
              !webhooks &&
              apiKeys &&
              currentPermission?.webhooks !== 'none' &&
              currentPermission?.webhooks !== 'default'
            }
            showGettingStartedGuide
          />

          <Relative>
            <StyledEditSectionTitle>
              <Icon.Calendar />
              Recent events
            </StyledEditSectionTitle>

            <PaginationWrapper
              tableId="events"
              ref={paginationRef}
              fetch={handleFetch}
              columns={columns}
              rowClick={(entry: any) => {
                openModal('EventDetail', { data: entry.original.data });
              }}
              empty={
                <EmptyState headline="No events found" text="Create your first person or a business entity.">
                  <Button onClick={() => navigate(`${ROUTE.ENTITIES}/edit/person`)} size="small" icon={<Icon.User />}>
                    Create Entity
                  </Button>
                </EmptyState>
              }
            />
          </Relative>
        </Grid>
      </Fade>
    </>
  );
};
