import React, { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { Location, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import styled, { keyframes } from 'styled-components';
import { PageAuth } from './pages/Auth';
import { LoginQueryParams, PageLogin } from './pages/Login';
import {
  PageRegisterForm,
  PageRegisterFinancialProducts,
  PageRegisterCompanyEmployees,
  PageRegisterCompliance,
  PageRegisterCustomers,
  PageRegisterBusinessYourInformation,
  PageRegisterBusinessCustomerTypes,
  PageRegisterBusinessInformation,
  PageRegisterBusinessLocation,
  PageRegisterBusinessSubmit,
  PageRegisterOpenBankAccount,
} from './pages/Register';
import { PageForgotPassword } from './pages/ForgotPassword';
import { PageNewPassword } from './pages/NewPassword';
import { PageVerify } from './pages/Verify';
import { PageContact } from './pages/Contact';
import { PagePlaidPlatformSelection } from './pages/Plaid/PlatformSelection';
import { ROUTE as APP_ROUTE } from '~/app/routes';
import { Center, Layout, Protected } from '~/public/Layout';
import { ROUTE } from '~/public/routes';
import { clientWrapper, EntityRepository, PlatformRolePermissions, UserRepository } from '~/repositories';
import { useSessionStore } from '~/stores/Session';
import { useQueryParams } from '~/hooks';
import { useNotificationStore } from '~/stores/Notification';

interface LocationState {
  action?: string;
}

interface WrapperProps {
  animation: 'fadeIn' | 'fadeOut' | '';
}

const FadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-4px);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
`;

const FadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateZ(0);
  }
  to {
    transform: translateY(4px);
    opacity: 0;
  }
`;

const Wrapper = styled.div<WrapperProps>`
  align-self: center;
  position: relative;
  animation: 0.2s ${({ animation }) => (animation === 'fadeIn' ? FadeIn : animation === 'fadeOut' ? FadeOut : 'none')}
    forwards;
`;

export const Router: React.FC = () => {
  const { isSignedIn, signOut, clearCookies, setRootEntity, setRedirectUrl, loadPlatform, currentPlatform } =
    useSessionStore();
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);
  const { getQueryParam } = useQueryParams<LoginQueryParams>();
  const location = useLocation();
  const navigate = useNavigate();
  const [displayLocation, setDisplayLocation] = useState<Location>(location);
  const [animation, setAnimation] = useState<'fadeIn' | 'fadeOut' | ''>('');
  const prevLocationRef = useRef<Location>(location);
  const firstUpdate = useRef<boolean>(true);

  const state = location.state as LocationState;

  useEffect(() => {
    if (state && state.action === 'signOut' && isSignedIn()) {
      clientWrapper(undefined, (e) => console.error('UserRepository.logout', e), UserRepository.logout()).finally(
        () => {
          signOut();
          if (location.pathname !== ROUTE.LOGIN) {
            navigate(ROUTE.LOGIN);
          }
        }
      );
    } else if (location.pathname === ROUTE.LOGIN) {
      clearCookies();
    }

    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search + location.hash });
  }, [location.pathname, state?.action]);

  useEffect(() => {
    const redirectParam = getQueryParam('redirect');

    if (redirectParam && redirectParam !== null) {
      setRedirectUrl(redirectParam + window.location.hash);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (prevLocationRef.current.pathname !== location.pathname) {
      setAnimation('fadeOut');
    }

    const timeoutId = setTimeout(() => {
      setAnimation('fadeIn');
      setDisplayLocation(location);
    }, 200);

    prevLocationRef.current = location;

    return () => clearTimeout(timeoutId);
  }, [location]);

  const onAnimationEnd = () => {
    if (animation === 'fadeOut') {
      setAnimation('');
    }
  };

  useEffect(
    () =>
      useSessionStore.subscribe(
        (s) => [s.currentPermission, s.currentUser?.defaultPlatformId, s.isLoading, s.isSignedIn()],
        (s) => {
          const currentPermission = s[0] as PlatformRolePermissions | undefined;
          const defaultPlatformId = s[1] as string | undefined;
          const isLoadingState = s[2] as boolean | undefined;
          const isSignedInState = s[3] as boolean | undefined;

          if (isLoadingState || !isSignedInState || !location.pathname.startsWith(ROUTE.REGISTER)) {
            return;
          }

          if (!defaultPlatformId || !currentPermission) {
            setRootEntity(undefined);
            return;
          }

          if (currentPermission.platformInfo === 'none') {
            return;
          }

          loadPlatform(true)
            .then((t) => {
              // void
            })
            .catch((error) => {
              console.error('Router.loadPlatform', error);

              addDangerNotification({
                display: 'page',
                content: 'Failed to load platform information.',
              });
            });

          EntityRepository.getRoot(defaultPlatformId, 'live')
            .then(setRootEntity)
            .catch(() => {
              setRootEntity(undefined);
            });
        },
        {
          fireImmediately: true,
          equalityFn: shallow,
        }
      ),
    [location.pathname]
  );

  return (
    <Layout>
      <Wrapper animation={animation} onAnimationEnd={onAnimationEnd}>
        <Routes location={displayLocation}>
          <Route path={ROUTE.ROOT} element={<Navigate to={APP_ROUTE.ROOT} />} />
          <Route element={<Center verticalCenter />}>
            <Route path={ROUTE.LOGIN} element={<PageLogin />} />
            <Route path={ROUTE.PLAID_LOGIN} element={<PageLogin isAuthFlow />} />
            <Route path={ROUTE.AUTH} element={<PageAuth />} />
            <Route path={ROUTE.PLAID_AUTH} element={<PageAuth isAuthFlow />} />
            <Route path={ROUTE.PLAID_SELECT} element={<PagePlaidPlatformSelection />} />
            <Route path={ROUTE.FORGOT_PASSWORD} element={<PageForgotPassword />} />
            <Route path={ROUTE.NEW_PASSWORD} element={<PageNewPassword />} />
            <Route path={ROUTE.REGISTER}>
              <Route path=":platformId" element={<PageRegisterForm />} />
              <Route index element={<PageRegisterForm />} />
            </Route>
            <Route element={<Protected />}>
              <Route path={ROUTE.VERIFY} element={<PageVerify />} />
            </Route>
          </Route>
          <Route element={<Protected checkPermissions />}>
            <Route element={<Center verticalCenter size="middle" loggedIn />}>
              <Route path={ROUTE.REGISTER_OPEN_BANK_ACCOUNT} element={<PageRegisterOpenBankAccount />} />
            </Route>
            <Route element={<Center size="middle" loggedIn />}>
              <Route path={ROUTE.REGISTER_FINANCIAL_PRODUCTS} element={<PageRegisterFinancialProducts />} />
              <Route path={ROUTE.REGISTER_COMPANY_EMPLOYEES} element={<PageRegisterCompanyEmployees />} />
              <Route path={ROUTE.REGISTER_COMPLIANCE} element={<PageRegisterCompliance />} />
              <Route path={ROUTE.REGISTER_CUSTOMERS} element={<PageRegisterCustomers />} />

              <Route path={ROUTE.REGISTER_BUSINESS_INFORMATION} element={<PageRegisterBusinessInformation />} />
              <Route path={ROUTE.REGISTER_BUSINESS_LOCATION} element={<PageRegisterBusinessLocation />} />
              <Route path={ROUTE.REGISTER_BUSINESS_CUSTOMER_TYPES} element={<PageRegisterBusinessCustomerTypes />} />
              <Route
                path={ROUTE.REGISTER_BUSINESS_YOUR_INFORMATION}
                element={<PageRegisterBusinessYourInformation />}
              />
              <Route path={ROUTE.REGISTER_BUSINESS_SUBMIT} element={<PageRegisterBusinessSubmit />} />
            </Route>
          </Route>
          <Route element={<Center size="large" />}>
            <Route path={ROUTE.CONTACT} element={<PageContact />} />
          </Route>
          <Route path="*" element={<Navigate to={ROUTE.LOGIN} replace />} />
        </Routes>
      </Wrapper>
    </Layout>
  );
};

export default Router;
