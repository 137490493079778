import React, { forwardRef } from 'react';
import styled from 'styled-components';

interface EventIdentityProps {
  size?: string;
}

const SVG = styled.svg`
  overflow: visible;
`;

export const EventIdentity = forwardRef<SVGSVGElement, EventIdentityProps>((props, ref) => {
  const size = props.size ?? '20px';

  return (
    <SVG
      {...props}
      ref={ref}
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8024 15.758L14.5107 15.0255C14.3632 14.6538 14.134 14.3205 13.8407 14.0488L13.7874 13.9997C13.3257 13.5713 12.719 13.333 12.089 13.333H7.9107C7.2807 13.333 6.67403 13.5713 6.21153 13.9988L6.1582 14.0488C5.86487 14.3205 5.63653 14.6538 5.4882 15.0255L5.19653 15.758"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0623 6.20584C13.2014 7.34487 13.2014 9.1916 12.0623 10.3306C10.9233 11.4697 9.07656 11.4697 7.93753 10.3306C6.79849 9.1916 6.79849 7.34487 7.93753 6.20584C9.07656 5.0668 10.9233 5.0668 12.0623 6.20584"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3033 4.6967C18.2322 7.62563 18.2322 12.3744 15.3033 15.3033C12.3744 18.2322 7.62562 18.2322 4.6967 15.3033C1.76777 12.3744 1.76777 7.62562 4.6967 4.6967C7.62563 1.76777 12.3744 1.76777 15.3033 4.6967"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
});
