import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Icon, Button, Dropdown } from '@column/column-ui-kit';
import { EditPage, EditToolbar, EditSection, EditBox, FormElement, FormLabel, Paragraph } from '~/styles';
import { Breadcrumb, Datepicker, NotificationList, RestrictedBanner } from '~/components';
import { ROUTE } from '~/app/routes';
import { NotificationPropsType, useNotificationStore } from '~/stores/Notification';
import { ReportingRepository, ReportType } from '~/repositories';
import { getDateFormat } from '~/util';
import { useSessionStore } from '~/stores/Session';

const StyledParagraph = styled(Paragraph)`
  max-width: 64ch;
  margin: 0 0 16px 0;
`;

const DateRange = styled.div`
  display: grid;
  grid-gap: 32px;
  grid-template-columns: auto 280px;
`;

const DateList = styled.div`
  margin: -20px 0;
  padding: 54px 0 24px 8px;
  border-left: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};
`;

const DatePreview = styled.span`
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};

  svg {
    display: inline-block;
    vertical-align: top;
    margin: 4px;

    --icon-size: 14px;
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(600)};
  }
`;

export const PageReportingSchedule: React.FC = () => {
  const currentPermission = useSessionStore((s) => s.currentPermission);
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();

  const [type, setType] = useState<ReportType | undefined>(undefined);
  const [dates, setDates] = useState<string[]>([]);
  const [hoverDate, setHoverDate] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleSchedule = () => {
    if (!dates || !Array.isArray(dates) || dates.length !== 2) {
      addDangerNotification({
        content: 'Please select a date range',
        display: 'page',
      });
      return;
    }
    if (!type) {
      addDangerNotification({
        content: 'Please select a type',
        display: 'page',
      });
      return;
    }

    if (isLoading) {
      return;
    }

    setIsLoading(true);

    ReportingRepository.schedule({
      fromDate: dates[0] ?? '',
      toDate: dates[1] ?? '',
      type,
    })
      .then(() => {
        addSuccessNotification({
          content: 'Report scheduled',
          display: 'page',
        });
        navigate(ROUTE.REPORTING);
      })
      .catch((error) => {
        const object: NotificationPropsType = {
          content: error.message,
          display: 'page',
        };

        if (error.code === 'invalid_field_value') {
          object.actionLabel = 'Platform Settings';
          object.timeout = 8000;
          object.onActionClick = () => {
            navigate(ROUTE.PLATFORM_SETTINGS);
          };
        }

        addDangerNotification(object);
      })
      .finally(() => setIsLoading(false));
  };

  const handleChange = (range?: 'today' | 'lastDays' | 'lastWeek' | 'lastMonth') => {
    const from = new Date();

    if (range === 'lastDays') {
      from.setDate(from.getDate() - 3);
    }

    if (range === 'lastWeek') {
      from.setDate(from.getDate() - 7);
    }

    if (range === 'lastMonth') {
      from.setMonth(from.getMonth() - 1);
    }

    setDates([getDateFormat(from), getDateFormat(new Date())]);
  };

  return (
    <EditPage>
      <EditToolbar>
        <Breadcrumb
          entries={[
            {
              label: (
                <>
                  <Icon.DocumentEdit />
                  Reporting
                </>
              ),
              path: ROUTE.REPORTING,
            },
            {
              label: 'Create',
            },
          ]}
        />
      </EditToolbar>

      {currentPermission?.reporting !== 'write' && <RestrictedBanner />}

      <EditSection>
        <StyledParagraph size="small">
          Create a report for a date range or single day. Reports that include the current business day will be
          completed at the end of the day.
        </StyledParagraph>
        <EditBox data-disabled={currentPermission?.reporting !== 'write'}>
          <FormElement>
            <FormLabel>Type</FormLabel>
            <Dropdown
              options={[
                {
                  label: 'Bank account summary',
                  value: 'bank_account_summary',
                },
                {
                  label: 'Bank account transaction',
                  value: 'bank_account_transaction',
                },
              ]}
              active={type}
              fullWidth
              variant="muted"
              onChange={(value: ReportType) => setType(value)}
            />
          </FormElement>
          <EditBox pale bottom oneColumn>
            <DateRange>
              <FormElement>
                <FormLabel>
                  Date Range{' '}
                  {dates.length >= 1 && (
                    <DatePreview>
                      ({dates[0]}
                      <Icon.ArrowRight />
                      {(dates.length === 2 ? dates[1] : hoverDate) ?? '?'})
                    </DatePreview>
                  )}
                </FormLabel>
                <Datepicker
                  date={dates}
                  onDateSubmit={(value) => {
                    setDates(value as string[]);
                  }}
                  showMonths={2}
                  disableBefore={new Date('2022-01-01')}
                  disableAfter={new Date()}
                  onHoverDate={(value) => setHoverDate(value)}
                />
              </FormElement>
              <DateList>
                <Button variant="subtle" onClick={() => handleChange('today')}>
                  Today
                </Button>
                <Button variant="subtle" onClick={() => handleChange('lastDays')}>
                  Last 3 Days
                </Button>
                <Button variant="subtle" onClick={() => handleChange('lastWeek')}>
                  Last Week
                </Button>
                <Button variant="subtle" onClick={() => handleChange('lastMonth')}>
                  Last Month
                </Button>
              </DateList>
            </DateRange>
          </EditBox>
        </EditBox>
      </EditSection>

      <EditToolbar>
        <Button onClick={() => navigate(ROUTE.REPORTING)} variant="secondary">
          Cancel
        </Button>
        <Button onClick={handleSchedule} isLoading={isLoading} isDisabled={currentPermission?.reporting !== 'write'}>
          Create
        </Button>
      </EditToolbar>
    </EditPage>
  );
};
