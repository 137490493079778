import { createApiHook } from './utils/createApiHook';
import { ReportingListParams, ReportingListResponse, ReportingRepository } from '~/repositories';

export type ReportsFilterType = ReportingListParams & {
  page?: number;
};

export const useReporting = createApiHook<ReportingListResponse, ReportsFilterType>(ReportingRepository.getAll, {
  triggerAutomatically: true,
  triggerOnSessionStoreSubscribe: true,
  includeQueryParams: true,
  addQueryParamsToUrl: true,
  excludeQueryParams: ['page'],
});
