import React, { forwardRef } from 'react';
import styled from 'styled-components';

interface LogoProps {
  variant?: 'full' | 'word';
  size?: string;
}

const SVG = styled.svg`
  overflow: visible;
  fill: var(--logo-color, ${({ theme }) => theme.foreground});
`;

export const Logo = forwardRef<SVGSVGElement, LogoProps>((props, ref) => {
  const size = props.size ?? '24px';

  if (props.variant === 'full') {
    return (
      <SVG
        {...props}
        ref={ref}
        height={size}
        width={`${parseInt(size, 10) * 6.041}px`}
        viewBox="0 0 145 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path d="M9 9V15H12C13.5913 15 15.1174 15.6321 16.2426 16.7574C17.3679 17.8826 18 19.4087 18 21V24H6C4.4087 24 2.88258 23.3679 1.75736 22.2426C0.632141 21.1174 0 19.5913 0 18V6C0 4.4087 0.632141 2.88258 1.75736 1.75736C2.88258 0.632141 4.4087 0 6 0L18 0C19.5913 0 21.1174 0.632141 22.2426 1.75736C23.3679 2.88258 24 4.4087 24 6V9H9Z" />
        </g>
        <path d="M144.941 23.5906H140.96V13.9059C141.002 13.3849 140.933 12.8609 140.76 12.368C140.586 11.8751 140.31 11.4243 139.95 11.0449C139.591 10.6655 139.156 10.3659 138.673 10.1656C138.19 9.9653 137.671 9.8688 137.148 9.88235C134.706 9.88235 132.913 11.6329 132.913 14.7106V23.6188H128.96V6.67765H132.659V8.69647C133.345 7.90673 134.196 7.27738 135.153 6.8529C136.109 6.42843 137.147 6.2192 138.193 6.24C139.094 6.21602 139.99 6.3775 140.827 6.71441C141.663 7.05131 142.421 7.55646 143.054 8.1985C143.686 8.84054 144.18 9.60578 144.505 10.4467C144.83 11.2877 144.978 12.1864 144.941 13.0871V23.5906Z" />
        <path d="M124.922 23.5906H120.941V13.7082C120.971 13.2326 120.904 12.7558 120.743 12.3073C120.582 11.8587 120.33 11.448 120.004 11.1004C119.678 10.7529 119.284 10.4759 118.847 10.2865C118.409 10.0971 117.938 9.99941 117.461 9.99941C116.985 9.99941 116.513 10.0971 116.076 10.2865C115.638 10.4759 115.244 10.7529 114.918 11.1004C114.592 11.448 114.341 11.8587 114.18 12.3073C114.018 12.7558 113.951 13.2326 113.981 13.7082V23.6612H110.042V13.7788C110.086 13.2983 110.029 12.8141 109.875 12.3569C109.721 11.8997 109.473 11.4796 109.147 11.1235C108.822 10.7673 108.426 10.4828 107.985 10.2882C107.543 10.0936 107.066 9.99306 106.584 9.99306C106.101 9.99306 105.624 10.0936 105.182 10.2882C104.741 10.4828 104.345 10.7673 104.02 11.1235C103.694 11.4796 103.447 11.8997 103.292 12.3569C103.138 12.8141 103.081 13.2983 103.125 13.7788V23.7318H99.1718V6.79059H102.744V8.73882C103.292 7.96829 104.015 7.33961 104.855 6.90501C105.695 6.47041 106.626 6.24243 107.572 6.24C108.579 6.23261 109.57 6.48944 110.447 6.98488C111.324 7.48031 112.056 8.19703 112.569 9.06353C113.216 8.17514 114.066 7.45499 115.049 6.96364C116.032 6.47229 117.118 6.22412 118.216 6.24C119.07 6.21179 119.921 6.35306 120.72 6.65565C121.519 6.95825 122.25 7.41617 122.871 8.00295C123.492 8.58974 123.99 9.29375 124.338 10.0743C124.685 10.8549 124.874 11.6965 124.894 12.5506L124.922 23.5906Z" />
        <path d="M94.9929 6.64942V23.5906H91.2941V21.5294C90.5976 22.3375 89.7287 22.9789 88.7514 23.4065C87.774 23.8341 86.7132 24.0369 85.6471 24C84.7428 24.0222 83.8436 23.8582 83.0054 23.5179C82.1672 23.1777 81.4081 22.6687 80.7751 22.0225C80.1421 21.3762 79.6489 20.6067 79.3261 19.7617C79.0033 18.9166 78.8579 18.0142 78.8988 17.1106V6.64942H82.8518V16.3341C82.8074 16.8581 82.8748 17.3855 83.0496 17.8814C83.2243 18.3773 83.5024 18.8305 83.8655 19.2108C84.2285 19.5912 84.6682 19.8902 85.1554 20.0879C85.6427 20.2856 86.1663 20.3775 86.6918 20.3577C89.12 20.3577 90.9271 18.6353 90.9271 15.5577V6.64942H94.9929Z" />
        <path d="M74.8753 0V23.5906H70.9223V4.64471C70.9223 3.36 70.4 2.62588 69.44 2.62588H68.7059V0H74.8753Z" />
        <path d="M58.6682 6.24C60.4203 6.25394 62.129 6.78585 63.5794 7.76879C65.0298 8.75172 66.157 10.1417 66.8191 11.7639C67.4812 13.386 67.6487 15.1678 67.3004 16.8849C66.9521 18.602 66.1036 20.1777 64.8618 21.4137C63.62 22.6497 62.0402 23.4906 60.3215 23.8308C58.6028 24.1709 56.8218 23.995 55.2028 23.3252C53.5838 22.6554 52.1992 21.5216 51.2231 20.0666C50.2471 18.6116 49.7233 16.9003 49.7177 15.1482C49.7176 13.9748 49.9495 12.813 50.3998 11.7294C50.8501 10.6458 51.5101 9.6619 52.3418 8.83414C53.1735 8.00638 54.1605 7.35109 55.2462 6.90591C56.3319 6.46072 57.4948 6.23442 58.6682 6.24V6.24ZM58.6682 9.85412C57.9868 9.85913 57.3134 10.0015 56.6882 10.2728C56.0631 10.544 55.499 10.9385 55.0299 11.4328C54.5607 11.927 54.196 12.5107 53.9576 13.1491C53.7192 13.7875 53.612 14.4675 53.6424 15.1482C53.6138 15.8221 53.7219 16.4948 53.9602 17.1258C54.1986 17.7568 54.5621 18.3331 55.0291 18.8198C55.4961 19.3065 56.0567 19.6937 56.6773 19.958C57.2978 20.2223 57.9655 20.3583 58.64 20.3576C59.3176 20.3579 59.9884 20.2223 60.6127 19.9588C61.237 19.6953 61.8021 19.3093 62.2746 18.8236C62.7472 18.3379 63.1175 17.7624 63.3637 17.1311C63.61 16.4999 63.7271 15.8256 63.7082 15.1482C63.7327 14.4714 63.6217 13.7965 63.3818 13.1632C63.1418 12.5299 62.7776 11.951 62.3107 11.4604C61.8438 10.9698 61.2836 10.5775 60.6629 10.3065C60.0422 10.0355 59.3737 9.89132 58.6965 9.88235L58.6682 9.85412Z" />
        <path d="M40.9506 6.24C42.2777 6.2301 43.5904 6.51498 44.794 7.07406C45.9976 7.63314 47.0621 8.45248 47.9106 9.47294L44.96 11.9153C44.5029 11.2874 43.9042 10.7761 43.2124 10.4229C42.5207 10.0697 41.7555 9.88447 40.9788 9.88235C40.2999 9.88751 39.6289 10.029 39.0057 10.2985C38.3824 10.568 37.8197 10.96 37.351 11.4512C36.8822 11.9424 36.5168 12.5227 36.2767 13.1578C36.0365 13.7929 35.9264 14.4698 35.9529 15.1482C35.9244 15.8221 36.0325 16.4948 36.2708 17.1258C36.5091 17.7568 36.8727 18.3331 37.3397 18.8198C37.8066 19.3065 38.3673 19.6937 38.9879 19.958C39.6084 20.2223 40.2761 20.3582 40.9506 20.3576C41.795 20.3717 42.6287 20.1672 43.3708 19.764C44.1129 19.3608 44.7382 18.7726 45.1859 18.0565L48.2918 20.2871C47.4493 21.4428 46.3445 22.382 45.0683 23.0275C43.792 23.673 42.3808 24.0063 40.9506 24C38.5955 24 36.3368 23.0644 34.6715 21.3991C33.0062 19.7338 32.0706 17.4751 32.0706 15.12C32.0706 12.7649 33.0062 10.5062 34.6715 8.84089C36.3368 7.17557 38.5955 6.24 40.9506 6.24V6.24Z" />
      </SVG>
    );
  }
  if (props.variant === 'word') {
    return (
      <SVG
        {...props}
        ref={ref}
        height={size}
        width={`${parseInt(size, 10) * 4.705}px`}
        viewBox="0 0 80 17"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M80.0001 16.71H77.1801V9.84998C77.2096 9.48094 77.1613 9.1098 77.0381 8.76067C76.9149 8.41154 76.7196 8.09222 76.465 7.82345C76.2104 7.55468 75.9021 7.34247 75.5601 7.20061C75.2181 7.05874 74.8502 6.99039 74.4801 6.99998C72.7501 6.99998 71.4801 8.23998 71.4801 10.42V16.73H68.6801V4.72998H71.3001V6.15998C71.7862 5.60058 72.3892 5.15479 73.0666 4.85412C73.744 4.55346 74.4791 4.40525 75.2201 4.41998C75.8584 4.403 76.4933 4.51738 77.0856 4.75602C77.6779 4.99466 78.2148 5.35248 78.663 5.80726C79.1112 6.26204 79.4612 6.80408 79.6912 7.39974C79.9212 7.99541 80.0263 8.63199 80.0001 9.26998V16.71Z" />
        <path d="M65.8199 16.71H62.9999V9.70998C63.0214 9.37307 62.9736 9.03533 62.8594 8.71762C62.7453 8.39991 62.5672 8.10898 62.3362 7.86279C62.1052 7.6166 61.8262 7.42038 61.5164 7.28624C61.2066 7.1521 60.8725 7.0829 60.5349 7.0829C60.1974 7.0829 59.8633 7.1521 59.5535 7.28624C59.2437 7.42038 58.9647 7.6166 58.7337 7.86279C58.5027 8.10898 58.3246 8.39991 58.2105 8.71762C58.0963 9.03533 58.0485 9.37307 58.0699 9.70998V16.76H55.2799V9.75998C55.3107 9.41964 55.2703 9.07661 55.1611 8.75278C55.052 8.42894 54.8766 8.13139 54.6461 7.8791C54.4156 7.62681 54.135 7.42531 53.8224 7.28745C53.5097 7.14959 53.1717 7.07839 52.83 7.07839C52.4882 7.07839 52.1502 7.14959 51.8375 7.28745C51.5249 7.42531 51.2443 7.62681 51.0138 7.8791C50.7833 8.13139 50.6079 8.42894 50.4988 8.75278C50.3896 9.07661 50.3492 9.41964 50.38 9.75998V16.81H47.58V4.80998H50.11V6.18998C50.4981 5.64418 51.0109 5.19887 51.6057 4.89103C52.2005 4.58319 52.8602 4.4217 53.53 4.41998C54.2434 4.41474 54.9457 4.59667 55.5668 4.9476C56.188 5.29853 56.7063 5.80621 57.0699 6.41998C57.5278 5.7907 58.1302 5.28059 58.8262 4.93256C59.5223 4.58452 60.2918 4.40873 61.0699 4.41998C61.6748 4.39999 62.2775 4.50006 62.8434 4.7144C63.4093 4.92874 63.9272 5.2531 64.367 5.66874C64.8068 6.08437 65.1599 6.58305 65.4058 7.13595C65.6518 7.68885 65.7857 8.28501 65.7999 8.88998L65.8199 16.71Z" />
        <path d="M44.62 4.71002V16.71H42V15.25C41.5067 15.8224 40.8912 16.2768 40.1989 16.5796C39.5066 16.8825 38.7552 17.0262 38 17C37.3594 17.0158 36.7225 16.8995 36.1288 16.6586C35.5351 16.4176 34.9974 16.057 34.549 15.5993C34.1007 15.1415 33.7513 14.5964 33.5227 13.9979C33.294 13.3993 33.191 12.7601 33.22 12.12V4.71002H36.02V11.57C35.9886 11.9411 36.0363 12.3147 36.1601 12.666C36.2839 13.0173 36.4809 13.3383 36.738 13.6077C36.9952 13.8771 37.3066 14.0889 37.6518 14.2289C37.9969 14.369 38.3678 14.4341 38.74 14.42C40.46 14.42 41.74 13.2 41.74 11.02V4.71002H44.62Z" />
        <path d="M30.37 0V16.71H27.57V3.29C27.57 2.38 27.2 1.86 26.52 1.86H26V0H30.37Z" />
        <path d="M18.89 4.41999C20.1311 4.42986 21.3414 4.80664 22.3688 5.50288C23.3961 6.19913 24.1946 7.18373 24.6636 8.33275C25.1326 9.48177 25.2512 10.7439 25.0045 11.9601C24.7578 13.1764 24.1568 14.2926 23.2772 15.168C22.3975 16.0435 21.2786 16.6392 20.0611 16.8801C18.8437 17.1211 17.5822 16.9965 16.4354 16.522C15.2886 16.0476 14.3078 15.2445 13.6164 14.2138C12.9251 13.1832 12.554 11.971 12.55 10.73C12.55 9.89882 12.7142 9.07584 13.0332 8.30831C13.3522 7.54078 13.8197 6.84384 14.4088 6.25751C14.9979 5.67118 15.6971 5.20701 16.4661 4.89168C17.2351 4.57634 18.0589 4.41604 18.89 4.41999V4.41999ZM18.89 6.97999C18.4074 6.98355 17.9303 7.0844 17.4875 7.27653C17.0447 7.46867 16.6452 7.74812 16.3129 8.0982C15.9805 8.44827 15.7222 8.86177 15.5533 9.31397C15.3845 9.76616 15.3085 10.2478 15.33 10.73C15.3098 11.2073 15.3864 11.6838 15.5552 12.1308C15.724 12.5778 15.9816 12.9859 16.3123 13.3307C16.6431 13.6755 17.0402 13.9497 17.4798 14.1369C17.9194 14.3241 18.3923 14.4204 18.87 14.42C19.35 14.4202 19.8252 14.3241 20.2674 14.1375C20.7096 13.9508 21.1099 13.6774 21.4446 13.3334C21.7793 12.9894 22.0416 12.5817 22.216 12.1346C22.3904 11.6874 22.4734 11.2098 22.46 10.73C22.4774 10.2506 22.3988 9.77255 22.2288 9.32396C22.0588 8.87537 21.8009 8.46528 21.4701 8.11779C21.1394 7.7703 20.7426 7.49241 20.3029 7.30045C19.8633 7.1085 19.3897 7.00634 18.91 6.99999L18.89 6.97999Z" />
        <path d="M6.34005 4.41998C7.28007 4.41297 8.20992 4.61475 9.06248 5.01077C9.91504 5.40679 10.669 5.98715 11.27 6.70998L9.18005 8.43998C8.85625 7.99521 8.43216 7.63304 7.9422 7.38284C7.45224 7.13265 6.91019 7.00148 6.36005 6.99998C5.87912 7.00363 5.40382 7.10388 4.96239 7.29477C4.52095 7.48567 4.12237 7.76331 3.79031 8.11123C3.45825 8.45915 3.19948 8.87024 3.02937 9.32009C2.85926 9.76995 2.78128 10.2494 2.80005 10.73C2.7798 11.2073 2.8564 11.6838 3.02521 12.1308C3.19403 12.5777 3.45157 12.9859 3.78233 13.3307C4.11309 13.6754 4.51021 13.9497 4.94978 14.1369C5.38935 14.3241 5.86227 14.4204 6.34005 14.42C6.93819 14.4299 7.52874 14.2851 8.05439 13.9995C8.58004 13.7139 9.02292 13.2972 9.34005 12.79L11.54 14.37C10.9433 15.1886 10.1607 15.8539 9.25674 16.3111C8.35273 16.7684 7.3531 17.0044 6.34005 17C4.67184 17 3.07195 16.3373 1.89235 15.1577C0.712743 13.9781 0.0500488 12.3782 0.0500488 10.71C0.0500488 9.04176 0.712743 7.44188 1.89235 6.26227C3.07195 5.08267 4.67184 4.41998 6.34005 4.41998V4.41998Z" />
      </SVG>
    );
  }
  return (
    <SVG
      {...props}
      ref={ref}
      height={size}
      width={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.5 9.5V14.5H12C13.3261 14.5 14.5979 15.0268 15.5355 15.9645C16.4732 16.9021 17 18.1739 17 19.5V22H7C5.67392 22 4.40215 21.4732 3.46447 20.5355C2.52678 19.5979 2 18.3261 2 17V7C2 5.67392 2.52678 4.40215 3.46447 3.46447C4.40215 2.52678 5.67392 2 7 2L17 2C18.3261 2 19.5979 2.52678 20.5355 3.46447C21.4732 4.40215 22 5.67392 22 7V9.5H9.5Z" />
    </SVG>
  );
});
