import React, { ReactNode } from 'react';
import { Icon, Chip, ChipStyleProps } from '@column/column-ui-kit';
import styled, { css } from 'styled-components';
import { EventReport, EventBookTransfer } from '~/elements';
import { formatString } from '~/util';

const EventTypeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${({ theme }) => theme.secondary.blendToBackground(600)};
`;

const EventStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
`;

const EventType = styled.h4`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.foreground};
  margin: 0;
`;

const EventStatus = styled.p<{ variant?: 'default' | 'success' | 'danger' | 'warning' }>`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.secondary.blendToBackground(600)};
  display: flex;
  align-items: center;
  margin: 0;

  ${({ variant }) =>
    variant === 'success' &&
    css`
      color: ${({ theme }) => theme.success.background};
    `}

  ${({ variant }) =>
    variant === 'danger' &&
    css`
      color: ${({ theme }) => theme.danger.background};
    `}

  ${({ variant }) =>
    variant === 'warning' &&
    css`
      color: ${({ theme }) => theme.warning.background};
    `}
`;

const EventTypeMap = new Map<string, string>([
  ['account', 'Account'],
  ['ach', 'ACH Transfer'],
  ['book', 'Book Transfer'],
  ['check', 'Check'],
  ['disbursement', 'Disbursement'],
  ['identity', 'Identity'],
  ['payment', 'Payment'],
  ['reporting', 'Reporting'],
  ['swift', 'International Wire Transfer'],
  ['verification', 'Verification'],
  ['wire', 'Wire Transfer'],
  ['realtime', 'Realtime Payment'],
]);

const EventIconMap = new Map<string, ReactNode>([
  ['account', <Icon.Wallet key="account" />],
  ['check', <Icon.Checks key="check" />],
  ['identity', <Icon.User key="identity" />],
  ['incoming_transfer', <Icon.CircleArrowDown key="incoming" />],
  ['loan', <Icon.HandCoins key="loan" />],
  ['outgoing_transfer', <Icon.CircleArrowUp key="outgoing" />],
  ['reporting', <EventReport key="reporting" />],
  ['transfer', <EventBookTransfer key="book" />],
]);

const EventTransferDirectionMap = new Map<string, string>([
  ['bank_account_summary', 'Bank Account Summary'],
  ['bank_account_transaction', 'Bank Account Transaction'],
  ['incoming_drawdown_request', 'Incoming Drawdown request'],
  ['incoming_transfer', 'Incoming'],
  ['incoming_debit', 'Incoming Debit'],
  ['outgoing_debit', 'Outgoing Debit'],
  ['outgoing_transfer', 'Outgoing'],
]);

const EventStatusMap = new Map<string, string>([
  ['noc', 'Notification of Change'],
  ['nsf', 'Insufficient Funds'],
]);

const EventStatusTypeMap = new Map<string, ChipStyleProps['type']>([
  // Success
  ['completed', 'success'],
  ['created', 'success'],
  ['paid_off', 'success'],
  ['settled', 'success'],
  ['verified', 'success'],

  // Danger
  ['cancelled', 'danger'],
  ['denied ', 'danger'],
  ['delinquent', 'danger'],
  ['failed', 'danger'],
  ['overdrafted', 'danger'],
  ['rejected', 'danger'],
  ['rejected', 'danger'],
  ['return_contested', 'danger'],
  ['returned', 'danger'],
  ['returned_to_sender', 'danger'],
]);

const EventStatusIconMap = new Map<string, ReactNode>([
  ['cancelled', <Icon.Cross key="cancelled" />],
  ['completed', <Icon.Checkmark key="completed" />],
  ['created', <Icon.Checkmark key="created" />],
  ['denied ', <Icon.Cross key="denied" />],
  ['overdrafted', <Icon.Cross key="overdrafted" />],
  ['rejected', <Icon.Cross key="rejected" />],
  ['return', <Icon.Cross key="return" />],
  ['settled', <Icon.Checkmark key="settled" />],
  ['verified', <Icon.Checkmark key="verified" />],
]);

export const getEventType = (value: string) => {
  const eventStringRaw = value;

  // Parse event string into array
  const eventString = value.split('.');

  const eventType = EventTypeMap.get(eventString[0]);

  const eventIcon = ['incoming_transfer', 'outgoing_transfer', 'transfer'].includes(eventString[1])
    ? EventIconMap.get(eventString[1])
    : (EventIconMap.get(eventString[0]) ?? <Icon.CalendarLayer />);

  const eventTransferDirection =
    eventString[0] === 'check' && eventString[1] === 'incoming_debit'
      ? 'Incoming'
      : eventString[0] === 'check' && eventString[1] === 'outgoing_debit'
        ? 'Outgoing'
        : eventString[0] === 'book' ||
            (eventString[0] === 'identity' && eventString[1] === 'verification') ||
            eventString[0] === 'account' ||
            (eventString[0] === 'identity' && !eventString[2])
          ? null
          : (EventTransferDirectionMap.get(eventString[1]) ?? formatString(eventString[1]));

  return (
    <EventTypeWrapper>
      {eventIcon}
      <EventStatusWrapper>
        <EventType>
          {eventTransferDirection} {eventType}
        </EventType>
        <EventStatus>{eventStringRaw}</EventStatus>
      </EventStatusWrapper>
    </EventTypeWrapper>
  );
};

export const getEventStatus = (value: string) => {
  const eventString = value.split('.');

  const eventStatus =
    // If it's a delivered check use the 4th segment of the event string
    eventString[0] === 'check' && eventString[2] === 'delivery'
      ? (EventStatusMap.get(eventString[3]) ?? formatString(eventString[3]))
      : // If it's a loan, identity, or account event with no 3rd segment use the 2nd segment of the event string
        (eventString[0] === 'loan' && !eventString[2]) ||
          (eventString[0] === 'identity' && !eventString[2]) ||
          (eventString[0] === 'account' && !eventString[2])
        ? (EventStatusMap.get(eventString[1]) ?? formatString(eventString[1]))
        : (EventStatusMap.get(eventString[2]) ?? formatString(eventString[2]));

  const statusType = EventStatusTypeMap.get(eventString[2]) ?? 'default';

  const statusIcon = EventStatusIconMap.get(eventString[2]);

  return (
    <Chip type={statusType} icon={statusIcon}>
      {eventStatus}
    </Chip>
  );
};

export * from './EventRepository';
