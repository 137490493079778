import React, { FC } from 'react';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import Router from './Router';
import { LDClientIdentifier } from './LDClientIdentifier';
import { AuthProvider } from './AuthProvider';
import { LAUNCH_DARKLY_CLIENT_ID } from '~/constants';

export const App: FC = () => {
  return (
    <LDProvider clientSideID={LAUNCH_DARKLY_CLIENT_ID}>
      <LDClientIdentifier>
        <AuthProvider>
          <Router />
        </AuthProvider>
      </LDClientIdentifier>
    </LDProvider>
  );
};
