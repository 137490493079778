import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Icon, Button, Chip, Fade } from '@column/column-ui-kit';
import {
  EditPage,
  EditToolbar,
  EditTitle,
  Headline,
  EditSection,
  EditSectionTitle,
  EditBox,
  FormElement,
  FormLabel,
  FormText,
} from '~/styles';
import { Breadcrumb, NotificationList } from '~/components';
import { ROUTE } from '~/app/routes';
import { useNotificationStore } from '~/stores/Notification';
import { DocumentRepository, ReportingRepository, ReportingSettlementReport } from '~/repositories';
import { downloadFile, formatString } from '~/util';
import { LogoLoading } from '~/elements';

interface Params {
  id: string;
}

enum DocumentType {
  Statement = 'statement',
  Report = 'report',
}

const getDocumentType = (rawType: string): DocumentType => {
  if (rawType === 'bank_account_daily_statement') {
    return DocumentType.Statement;
  }
  if (rawType === 'bank_account_monthly_statement') {
    return DocumentType.Statement;
  }

  return DocumentType.Report;
};

const Headers = {
  [DocumentType.Report]: {
    headline: 'View report',
    breadcrumb: {
      label: (
        <>
          <Icon.DocumentEdit />
          Reports
        </>
      ),
      path: ROUTE.REPORTING,
    },
  },
  [DocumentType.Statement]: {
    headline: 'View statement',
    breadcrumb: {
      label: (
        <>
          <Icon.DocumentEdit />
          Statements
        </>
      ),
      path: ROUTE.STATEMENTS,
    },
  },
};

const ButtonList = styled.div`
  display: flex;
  gap: 12px;
`;

const Arrow = styled(Icon.ArrowRight)`
  display: inline-block;
  vertical-align: top;
  margin: 4px;

  --icon-size: 16px;
  --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

const Container = styled.div`
  position: relative;
`;

const StyledLoading = styled(LogoLoading)`
  top: 80px;
`;

interface DownloadButtonProps {
  label: string;
  id?: string;
}

export const DownloadButton: React.FC<DownloadButtonProps> = (props) => {
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);

  const [running, setRunning] = useState<boolean>(false);

  const handleDownload = (docId?: string) => {
    if (!docId) {
      return;
    }

    setRunning(true);

    DocumentRepository.get(docId)
      .then((response) => {
        downloadFile(response.url, 'Report');
      })
      .catch((error: any) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  return (
    <Button
      onClick={() => handleDownload(props.id)}
      isDisabled={!props.id}
      icon={<Icon.AnimationDownload running={running} onDone={() => setRunning(false)} />}
    >
      {props.label}
    </Button>
  );
};

export const PageDocumentView: React.FC = () => {
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);

  const navigate = useNavigate();
  const { id } = useParams<keyof Params>() as Params;
  const [report, setReport] = useState<ReportingSettlementReport>();

  const documentType = report?.type ? getDocumentType(report.type) : undefined;

  useEffect(() => {
    ReportingRepository.get(id)
      .then((response: any) => {
        setReport(response);
      })
      .catch((error: any) => {
        navigate(ROUTE.REPORTING);
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  }, [id]);

  return (
    <Container>
      <Fade show={!documentType} base={StyledLoading} />
      <Fade show={!!documentType}>
        {documentType && (
          <EditPage>
            <EditToolbar>
              <EditTitle>
                <Headline>{Headers[documentType].headline}</Headline>
                <Breadcrumb
                  entries={[
                    {
                      label: Headers[documentType].breadcrumb.label,
                      path: Headers[documentType].breadcrumb.path,
                    },
                    {
                      label: 'View',
                    },
                  ]}
                />
              </EditTitle>
            </EditToolbar>
            <div>
              <NotificationList display="page" />
              <EditPage>
                <EditSection>
                  <EditSectionTitle>
                    <Icon.ArrowDown />
                    Download
                  </EditSectionTitle>
                  <EditBox>
                    <ButtonList>
                      {report?.pdfDocumentId && <DownloadButton label=".pdf File" id={report?.pdfDocumentId} />}
                      {report?.bai2DocumentId && <DownloadButton label=".bai2 File" id={report?.bai2DocumentId} />}
                      {report?.csvDocumentId && <DownloadButton label=".csv File" id={report?.csvDocumentId} />}
                      {report?.jsonDocumentId && <DownloadButton label=".json File" id={report?.jsonDocumentId} />}
                      {report?.parquetDocumentId && (
                        <DownloadButton label=".parquet File" id={report?.parquetDocumentId} />
                      )}
                    </ButtonList>
                  </EditBox>
                </EditSection>
                <EditSection>
                  <EditSectionTitle>
                    <Icon.CircleInfo />
                    Information
                  </EditSectionTitle>
                  <EditBox>
                    <FormElement>
                      <FormLabel>Type</FormLabel>
                      <FormText>
                        <Chip>{report?.type ? formatString(report.type) : '-'}</Chip>
                      </FormText>
                    </FormElement>
                    <FormElement>
                      <FormLabel>Status</FormLabel>
                      <FormText>
                        {report?.status ? (
                          <Chip
                            type={
                              report?.status === 'completed'
                                ? 'success'
                                : report?.status === 'failed'
                                  ? 'danger'
                                  : 'default'
                            }
                          >
                            {formatString(report.status)}
                          </Chip>
                        ) : (
                          '-'
                        )}
                      </FormText>
                    </FormElement>
                    <FormElement>
                      <FormLabel>Date</FormLabel>
                      <FormText>
                        {report?.fromDate} <Arrow /> {report?.toDate}
                      </FormText>
                    </FormElement>
                    {
                      // Monthly statements include a subject vs being at the platform level
                      report?.statementSubject && (
                        <FormElement>
                          <FormLabel>Statement subject</FormLabel>
                          <FormText>{report?.statementSubject}</FormText>
                        </FormElement>
                      )
                    }
                    <FormElement newRow>
                      <FormLabel>Initiated</FormLabel>
                      <FormText>
                        {report?.initiatedAt ? (
                          <>
                            {(report.initiatedAt as any).toLocaleString('en-US', { weekday: 'long' })},{' '}
                            {(report.initiatedAt as any).toLocaleString('en-US', { month: 'long' })}{' '}
                            {(report.initiatedAt as any).getDate()}, {(report.initiatedAt as any).getFullYear()} —{' '}
                            {(report.initiatedAt as any).toLocaleString('en-US', {
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: true,
                            })}
                          </>
                        ) : (
                          '-'
                        )}
                      </FormText>
                    </FormElement>
                    <FormElement>
                      <FormLabel>Completed</FormLabel>
                      <FormText>
                        {report?.completedAt ? (
                          <>
                            {(report.completedAt as any).toLocaleString('en-US', { weekday: 'long' })},{' '}
                            {(report.completedAt as any).toLocaleString('en-US', { month: 'long' })}{' '}
                            {(report.completedAt as any).getDate()}, {(report.completedAt as any).getFullYear()} —{' '}
                            {(report.completedAt as any).toLocaleString('en-US', {
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: true,
                            })}
                          </>
                        ) : (
                          '-'
                        )}
                      </FormText>
                    </FormElement>
                  </EditBox>
                </EditSection>
              </EditPage>
            </div>
            <EditToolbar>
              <Button onClick={() => navigate(-1)} variant="secondary">
                Back
              </Button>
            </EditToolbar>
          </EditPage>
        )}
      </Fade>
    </Container>
  );
};
