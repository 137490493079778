import { gsap } from 'gsap';
import React, { useRef, useEffect, useLayoutEffect, PropsWithChildren, FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { Button, Icon } from '@column/column-ui-kit';
import { Sidebar } from './Sidebar';
import { PlatformStatus, User } from './Toolbar';
import { helpSidebars, modals } from '~/components';
import { useScrollPosition } from '~/hooks';
import { useHelpSidebarStore } from '~/stores/HelpSidebar';
import { useSessionStore } from '~/stores/Session';
import { log } from '~/util';

interface LocationState {
  from?: string;
}

const Wrapper = styled.div<{ $showSandboxBanner?: boolean }>`
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
  flex-grow: 1;
`;

const Content = styled.div`
  --content-o: 0;
  opacity: var(--content-o);
  min-height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const Toolbar = styled.div<{ isScrolled: boolean }>`
  position: sticky;
  top: 0;
  z-index: 11;
  display: flex;
  align-items: center;
  padding-left: 20px;
  background: ${({ theme }) => theme.body};
  border-bottom: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};
`;

const Panel = styled.div`
  padding: 8px 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex: auto;
`;

const Bottom = styled.div`
  --sidebar-width: 248px;
  display: grid;
  grid-template-columns: auto;
  padding-left: var(--sidebar-width);
`;

const Main = styled.div`
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 72px;
`;

const Inner = styled.div`
  max-width: 1340px;
  margin: 0 auto;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Help = styled(Button)`
  --button-border-radius: 50%;

  position: fixed;
  left: 12px;
  bottom: 12px;
  z-index: 10;
  padding: 10px;

  svg {
    --icon-size: 20px;
  }
`;

const Link = styled.a`
  cursor: pointer;
  color: ${({ theme }) => theme.secondary.background};
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  transition: color 0.2s;

  &:hover {
    color: ${({ theme }) => theme.primary.background};
  }
`;

export const Layout: FC<PropsWithChildren> = ({ children }) => {
  const { currentUser } = useSessionStore();

  const { openHelpSidebar, openHelpSidebars } = useHelpSidebarStore();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const { scrollY } = useScrollPosition(wrapperRef);
  const showSandboxBanner = useSessionStore((s) => s.showSandboxBanner);

  const location = useLocation();
  const navigate = useNavigate();

  const state = location.state as LocationState;

  useEffect(() => {
    if (state && state.from === 'register') {
      openHelpSidebar('Onboarding');
      navigate(location.pathname);
    }
    if (state && state.from === 'auth') {
      gsap.to(sidebarRef.current, {
        '--sidebar-o': 1,
        duration: 0.4,
        ease: 'none',
        onComplete: () => {
          navigate(location.pathname);
        },
      });
      gsap.to(contentRef.current, {
        '--content-o': 1,
        duration: 0.4,
        ease: 'none',
      });
    } else {
      gsap.set(sidebarRef.current, {
        '--sidebar-o': 1,
      });
      gsap.set(contentRef.current, {
        '--content-o': 1,
      });
    }
  }, [location]);

  useLayoutEffect(() => {
    const wrapper = wrapperRef.current;

    if (!wrapper) {
      return;
    }

    wrapper.scrollTo({ top: 0 });
  }, [wrapperRef, location]);

  const handleDocsClick = () => {
    log({
      name: 'Documentation clicked',
      context: currentUser,
    });

    window.open('https://column.com/docs');
  };

  return (
    <Wrapper ref={wrapperRef} id="scroll-container" $showSandboxBanner={showSandboxBanner}>
      <Bottom>
        <Sidebar ref={sidebarRef} />
        <Content ref={contentRef}>
          <Toolbar ref={toolbarRef} isScrolled={scrollY > 0 && openHelpSidebars.length < 1}>
            <Panel>
              <Link onClick={handleDocsClick}>Documentation</Link>
              <PlatformStatus />
              <User />
            </Panel>
          </Toolbar>
          <Main>
            <Inner>{children}</Inner>
          </Main>
        </Content>
      </Bottom>

      {modals}
      {helpSidebars}
      <Help variant="secondary" onClick={() => openHelpSidebar('Support')}>
        <Icon.QuestionMark />
      </Help>
    </Wrapper>
  );
};
