import chroma from 'chroma-js';
import styled, { css } from 'styled-components';

export const Box = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.background};
  box-shadow:
    0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(1100, 50)},
    0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      background-color: ${theme.secondary.blendToBackground(5)};
      box-shadow: 0 0 0 1px ${theme.secondary.blendToBackground(150)};
    `}
`;

export const EditPage = styled.div<{ fullWidth?: boolean; smallGap?: boolean }>`
  display: grid;
  grid-gap: 24px;
  width: 100%;

  ${({ fullWidth }) =>
    !fullWidth &&
    css`
      max-width: 1080px;
      margin: 0 auto;
    `}

  ${({ smallGap }) =>
    smallGap &&
    css`
      grid-gap: 16px;
    `}
`;

export const EditTitle = styled.div`
  display: grid;
  grid-gap: 12px;
  padding-right: 32px;
`;

export const EditSection = styled.div`
  display: grid;
  grid-gap: 12px;
`;

export const EditSectionTitle = styled.div<{ size?: 'default' | 'small'; twoColumns?: boolean }>`
  display: flex;
  gap: 8px;
  align-items: center;
  color: ${({ theme }) => theme.foreground};
  line-height: 20px;
  font-size: 16px;
  font-weight: 500;

  svg {
    --icon-color: ${({ theme }) => theme.secondary.background};
  }

  ${({ size }) =>
    size === 'small' &&
    css`
      gap: 4px;
      color: ${({ theme }) => theme.secondary.background};
      line-height: 18px;
      font-size: 14px;

      svg {
        --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
        --icon-size: 18px;
      }
    `}

  ${({ twoColumns }) =>
    twoColumns &&
    css`
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 0;

      div {
        display: flex;
        gap: 8px;
        align-items: center;

        &:not(:first-child) {
          margin-left: 32px;
        }
      }
    `};
`;

export const EditSectionInfo = styled.div`
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;

  small {
    font-size: 14px;
    display: inline-block;
    margin-right: 4px;
    color: ${({ theme }) => theme.secondary.blendToBackground(600)};
  }
`;

export const EditBox = styled(Box)<{
  smallSpacing?: boolean;
  oneColumn?: boolean;
  pale?: boolean;
  bottom?: boolean;
}>`
  padding: 20px;
  display: grid;
  grid-gap: 24px;
  align-items: flex-start;

  &[data-disabled='true'] {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: inherit;
      cursor: not-allowed;
      z-index: 11;
      transition: background-color 0.2s;
    }

    &:hover {
      &:before {
        background-color: ${({ theme }) => chroma(theme.background).alpha(0.4).hex()};
      }
    }
  }

  ${({ oneColumn }) =>
    !oneColumn &&
    css`
      grid-template-columns: repeat(2, minmax(0, 1fr));
    `}

  ${({ pale }) =>
    pale &&
    css<{ bottom?: boolean }>`
      grid-column: 1 / 3;
      margin: 4px -20px;
      border-radius: 0;
      background-color: ${({ theme }) => theme.secondary.blendToBackground(25)};
      box-shadow:
        0 1px 0 0 ${({ theme }) => theme.secondary.blendToBackground(1200, 70)},
        0 -1px 0 0 ${({ theme }) => theme.secondary.blendToBackground(1200, 70)};

      ${({ theme }) =>
        theme.id !== 'default' &&
        css`
          background-color: ${theme.secondary.blendToBackground(0)};
          box-shadow:
            0 1px 0 0 ${theme.secondary.blendToBackground(150)},
            0 -1px 0 0 ${theme.secondary.blendToBackground(150)};
          asdf: asdf;
        `}

      ${({ bottom }) =>
        bottom &&
        css`
          margin-bottom: -20px;
          border-radius: 0 0 12px 12px;
        `}
    `}

  ${({ smallSpacing }) =>
    smallSpacing &&
    css`
      padding: 20px;
      grid-gap: 24px;
    `}
`;

export const EditToolbar = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Line = styled.hr`
  height: 1px;
  width: 100%;
  margin: 0;
  border: none;
  background-color: ${({ theme }) => theme.secondary.blendToBackground(150)};
`;
