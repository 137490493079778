import React, { FC, PropsWithChildren, Suspense, useLayoutEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { useSessionStore } from '~/stores/Session';
import { ROUTE } from '~/public/routes';
import { LogoLoading } from '~/elements';

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const loadingRef = useRef<HTMLDivElement>(null);
  const { isLoading, signIn, signOut, authRequired, isSignedIn } = useSessionStore();

  useLayoutEffect(() => {
    if (isSignedIn() && !authRequired()) {
      signIn().catch(signOut);
    } else {
      signOut();
    }
  }, []);

  return (
    <Suspense fallback={<LogoLoading ref={loadingRef} />}>
      {isLoading ? <LogoLoading ref={loadingRef} /> : isSignedIn() ? children : <Navigate to={ROUTE.LOGIN} />}
    </Suspense>
  );
};
