import React, { forwardRef } from 'react';
import styled from 'styled-components';

interface EventAccountProps {
  size?: string;
}

const SVG = styled.svg`
  overflow: visible;
`;

export const EventAccount = forwardRef<SVGSVGElement, EventAccountProps>((props, ref) => {
  const size = props.size ?? '20px';

  return (
    <SVG
      {...props}
      ref={ref}
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5325 6.59594H15.8333C16.7542 6.59594 17.5 7.34177 17.5 8.2626V15.8334C17.5 16.7501 16.75 17.5001 15.8333 17.5001H4.16667C3.24583 17.5001 2.5 16.7543 2.5 15.8334V6.90927C2.5 6.21594 2.92917 5.5951 3.57833 5.3501L12.2058 2.0926C12.7508 1.88677 13.3333 2.28927 13.3333 2.87177V6.5951"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3326 11.7712C13.1601 11.772 13.0209 11.912 13.0209 12.0845C13.0209 12.257 13.1609 12.397 13.3334 12.3962C13.5059 12.3962 13.6459 12.2562 13.6459 12.0837C13.6459 11.9112 13.5059 11.7712 13.3326 11.7712"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
});
