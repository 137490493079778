import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { Icon, Button, Input, Dropdown, AmountInput, Toggle, Tooltip } from '@column/column-ui-kit';
import { Balance, FormTextAmount, FormLabelAmount } from '../BankAccounts/Edit';
import { handleFetchPayments, paymentColumns, paymentFilter } from './Payment/Overview';
import { handleFetchDisbursements, disbursementColumns, disbursementFilter } from './Disbursement/Overview';
import {
  EditPage,
  EditToolbar,
  EditTitle,
  EditSection,
  EditSectionTitle,
  EditBox,
  Headline,
  FormElement,
  FormLabel,
  FormText,
} from '~/styles';
import { Breadcrumb, NotificationList, DateInput, CopyInput, PaginationWrapper, RestrictedBanner } from '~/components';
import { ROUTE } from '~/app/routes';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { EntityRepository, Loan, LoanRepository } from '~/repositories';
import { currenciesUSD, formatNumber } from '~/util';
import { UpdateLoanRequest } from '~/typings/API';

interface Params {
  id: string;
}

const ToggleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  min-height: 40px;

  svg {
    --icon-size: 18px;
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  }
`;

export const PageLoansEdit: React.FC = () => {
  const { currentUser, currentPermission } = useSessionStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const navigate = useNavigate();
  const { id } = useParams<keyof Params>() as Params;
  const [loan, setLoan] = useState<Partial<Loan>>({});
  const [entityName, setEntityName] = useState<string>('');
  const [entityId, setEntityId] = useState<string>('');

  const handleUpdate = () => {
    loan.maxPrincipalBalance = loan.maxPrincipalBalance?.toString();

    const updateLoan = loan;

    for (const property in updateLoan) {
      if (updateLoan.hasOwnProperty(property)) {
        if (!['description', 'isRevolving', 'maturityDate', 'maxPrincipalBalance', 'status'].includes(property)) {
          delete updateLoan[property as keyof Loan];
        }
      }
    }

    LoanRepository.update(id, updateLoan as UpdateLoanRequest)
      .then((response) => {
        addSuccessNotification({
          content: `${response.description} updated`,
          display: 'page',
        });
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  const fetchData = () => {
    LoanRepository.get(id)
      .then((response) => {
        setLoan(response);

        if (!response?.primarySignerEntityId) {
          return;
        }

        EntityRepository.get(response.primarySignerEntityId)
          .then((entityResponse) => {
            if (!entityResponse.id) {
              return;
            }

            setEntityId(entityResponse.id);
            if (entityResponse?.personDetails) {
              setEntityName(`${entityResponse.personDetails.firstName} ${entityResponse.personDetails.lastName}`);
            }
            if (entityResponse?.businessDetails) {
              if (!entityResponse.businessDetails.businessName) {
                return;
              }

              setEntityName(entityResponse.businessDetails.businessName);
            }
          })
          .catch((error) => {
            addDangerNotification({
              content: error.message,
              display: 'page',
            });
          });
      })
      .catch((error) => {
        navigate(ROUTE.LOANS);
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    if (id) {
      fetchData();
    }
  }, [id]);

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => ({
          isSandbox: state.isSandbox,
          isLoading: state.isLoading,
          defaultPlatformId: state.currentUser?.defaultPlatformId,
        }),
        () => {
          if (!currentUser) {
            return;
          }
          if (id) {
            fetchData();
          }
        }
      ),
    []
  );

  return (
    <EditPage>
      <EditToolbar>
        <EditTitle>
          <Headline>Edit loan</Headline>
          <Breadcrumb
            entries={[
              {
                label: (
                  <>
                    <Icon.HandCoins />
                    Loans
                  </>
                ),
                path: ROUTE.LOANS,
              },
              {
                label: loan.description,
              },
            ]}
          />
        </EditTitle>
      </EditToolbar>

      {currentPermission?.loans !== 'write' && <RestrictedBanner />}

      <div>
        <NotificationList display="page" />

        <EditSection>
          <EditSectionTitle>
            <Icon.CircleInfo />
            Information
          </EditSectionTitle>
          <EditBox data-disabled={currentPermission?.loans !== 'write'}>
            <Balance>
              <FormElement>
                <FormTextAmount>{formatNumber(loan.balances?.principalOutstanding)}</FormTextAmount>
                <FormLabelAmount>Outstanding</FormLabelAmount>
              </FormElement>
              <FormElement>
                <FormTextAmount>{formatNumber(loan.balances?.principalPaid)}</FormTextAmount>
                <FormLabelAmount>Paid</FormLabelAmount>
              </FormElement>
              <FormElement>
                <FormTextAmount>{formatNumber(loan.balances?.principalChargedOff)}</FormTextAmount>
                <FormLabelAmount>Charged off</FormLabelAmount>
              </FormElement>
              <FormElement>
                <FormTextAmount>{formatNumber(loan.balances?.principalHolding)}</FormTextAmount>
                <FormLabelAmount>Holding</FormLabelAmount>
              </FormElement>
            </Balance>

            <FormElement newRow>
              <FormLabel>Description</FormLabel>
              <Input
                onChange={(value: string) => setLoan({ ...loan, description: value })}
                value={loan?.description ?? ''}
                placeholder="Description"
              />
            </FormElement>
            <FormElement>
              <FormLabel>Is Revolving?</FormLabel>
              <ToggleWrapper>
                <Toggle
                  isChecked={loan.isRevolving}
                  onCheckedChange={(value: boolean) => setLoan({ ...loan, isRevolving: value })}
                />
                <Tooltip content="Indicates whether or not the loan is revolving. If checked the loan can have multiple disbursements.">
                  <Icon.CircleQuestionmark />
                </Tooltip>
              </ToggleWrapper>
            </FormElement>
            <EditBox pale>
              <FormElement>
                <FormLabel>Entity</FormLabel>
                <FormText>{entityName}</FormText>
              </FormElement>
              <FormElement>
                <FormLabel>Entity ID</FormLabel>
                <CopyInput value={entityId ?? ''} placeholder="" />
              </FormElement>
            </EditBox>
            <FormElement>
              <FormLabel>Maturity Date</FormLabel>
              <DateInput
                onChange={(value?: string) => setLoan({ ...loan, maturityDate: value })}
                value={loan?.maturityDate}
              />
            </FormElement>
            <FormElement>
              <FormLabel>Max Principal Balance</FormLabel>
              <AmountInput
                onChange={(value: number) => setLoan({ ...loan, maxPrincipalBalance: value?.toString() })}
                onCurrencyCodeChange={(value: string) => setLoan({ ...loan, currency: value })}
                currencyCode={loan.currency}
                currencyList={currenciesUSD}
                value={loan.maxPrincipalBalance ? parseInt(loan.maxPrincipalBalance, 10) : undefined}
              />
            </FormElement>
            <FormElement>
              <FormLabel>Status</FormLabel>
              <Dropdown
                options={[
                  {
                    label: 'Current',
                    value: 'current',
                  },
                  {
                    label: 'Delinquent',
                    value: 'delinquent',
                  },
                  {
                    label: 'Charged off',
                    value: 'charged_off',
                  },
                  {
                    label: 'In Dispute',
                    value: 'in_dispute',
                  },
                  {
                    label: 'Canceled',
                    value: 'canceled',
                  },
                  {
                    label: 'Paid off',
                    value: 'paid_off',
                  },
                ]}
                variant="muted"
                fullWidth
                active={loan.status}
                onChange={(value: string) => setLoan({ ...loan, status: value })}
              />
            </FormElement>
            {[
              { label: 'Created at', key: 'createdAt' },
              { label: 'Charged off at', key: 'charged_off_at' },
              { label: 'Paid off at', key: 'paidOffAt' },
              { label: 'Delinquent at', key: 'delinquentAt' },
              { label: 'Disputed at', key: 'disputedAt' },
            ].map((o) => {
              const value = loan[o.key as keyof Loan];

              if (!value) {
                return;
              }

              return (
                <FormElement key={o.key}>
                  <FormLabel>{o.label}</FormLabel>
                  <FormText>
                    {value && (
                      <>
                        {(value as any).toLocaleString('en-US', { weekday: 'long' })},{' '}
                        {(value as any).toLocaleString('en-US', { month: 'long' })} {(value as any).getDate()},{' '}
                        {(value as any).getFullYear()} —{' '}
                        {(value as any).toLocaleString('en-US', {
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: true,
                        })}
                      </>
                    )}
                  </FormText>
                </FormElement>
              );
            })}
          </EditBox>
        </EditSection>
      </div>
      <EditToolbar>
        <Button onClick={() => navigate(-1)} variant="secondary">
          Back
        </Button>
        <Button onClick={handleUpdate} isDisabled={currentPermission?.loans !== 'write'}>
          Update
        </Button>
      </EditToolbar>
      <EditSection>
        <EditSectionTitle>
          <Icon.MoneyIn />
          Payments
        </EditSectionTitle>
        <PaginationWrapper
          tableId="loanPayments"
          fetch={(o) => handleFetchPayments(o, navigate)}
          columns={paymentColumns}
          filter={paymentFilter}
          action={
            <Button onClick={() => navigate(`${ROUTE.LOANS}/edit/${id}/payment`)} icon={<Icon.Plus />} size="small">
              Create
            </Button>
          }
          rowClick={(row) => {
            navigate(`${ROUTE.LOANS}/payments/edit/${row.original.id}`);
          }}
        />
      </EditSection>
      <EditSection>
        <EditSectionTitle>
          <Icon.MoneyOut />
          Disbursements
        </EditSectionTitle>
        <PaginationWrapper
          tableId="loanDisbursements"
          fetch={(o) => handleFetchDisbursements(o, navigate)}
          columns={disbursementColumns}
          filter={disbursementFilter}
          action={
            <Button
              onClick={() => navigate(`${ROUTE.LOANS}/edit/${id}/disbursement`)}
              icon={<Icon.Plus />}
              size="small"
            >
              Create
            </Button>
          }
          rowClick={(row) => {
            navigate(`${ROUTE.LOANS}/disbursements/edit/${row.original.id}`);
          }}
        />
      </EditSection>
    </EditPage>
  );
};
