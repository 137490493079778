import React, { PropsWithChildren } from 'react';
import styled, { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { themeDefault } from '@column/column-ui-kit';
import { themeDark } from './themeDark';
import { themeWarm } from './themeWarm';
import { useSessionStore } from '~/stores/Session';

interface ThemeProviderProps {}

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.foreground};
  height: 100%;
`;

export const ThemeProvider: React.FC<PropsWithChildren<ThemeProviderProps>> = (props) => {
  const themeType = useSessionStore((state) => state.settings.themeType);

  const themes = new Map([
    ['default', themeDefault],
    ['dark', themeDark],
    ['warm', themeWarm],
    [undefined, themeDefault],
  ]);

  return (
    <StyledThemeProvider theme={themes.get(themeType) ?? themeDefault}>
      <Wrapper>{props.children}</Wrapper>
    </StyledThemeProvider>
  );
};
