import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Icon, Input, Button, Textarea, Chip, SegmentedControl } from '@column/column-ui-kit';
import {
  EditBox,
  EditSection,
  EditSectionTitle,
  EditPage,
  EditToolbar,
  EditTitle,
  FormElement,
  FormLabel,
  FormText,
  Headline,
} from '~/styles';
import { Breadcrumb, CopyInput, RestrictedBanner } from '~/components';
import { ROUTE } from '~/app/routes';
import { ApiKeyRepository } from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';

interface Params {
  id: string;
  URLType: string;
}

export const PageApiKeysEdit: React.FC = () => {
  const { currentUser, currentPlatform, currentPermission } = useSessionStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const navigate = useNavigate();
  const { id, URLType } = useParams<keyof Params>() as Params;
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [type, setType] = useState<string>('sandbox');
  const [apiKey, setApiKey] = useState<string>('');

  const handleSave = () => {
    if (!id || !currentUser) {
      return;
    }
    ApiKeyRepository.update(currentUser.defaultPlatformId, id, name, description, URLType === 'Sandbox')
      .then(() => {
        addSuccessNotification({
          content: 'API key updated',
          display: 'page',
        });
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  const handleSubmit = () => {
    if (!currentUser) {
      return;
    }
    if (id) {
      handleSave();
      return;
    }
    ApiKeyRepository.create(currentUser.defaultPlatformId, name, description, type === 'sandbox')
      .then((response) => {
        navigate(`${ROUTE.PLATFORM_API_KEYS}/edit/${type === 'sandbox' ? 'Sandbox' : 'Production'}/${response.id}`);
        addSuccessNotification({
          content: 'API key created',
          display: 'page',
        });
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  const fetchData = () => {
    if (!currentUser) {
      return;
    }

    ApiKeyRepository.get(currentUser.defaultPlatformId, id, URLType === 'Sandbox')
      .then((response) => {
        setName(response.name);
        setDescription(response.description);
        setApiKey(response.apiKeyToken);
      })
      .catch((error: any) => {
        navigate(ROUTE.PLATFORM_API_KEYS);
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  useEffect(() => {
    if (id && currentUser) {
      fetchData();
    }
  }, [id]);

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => ({
          isLoading: state.isLoading,
          defaultPlatformId: state.currentUser?.defaultPlatformId,
        }),
        () => {
          if (id && currentUser) {
            fetchData();
          }
        }
      ),
    []
  );

  return (
    <EditPage fullWidth>
      <EditTitle>
        <Headline>{id ? 'Edit API key' : 'Create API key'}</Headline>
        <Breadcrumb
          entries={[
            {
              label: (
                <>
                  <Icon.Layers />
                  Platform
                </>
              ),
              path: ROUTE.PLATFORM,
            },
            {
              label: 'API keys',
              path: ROUTE.PLATFORM_API_KEYS,
            },
            {
              label: id ? 'Edit' : 'Create',
            },
          ]}
        />
      </EditTitle>

      {currentPermission?.apiKeys !== 'write' && <RestrictedBanner />}

      <EditPage fullWidth>
        <EditSection>
          <EditSectionTitle>
            <Icon.List />
            General
          </EditSectionTitle>
          <EditBox data-disabled={currentPermission?.apiKeys !== 'write'}>
            <FormElement newRow>
              <FormLabel>Name</FormLabel>
              <Input value={name} onChange={setName} placeholder="Name" />
            </FormElement>
            <FormElement fullWidth>
              <FormLabel>Environment</FormLabel>
              {id ? (
                <FormText>
                  <Chip icon={URLType === 'Production' ? <Icon.CircleCheck /> : <Icon.Sandbox />}>{URLType}</Chip>
                </FormText>
              ) : (
                <SegmentedControl
                  options={[
                    {
                      label: (
                        <>
                          <Icon.CircleCheck />
                          Production
                        </>
                      ),
                      value: 'production',
                    },
                    {
                      label: (
                        <>
                          <Icon.Sandbox />
                          Sandbox
                        </>
                      ),
                      value: 'sandbox',
                    },
                  ]}
                  active={type}
                  onOptionChange={setType}
                  withIcon
                  isDisabled={!currentPlatform?.isLiveEnabled}
                />
              )}
            </FormElement>
            <FormElement fullWidth>
              <FormLabel>Description</FormLabel>
              <Textarea value={description} onChange={setDescription} placeholder="Description" />
            </FormElement>
          </EditBox>
        </EditSection>
        <EditSection>
          <EditSectionTitle>
            <Icon.Key />
            Keys
          </EditSectionTitle>
          <EditBox>
            <FormElement fullWidth>
              <FormLabel>API Key</FormLabel>
              <CopyInput value={apiKey} placeholder="Key Will be Generated" icon={<Icon.CopyCode />} />
            </FormElement>
          </EditBox>
        </EditSection>
        <EditToolbar>
          <Button onClick={() => navigate(-1)} variant="secondary">
            {id ? 'Back' : 'Cancel'}
          </Button>
          <Button onClick={handleSubmit} isDisabled={currentPermission?.apiKeys !== 'write'}>
            {id ? 'Save' : 'Create'}
          </Button>
        </EditToolbar>
      </EditPage>
    </EditPage>
  );
};
