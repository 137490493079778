import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { Icon, PasswordInput, ToggleHeight, Tooltip } from '@column/column-ui-kit';

export interface PasswordStrengthStyleProps {
  hasError: boolean;
  size: 'default' | 'small';
  variant: 'default' | 'light';
}

export interface PasswordInfo {
  score: number;
  suggestions: string[];
}

export interface PasswordStrengthProps extends Partial<PasswordStrengthStyleProps> {
  onChange?: (value: string) => void;
  onStrengthChange?: (value: PasswordInfo) => void;
  className?: string;
  value?: string;
  disallowedStrings?: string[];
}

const Wrapper = styled.div``;

const Info = styled(ToggleHeight)`
  --toggle-height-padding: 5px 0 0 0;
`;

const InfoInner = styled.div`
  display: flex;
  gap: 4px;
  letter-spacing: -0.125px;
  padding-left: 4px;
  line-height: 20px;
  font-size: 13px;
  white-space: nowrap;
  color: ${({ theme }) => theme.secondary.blendToBackground(700)};
`;

const Time = styled.span`
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

const Bulb = styled(Icon.Bulb)<{ opacity: number }>`
  --icon-color: ${({ theme }) => theme.secondary.blendToBackground(600)};

  path {
    transition:
      stroke 0.2s,
      opacity 0.2s;

    &:first-child {
      opacity: ${({ opacity }) => opacity};
    }
  }

  &:hover {
    --icon-color: ${({ theme }) => theme.secondary.background};
  }
`;

const TooltipContent = styled.div`
  text-align: left;
`;

const TooltipContentList = styled.ul`
  margin: 4px 0 0 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-gap: 4px;
`;

const TooltipContentListEntry = styled.li<{ isDone: boolean }>`
  display: flex;
  gap: 4px;
  line-height: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.blendToBackground(600)};

  ${({ isDone }) =>
    isDone &&
    css`
      color: ${({ theme }) => theme.success.background};
    `}

  svg {
    --icon-size: 14px;
    --icon-color: currentColor;
  }
`;

const TooltipContentListEntrySuggestion = styled.li`
  line-height: 14px;
  font-weight: 500;
  padding-left: 13px;
  position: relative;
  color: ${({ theme }) => theme.background};
  opacity: 0.75;

  &:before {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 5px;
    opacity: 0.5;
    background-color: currentColor;
  }
`;

const ErrorInfo = styled(Tooltip)<{ isShow?: boolean }>`
  margin-left: auto;
  transition:
    opacity 0.15s,
    color 0.15s;
  opacity: ${({ isShow }) => (isShow ? '1' : '0')};
  pointer-events: ${({ isShow }) => (isShow ? 'auto' : 'none')};
  color: ${({ theme }) => theme.danger.blendToBackground(800)};
  cursor: help;
  display: flex;
  gap: 4px;
  align-items: center;
  font-weight: 500;
  letter-spacing: -0.125px;
  line-height: 20px;
  font-size: 13px;

  svg {
    --icon-size: 18px;
  }

  &:hover {
    color: ${({ theme }) => theme.danger.background};
  }
`;

export const PasswordStrength = forwardRef<HTMLLabelElement, PasswordStrengthProps>((props, ref) => {
  const [currentValue, setCurrentValue] = useState<string>(props.value ?? '');
  const [result, setResult] = useState<string>('');
  const [passwordInfo, setPasswordInfo] = useState<PasswordInfo>({
    score: 0,
    suggestions: [],
  });

  const getDisallowedStrings = useCallback(
    (disallowedStrings?: string[]) => {
      const returnSuggestions: string[] = [];

      if (disallowedStrings) {
        disallowedStrings.forEach((notAllowed) => {
          if (currentValue.toLowerCase().includes(notAllowed.toLowerCase())) {
            const text = `Please don't include “${notAllowed}“.`;

            if (!returnSuggestions.includes(text)) {
              returnSuggestions.push(text);
            }
          }
        });
      }

      return returnSuggestions;
    },
    [currentValue]
  );

  const loadOptions = useCallback(async () => {
    const zxcvbnCommonPackage = await import('@zxcvbn-ts/language-common');
    const zxcvbnEnPackage = await import('@zxcvbn-ts/language-en');

    const translations = zxcvbnEnPackage.translations;

    translations.timeEstimation.ltSecond = 'just a second';

    return {
      translations,
      graphs: zxcvbnCommonPackage.adjacencyGraphs,
      dictionary: {
        ...zxcvbnCommonPackage.dictionary,
        ...zxcvbnEnPackage.dictionary,
        userInputs: props?.disallowedStrings ?? [],
      },
    };
  }, [props.disallowedStrings]);

  const atLeast8 = useMemo(() => {
    return /.{8,}/.test(currentValue);
  }, [currentValue]);

  const lowercaseUppercase = useMemo(() => {
    return /[a-z]/.test(currentValue) && /[A-Z]/.test(currentValue);
  }, [currentValue]);

  const numbered = useMemo(() => {
    return /\d/.test(currentValue);
  }, [currentValue]);

  const special = useMemo(() => {
    return /[^A-Za-z0-9]/.test(currentValue);
  }, [currentValue]);

  const opacity = useMemo(() => {
    return 0 + (atLeast8 ? 0.25 : 0) + (lowercaseUppercase ? 0.25 : 0) + (numbered ? 0.25 : 0) + (special ? 0.25 : 0);
  }, [currentValue]);

  useEffect(() => {
    if (!currentValue) {
      return;
    }

    const load = async () => {
      const { zxcvbn, zxcvbnOptions } = await import('@zxcvbn-ts/core');

      const options = await loadOptions();

      zxcvbnOptions.setOptions(options);

      const res = zxcvbn(currentValue);

      if (!active) {
        return;
      }

      const info: PasswordInfo = { score: res.score, suggestions: res.feedback.suggestions };

      const disallowedStrings = getDisallowedStrings(props.disallowedStrings);

      if (disallowedStrings.length > 0) {
        info.score = 0;
        info.suggestions = [...disallowedStrings, ...res.feedback.suggestions];
      }

      setResult(res.crackTimesDisplay.onlineNoThrottling10PerSecond);
      setPasswordInfo(info);

      if (props.onStrengthChange) {
        props.onStrengthChange(info);
      }
    };

    let active = true;

    load();

    return () => {
      active = false;
    };
  }, [currentValue, props.disallowedStrings]);

  const handleValueChange = (value: string) => {
    setCurrentValue(value);

    if (props.onChange) {
      props.onChange(value);
    }
  };

  useEffect(() => {
    setCurrentValue(props.value ?? '');
  }, [props.value]);

  const styleProps: PasswordStrengthStyleProps = {
    hasError: props.hasError ?? false,
    size: props.size ?? 'default',
    variant: props.variant ?? 'default',
  };

  return (
    <Wrapper className={props.className}>
      <PasswordInput
        placeholder="Password"
        ref={ref}
        onChange={handleValueChange}
        value={props.value}
        {...styleProps}
      />
      <Info isClose={currentValue.length < 1 || !result}>
        <InfoInner>
          {currentValue.length > 0 && (
            <Tooltip
              content={
                <TooltipContent>
                  Password strength
                  <TooltipContentList>
                    <TooltipContentListEntry isDone={lowercaseUppercase}>
                      {lowercaseUppercase ? <Icon.CircleCheck /> : <Icon.CircleCross />}
                      Uppercase &amp; lowercase
                    </TooltipContentListEntry>
                    <TooltipContentListEntry isDone={numbered}>
                      {numbered ? <Icon.CircleCheck /> : <Icon.CircleCross />}
                      Numbers
                    </TooltipContentListEntry>
                    <TooltipContentListEntry isDone={special}>
                      {special ? <Icon.CircleCheck /> : <Icon.CircleCross />}
                      Special characters
                    </TooltipContentListEntry>
                    <TooltipContentListEntry isDone={atLeast8}>
                      {atLeast8 ? <Icon.CircleCheck /> : <Icon.CircleCross />}
                      At least 8 characters
                    </TooltipContentListEntry>
                  </TooltipContentList>
                </TooltipContent>
              }
              placement="bottom-start"
              offsetArrowX={-90}
              offsetX={-4}
              defaultWidth="184px"
            >
              <Bulb opacity={opacity} />
            </Tooltip>
          )}
          Time to hack: <Time>{result}</Time>
          <ErrorInfo
            isShow={styleProps.hasError}
            content={
              <TooltipContent>
                Suggestions
                <TooltipContentList>
                  {passwordInfo.suggestions.map((text) => (
                    <TooltipContentListEntrySuggestion key={text}>{text}</TooltipContentListEntrySuggestion>
                  ))}
                </TooltipContentList>
              </TooltipContent>
            }
          >
            <Icon.CircleQuestionmark />
            <span>Help</span>
          </ErrorInfo>
        </InfoInner>
      </Info>
    </Wrapper>
  );
});
