import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Icon, Input, Button, Dropdown } from '@column/column-ui-kit';
import { Buttons } from './Alert';
import { Wrapper, IconCircle, Text } from './Authenticate';
import { Form } from './Counterparty';
import { useNotificationStore } from '~/stores/Notification';
import { useModalStore, ModalType } from '~/stores/Modal';
import { NotificationList } from '~/components';
import { FormElement, FormLabel, Headline, Line, Paragraph } from '~/styles';
import { CreatePlatformInviteRequest, InviteRepository, PlatformRoleName, userGroupsDropdown } from '~/repositories';
import { validateEmail } from '~/util';
import { useHelpSidebarStore } from '~/stores/HelpSidebar';

interface InviteEntry {
  email: string;
  platformRole: string;
}

const InviteWrapper = styled(Wrapper)`
  --modal-width: 500px;
`;

const Fields = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: auto-fill;
`;

const Inner = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const Columns = styled.div`
  margin-top: -2px;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 40% auto;
`;

const AddAnother = styled(Button)`
  margin-top: 4px;
  padding: 0;
  justify-self: start;
`;

const Mailbox = styled(Icon.AnimationMailbox)`
  --icon-background: ${({ theme }) => theme.primary.blendToBackground(100)};
`;

const Email = styled(Input)`
  display: flex;
  align-items: center;

  & > div {
    flex-grow: 1;
  }
`;

const Remove = styled(Icon.Cross)`
  --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  cursor: pointer;

  path {
    transition: stroke 0.2s;
  }

  &:hover {
    --icon-color: ${({ theme }) => theme.secondary.background};
  }
`;

const RoleHelpButton = styled(Button)`
  padding: 0;
  font: inherit;
  color: inherit;

  &:hover {
    color: ${({ theme }) => theme.primary.background};
  }
`;

export const ModalInvite: React.FC = () => {
  const { closeModal, getModalData } = useModalStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const openHelpSidebar = useHelpSidebarStore((state) => state.openHelpSidebar);
  const modalTypes: ModalType[] = ['Invite'];

  const [inviteEntry, setInviteEntry] = useState<InviteEntry[]>([{ platformRole: 'admin', email: '' }]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const platformId = getModalData()?.platformId;

    if (!platformId) {
      addDangerNotification({
        content: 'No Platform ID provided, please reload and try again',
        display: 'popup',
      });
      return;
    }

    const validEmails = inviteEntry.filter(
      (entry: InviteEntry) => entry.email.length > 0 && validateEmail(entry.email)
    );

    if (validEmails.length < 1) {
      addDangerNotification({
        content: 'Enter a valid email address',
        display: 'popup',
      });
      return;
    }

    Promise.all(
      validEmails.map((entry: InviteEntry) => {
        const request: CreatePlatformInviteRequest = {
          platformId,
          email: entry.email,
          platformRole: entry.platformRole as PlatformRoleName,
        };

        InviteRepository.create(request);
      })
    )
      .then(() => {
        if (getModalData()?.callback) {
          setTimeout(() => getModalData()?.callback(), 100);
        }
        closeModal();
        addSuccessNotification({
          content:
            validEmails.length === 1
              ? `Invite sent to "${validEmails.at(0)?.email}".`
              : `Invites sent to "${validEmails.map((emailEntry) => emailEntry.email).join(', ')}".`,
          display: 'invite-list',
        });
      })
      .catch((error: any) => {
        addDangerNotification({
          content: error.message,
          display: 'popup',
        });
      });
  };

  const handleClose = (e: React.FormEvent) => {
    e.preventDefault();

    closeModal();
  };

  const handleAddEmail = () => {
    setInviteEntry((s) => [...s, { platformRole: 'admin', email: '' }]);
  };

  const removeEmail = (e: React.MouseEvent, index: number) => {
    e.stopPropagation();

    setInviteEntry((s) => s.filter((_, i: number) => i !== index));
  };

  useEffect(
    () =>
      useModalStore.subscribe(
        (state) => state.openModals,
        () => {
          setInviteEntry([{ platformRole: 'admin', email: '' }]);
        }
      ),
    []
  );

  return (
    <InviteWrapper modalTypes={modalTypes}>
      <IconCircle>
        <Mailbox
          counter={
            inviteEntry.filter((entry: InviteEntry) => entry.email.length > 0 && validateEmail(entry.email)).length
          }
        />
      </IconCircle>
      <Text>
        <Headline size="small">Invite users</Headline>
        <Paragraph>Select a role for each email.</Paragraph>
      </Text>
      <NotificationList display="popup" />
      <Form onSubmit={handleSubmit}>
        <Fields>
          <Inner>
            {inviteEntry.map((entry: InviteEntry, index: number) => (
              <Inner key={index}>
                {index !== 0 && <Line />}
                <Columns>
                  <FormElement>
                    <FormLabel>
                      {index === 0 ? (
                        <RoleHelpButton
                          icon={<Icon.CircleQuestionmark />}
                          iconRight
                          onClick={() => openHelpSidebar('Roles')}
                          variant="subtle"
                          size="tiny"
                          type="button"
                        >
                          Role
                        </RoleHelpButton>
                      ) : (
                        'Role'
                      )}
                    </FormLabel>
                    <Dropdown
                      placeholder="Select role"
                      onChange={(value: string) => {
                        setInviteEntry((s) =>
                          s.map((e: InviteEntry, i: number) => ({
                            platformRole: i === index ? value : e.platformRole,
                            email: e.email,
                          }))
                        );
                      }}
                      active={entry.platformRole}
                      options={userGroupsDropdown.filter((g) => g.value !== 'owner')}
                      fullWidth
                      variant="muted"
                    />
                  </FormElement>
                  <FormElement>
                    <FormLabel>Email</FormLabel>
                    <Email
                      value={entry.email}
                      onChange={(value: string) => {
                        setInviteEntry((s) =>
                          s.map((e: InviteEntry, i: number) => ({
                            platformRole: e.platformRole,
                            email: i === index ? value : e.email,
                          }))
                        );
                      }}
                      placeholder="Email address"
                      hasError={entry.email.length > 0 && !validateEmail(entry.email)}
                    >
                      {index !== 0 && <Remove onClick={(e: React.MouseEvent) => removeEmail(e, index)} />}
                    </Email>
                  </FormElement>
                </Columns>
              </Inner>
            ))}
            {inviteEntry.length < 5 && (
              <AddAnother size="small" type="button" icon={<Icon.Plus />} variant="subtle" onClick={handleAddEmail}>
                Add Another
              </AddAnother>
            )}
          </Inner>
        </Fields>
        <Buttons grow>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button icon={<Icon.Send />}>Send</Button>
        </Buttons>
      </Form>
    </InviteWrapper>
  );
};
