import React, { createRef, FormEvent, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { PasswordInput, PhoneInput, Icon, Button, Input, Tooltip } from '@column/column-ui-kit';
import { PlaneButton } from '~/elements';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { useModalStore } from '~/stores/Modal';
import { Box, FormElement, FormLabel } from '~/styles';
import { UserRepository } from '~/repositories';
import { Notification } from '~/components';
import { log } from '~/util';
import { useAddress } from '~/hooks';
import { reCaptchaCheck } from '~/util/reCaptcha';

const Grid = styled.div`
  display: grid;
  grid-gap: 32px;
`;

const Section = styled(Box)`
  padding: 24px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 24px;

  @media (max-width: 1400px) {
    grid-template-columns: 25% repeat(2, minmax(0, 1fr));
  }

  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 20px 24px;
  }
`;

const Headline = styled.div<{ oneLine?: boolean }>`
  display: flex;
  gap: 8px;
  color: ${({ theme }) => theme.foreground};

  @media (max-width: 1300px) {
    grid-column: 1 / 3;
  }

  svg {
    --icon-color: ${({ theme }) => theme.secondary.background};
    --icon-size: 20px;
  }

  ${({ oneLine }) =>
    !oneLine &&
    css`
      grid-row: 1 / span 2;
    `}
`;

const HeadlineText = styled.div<{ oneLine?: boolean }>`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: -2px 0;
`;

const Info = styled.div`
  display: flex;
  gap: 16px;
  align-self: end;
`;

const Resend = styled(Button)`
  min-width: 108px;
`;

const Action = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const NotificationContainer = styled.div`
  position: relative;
  width: 196px;
  height: 40px;
`;

const NotificationItem = styled.div<{ show: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  transition:
    opacity 0.2s,
    transform 0.2s;

  ${({ show }) =>
    !show &&
    css`
      transform: translateY(-4px);
      opacity: 0;
      pointer-events: none;
    `}

  ${({ show }) =>
    show &&
    css`
      transition-delay: 0.15s;
    `}
`;

export const PagePersonal: React.FC = () => {
  const { isLoading, currentUser } = useSessionStore();
  const { addSuccessNotification, addDangerNotification, addInfoNotification } = useNotificationStore();
  const openModal = useModalStore((state) => state.openModal);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordRepeat, setNewPasswordRepeat] = useState<string>('');
  const { countries } = useAddress({ types: ['country'] });

  const notificationContainer = createRef<HTMLDivElement>();

  const { handleReCaptcha } = reCaptchaCheck();

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    setFirstName(currentUser.firstName ?? '');
    setLastName(currentUser.lastName ?? '');
    setEmail(currentUser.email ?? '');
    setPhoneNumber(currentUser.phoneNumber ?? '');
  }, [currentUser, isLoading]);

  if (!currentUser) {
    return null;
  }

  const handleVerifyEmail = (resend?: boolean) => {
    currentUser.createVerifyEmail();

    log({
      name: `User email verify${resend ? ' resent' : ''}`,
      context: currentUser,
    });

    if (!resend) {
      return;
    }

    addSuccessNotification({
      content: 'Verification email resent! Check your inbox.',
      display: 'page',
    });
  };

  const handleEnableMfa = () => {
    if (!phoneNumber) {
      addDangerNotification({
        content: 'Add a valid phone number first.',
        display: 'page',
      });
      return;
    }

    handleReCaptcha(
      (reCaptchaToken) =>
        currentUser
          .update(
            {
              firstName,
              lastName,
              email,
              phoneNumber,
              defaultPlatformId: '',
            },
            reCaptchaToken
          )
          .then(() => {
            handleReCaptcha(
              (reCaptchaTokenMfa) =>
                currentUser
                  .setupMfa(reCaptchaTokenMfa)
                  .then(() => {
                    log({
                      name: 'User enable MFA requested',
                      context: currentUser,
                    });

                    addInfoNotification({
                      content: 'Please confirm your phone number to finish 2FA setup.',
                      display: 'page',
                    });
                  })
                  .catch((error) => {
                    addDangerNotification({
                      content: error.message,
                      display: 'page',
                    });
                  }),
              (err) => {
                addDangerNotification({
                  content: err.message,
                  display: 'page',
                });
              }
            );
          })
          .catch((error) => {
            addDangerNotification({
              content: error.message,
              display: 'page',
            });
          }),
      (err) => {
        addDangerNotification({
          content: err.message,
          display: 'page',
        });
      }
    );
  };

  const handleConfirmMfa = () => {
    openModal('Authenticate');
  };

  const handleSave = (event: FormEvent) => {
    event.preventDefault();

    handleReCaptcha(
      (reCaptchaToken) =>
        currentUser
          .update(
            {
              firstName,
              lastName,
              email,
              phoneNumber,
            },
            reCaptchaToken
          )
          .then(() => {
            addSuccessNotification({
              content: 'User data updated',
              display: 'page',
            });
          })
          .catch((error) => {
            addDangerNotification({
              content: error.message,
              display: 'page',
            });
          }),
      (err) => {
        addDangerNotification({
          content: err.message,
          display: 'page',
        });
      }
    );

    if (newPassword) {
      if (newPassword !== newPasswordRepeat) {
        addDangerNotification({
          content: 'Passwords are not the same',
          display: 'page',
        });
        return;
      }

      UserRepository.updatePassword({
        currentPassword,
        newPassword,
      })
        .then(() => {
          addSuccessNotification({
            content: 'Password changed',
            display: 'page',
          });
        })
        .catch((error) => {
          addDangerNotification({
            content: error.message,
            display: 'page',
          });
        });
    }
  };

  return (
    <form autoComplete="off" onSubmit={handleSave}>
      <Grid>
        <Section>
          <Headline>
            <Icon.User />
            <HeadlineText>Personal information</HeadlineText>
          </Headline>
          <FormElement>
            <FormLabel>First Name</FormLabel>
            <Input value={firstName} onChange={(value: string) => setFirstName(value)} placeholder="First name" />
          </FormElement>
          <FormElement>
            <FormLabel>Last Name</FormLabel>
            <Input value={lastName} onChange={(value: string) => setLastName(value)} placeholder="Last name" />
          </FormElement>
          <FormElement>
            <FormLabel>Email</FormLabel>
            <Input value={email} onChange={(value: string) => setEmail(value)} placeholder="Email" />
          </FormElement>
          <Info>
            {!currentUser.isEmailVerified &&
              (currentUser.isEmailPending ? (
                <Resend type="button" onClick={() => handleVerifyEmail(true)} icon={<Icon.Mail />} variant="muted">
                  Resend
                </Resend>
              ) : (
                <PlaneButton
                  onDone={() => handleVerifyEmail()}
                  icon={<Icon.Mail />}
                  success={
                    <Button onClick={() => handleVerifyEmail()} icon={<Icon.Mail />} variant="muted">
                      Resend
                    </Button>
                  }
                >
                  Verify
                </PlaneButton>
              ))}
            <NotificationContainer ref={notificationContainer}>
              <NotificationItem show={currentUser.isEmailPending}>
                <Notification color="info" variant="light" withClose={false}>
                  Verification pending
                </Notification>
              </NotificationItem>
              <NotificationItem show={currentUser.isEmailVerified}>
                <Notification variant="light" withClose={false}>
                  Email verified
                </Notification>
              </NotificationItem>
              <NotificationItem show={!currentUser.isEmailPending && !currentUser.isEmailVerified}>
                <Notification color="danger" variant="light" withClose={false}>
                  Email not verified
                </Notification>
              </NotificationItem>
            </NotificationContainer>
          </Info>
        </Section>
        <Section>
          <Headline oneLine>
            <Icon.Phone />
            <HeadlineText>Two-factor authentication</HeadlineText>
          </Headline>
          <FormElement>
            <FormLabel>Phone Number</FormLabel>
            <PhoneInput
              value={phoneNumber}
              onChange={(value: string) => setPhoneNumber(value)}
              placeholder="Phone number"
              countryList={countries}
            />
          </FormElement>
          <Info>
            {currentUser.isMfaPending ? (
              <Button onClick={handleConfirmMfa} icon={<Icon.CircleCheck />} variant="muted">
                Confirm
              </Button>
            ) : (
              !currentUser.isMfaVerified &&
              (currentUser.isEmailVerified ? (
                <Button onClick={handleEnableMfa} icon={<Icon.PhoneAdd />} variant="muted">
                  Enable
                </Button>
              ) : (
                <Tooltip content="Verify email first">
                  <Button icon={<Icon.PhoneAdd />} variant="muted" isDisabled>
                    Enable
                  </Button>
                </Tooltip>
              ))
            )}
            {currentUser.isMfaPending ? (
              <Notification color="info" variant="light" withClose={false}>
                In progress
              </Notification>
            ) : currentUser.isMfaVerified ? (
              <Notification variant="light" withClose={false}>
                Enabled
              </Notification>
            ) : (
              <Notification color="warning" variant="light" withClose={false}>
                Not enabled
              </Notification>
            )}
          </Info>
        </Section>
        <Section>
          <Headline>
            <Icon.Shield />
            <HeadlineText>Password</HeadlineText>
          </Headline>
          <FormElement>
            <FormLabel>New Password</FormLabel>
            <PasswordInput value={newPassword} onChange={setNewPassword} placeholder="New password" />
          </FormElement>
          <FormElement>
            <FormLabel>Repeat Password</FormLabel>
            <PasswordInput value={newPasswordRepeat} onChange={setNewPasswordRepeat} placeholder="Repeat password" />
          </FormElement>
          <FormElement>
            <FormLabel>Current Password</FormLabel>
            <PasswordInput value={currentPassword} onChange={setCurrentPassword} placeholder="Current password" />
          </FormElement>
        </Section>
        <Action>
          <Button type="submit">Save</Button>
        </Action>
      </Grid>
    </form>
  );
};
