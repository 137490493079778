import React, { FC, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Icon, Chip, Fade } from '@column/column-ui-kit';
import {
  AccountInformation,
  AmountInfo,
  AmountText,
  TimelineArea,
  TimelineEntryProps,
  TransferObjectWrapper,
  TransferObject,
  loopObject,
  TransferParty,
  Label,
  PartyName,
  SourceField,
  PartyFields,
  Arrow,
} from './View';
import {
  EditBox,
  EditSection,
  EditSectionTitle,
  EditSectionInfo,
  EditPage,
  EditToolbar,
  EditTitle,
  Headline,
  FormElement,
  FormLabel,
  Line,
  FormText,
} from '~/styles';
import { Breadcrumb, CopyInput, ImageLoading, NotificationList } from '~/components';
import { ROUTE } from '~/app/routes';
import {
  BankAccountRepository,
  CheckRepository,
  CheckTransfer,
  CheckTransferImages,
  transferStatusTooltips,
} from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { formatNumber, formatString, generateFields, extractAccountAndCheckNumber } from '~/util';
import { useModalStore } from '~/stores/Modal';
import { useTransfer } from '~/hooks/useTransfers';
import { LogoLoading } from '~/elements';
import { useBankAccount } from '~/hooks';

interface Params {
  id: string;
}

const checkTypes: TimelineEntryProps[] = [
  {
    label: 'Created',
    field: 'createdAt',
  },
  {
    label: 'Deposited',
    field: 'depositedAt',
  },
  {
    label: 'Pending deposit',
    field: 'PendingDepositAt',
  },
  {
    label: 'Settled',
    field: 'settledAt',
  },
  {
    label: 'Pending first return',
    field: 'pendingFirstReturnAt',
  },
  {
    label: 'First return',
    field: 'firstReturnAt',
  },
  {
    label: 'Reclear',
    field: 'reclearAt',
  },
  {
    label: 'Second return',
    field: 'secondReturnAt',
  },
  {
    label: 'Stopped',
    field: 'stoppedAt',
  },
  {
    label: 'Pending user initiated return',
    field: 'pendingUserInitiatedReturnAt',
  },
  {
    label: 'User initiated returned',
    field: 'userInitiatedReturnedAt',
  },
];

const StyledFade = styled(Fade)`
  margin-top: 10%;
`;

const Tags = styled.div`
  display: flex;
  gap: 12px;
`;

const List = styled.div`
  display: flex;
  gap: 16px;
`;

const Timeline = styled(EditBox)`
  display: flex;
  align-items: stretch;
  gap: 16px;
`;

const StyledLine = styled(Line)`
  margin-left: -20px;
  margin-right: -20px;
  width: auto;
`;

export const PageTransfersCheck: FC = () => {
  const { addDangerNotification, addSuccessNotification } = useNotificationStore();
  const openModal = useModalStore((state) => state.openModal);
  const navigate = useNavigate();
  const { id } = useParams<keyof Params>() as Params;
  const { response: bankAccount, createRequest: fetchBankAccount } = useBankAccount({
    onError: (error) => {
      addDangerNotification({
        content: error.message,
        display: 'page',
      });
    },
    onInitialLoad: (response) => {
      console.info('bank account data', response);
    },
  });

  const {
    response: transfer,
    createRequest: fetchCheck,
    isLoading,
  } = useTransfer<CheckTransfer>('check')({
    initialParams: { id },
    onError: (error) => {
      addDangerNotification({
        content: error.message,
        display: 'page',
      });

      navigate(ROUTE.TRANSFERS);
    },
    onSuccess: (response) => {
      fetchAccountNumber(response.accountNumberId);

      fetchBankAccount({ id: response.bankAccountId });

      CheckRepository.getImages(id)
        .then(async (images) => {
          setTransferImages({
            frontImageJpeg: `data:image/jpeg;base64,${images.frontImageJpeg}`,
            backImageJpeg: `data:image/jpeg;base64,${images.backImageJpeg}`,
          });
        })
        .catch(() => {
          setTransferImages({
            frontImageJpeg: undefined,
            backImageJpeg: undefined,
          });
        });
    },
  });
  const [transferImages, setTransferImages] = useState<CheckTransferImages>({});
  const [accountNumber, setAccountNumber] = useState<any>({});

  const handleReturn = () => {
    openModal('CheckReturn', {
      checkTransferId: id,
      callback: fetchCheck,
    });
  };

  const handleStop = () => {
    if (!transfer?.id) {
      return;
    }

    CheckRepository.stopPayment(transfer.id)
      .then(() => {
        fetchCheck();

        addSuccessNotification({
          content: 'Stop payment created',
          display: 'page',
        });
      })
      .catch((error: any) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  const fetchAccountNumber = (accountNumberId?: string) => {
    if (!accountNumberId) {
      return;
    }

    BankAccountRepository.getAccountNumber({ id: accountNumberId })
      .then((response: any) => {
        setAccountNumber(response);
      })
      .catch((error: any) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });

        navigate(ROUTE.TRANSFERS);
      });
  };

  const { accountNumber: checkAccountNumber, checkNumber } = useMemo(
    () => extractAccountAndCheckNumber(transfer?.micrLine.onUs ?? '', transfer?.micrLine.auxiliaryOnUs ?? ''),
    [transfer?.micrLine.onUs, transfer?.micrLine.auxiliaryOnUs]
  );

  const updatedAt = transfer?.updatedAt as unknown as Date;

  return (
    <>
      <StyledFade show={isLoading}>
        <LogoLoading />
      </StyledFade>
      <Fade show={!isLoading}>
        <EditPage>
          <EditToolbar>
            <EditTitle>
              <Headline>View Check</Headline>
              <Breadcrumb
                entries={[
                  {
                    label: (
                      <>
                        <Icon.Swap />
                        Transfers
                      </>
                    ),
                    path: ROUTE.TRANSFERS,
                  },
                  {
                    label: 'View',
                  },
                ]}
              />
            </EditTitle>
            <List>
              {(transfer?.status === 'issued' || transfer?.status === 'manual_review') && (
                <Button variant="danger" size="small" onClick={handleStop}>
                  Stop Payment
                </Button>
              )}
              {transfer?.status === 'settled' && (
                <Button variant="secondary" onClick={handleReturn}>
                  Return
                </Button>
              )}
            </List>
          </EditToolbar>
          <div>
            <NotificationList display="page" />
            <EditPage>
              <EditBox>
                <AmountInfo>
                  <AmountText>
                    {formatNumber(
                      transfer?.type?.toUpperCase() === 'DEBIT'
                        ? transfer?.positivePayAmount
                        : transfer?.depositedAmount
                    )}
                  </AmountText>
                  <Tags>
                    {transfer?.status && (
                      <Chip
                        tooltip={
                          transferStatusTooltips.has(transfer.status)
                            ? {
                                content: transferStatusTooltips.get(transfer.status),
                                delay: 200,
                                triggerClick: false,
                              }
                            : undefined
                        }
                        type={
                          ['stopped'].includes(transfer.status)
                            ? 'danger'
                            : ['deposited'].includes(transfer.status)
                              ? 'success'
                              : 'default'
                        }
                      >
                        {formatString(transfer.status)}
                      </Chip>
                    )}
                    {transfer?.type && <Chip>{formatString(transfer.type)}</Chip>}
                  </Tags>
                </AmountInfo>
                <FormElement>
                  <FormLabel>ID</FormLabel>
                  <CopyInput value={id} />
                </FormElement>
              </EditBox>

              <EditSection>
                <EditSectionTitle>
                  <Icon.CircleInfo />
                  Information
                </EditSectionTitle>
                <EditBox>
                  <SourceField>
                    <PartyFields>
                      {transfer?.bankAccountId && (
                        <>
                          <TransferParty>
                            <Label>Bank Account</Label>
                            <PartyName>
                              <a href={`${ROUTE.BANK_ACCOUNTS}/edit/${transfer.bankAccountId}`}>
                                {bankAccount?.displayName ? bankAccount?.displayName : bankAccount?.description}
                              </a>
                            </PartyName>
                          </TransferParty>

                          <FormElement newRow>
                            <FormLabel>Bank Account ID</FormLabel>
                            <CopyInput value={transfer.bankAccountId} />
                          </FormElement>
                        </>
                      )}
                      {transfer?.accountNumberId && (
                        <>
                          {accountNumber.routingNumber && accountNumber.accountNumber && (
                            <AccountInformation>
                              <FormElement>
                                <FormLabel>Routing Number</FormLabel>
                                <CopyInput value={accountNumber.routingNumber} />
                              </FormElement>
                              <FormElement>
                                <FormLabel>Account Number</FormLabel>
                                <CopyInput value={accountNumber.accountNumber} />
                              </FormElement>
                            </AccountInformation>
                          )}
                        </>
                      )}
                    </PartyFields>
                    {transfer?.status === 'issued' ? <Arrow variant="right" /> : <Arrow variant="left" />}
                  </SourceField>

                  <TransferParty>
                    <Label>Payee Name</Label>
                    <PartyName>{(transfer?.payeeName ?? transfer?.description) || '-'}</PartyName>
                  </TransferParty>

                  <FormElement fullWidth as={StyledLine} />
                  {!!transfer &&
                    generateFields<CheckTransfer>(
                      [
                        {
                          type: 'text',
                          label: 'Description',
                          value: 'description',
                          newRow: true,
                        },
                        {
                          type: 'text',
                          label: 'Idempotency Key',
                          value: 'idempotencyKey',
                        },
                      ],
                      transfer,
                      undefined
                    )}

                  {transfer?.type?.toUpperCase() === 'DEBIT' && (
                    <FormElement>
                      <FormLabel>Positive pay amount</FormLabel>
                      <FormText>{formatNumber(transfer.positivePayAmount)}</FormText>
                    </FormElement>
                  )}
                </EditBox>
              </EditSection>

              <EditSection>
                <EditSectionTitle>
                  <Icon.CircleInfo />
                  MICR line
                </EditSectionTitle>
                <EditBox>
                  {!!transfer &&
                    generateFields<CheckTransfer>(
                      [
                        {
                          type: 'text',
                          label: 'Payor Bank Routing Number',
                          value: 'payorBankRoutingNumber',
                        },
                        {
                          type: 'text',
                          label: 'Auxiliary On Us',
                          value: 'auxiliaryOnUs',
                        },
                        {
                          type: 'text',
                          label: 'On Us',
                          value: 'onUs',
                        },
                      ],
                      transfer,
                      undefined,
                      'micrLine'
                    )}

                  <FormElement>
                    <FormLabel>Account Number</FormLabel>
                    <FormText>{checkAccountNumber || '-'}</FormText>
                  </FormElement>

                  <FormElement>
                    <FormLabel>Check Number</FormLabel>
                    <FormText>{checkNumber || '-'}</FormText>
                  </FormElement>

                  <FormElement>
                    <FormLabel>External Processing Code</FormLabel>
                    <FormText>{transfer?.micrLine.externalProcessingCode || '-'}</FormText>
                  </FormElement>
                </EditBox>
              </EditSection>

              <EditSection>
                <EditToolbar>
                  <EditSectionTitle>
                    <Icon.Checklist />
                    Events
                  </EditSectionTitle>
                  {transfer?.updatedAt && (
                    <EditSectionInfo>
                      <small>Updated</small>
                      {updatedAt.toLocaleString('en-US', { weekday: 'long' })},{' '}
                      {updatedAt.toLocaleString('en-US', { month: 'long' })} {updatedAt.getDate()},{' '}
                      {updatedAt.getFullYear()} —{' '}
                      {updatedAt.toLocaleString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                      })}
                    </EditSectionInfo>
                  )}
                </EditToolbar>
                <Timeline>
                  {checkTypes.map((entry) => (
                    <TimelineArea key={entry.field} transfer={transfer} entry={entry} />
                  ))}
                </Timeline>
              </EditSection>

              <EditSection>
                <EditSectionTitle>
                  <Icon.Layers />
                  Images
                </EditSectionTitle>
                <EditBox>
                  <FormElement>
                    <FormLabel>Front</FormLabel>
                    <ImageLoading
                      base64String={transferImages.frontImageJpeg}
                      errorMessage="Front image could not be loaded"
                    />
                  </FormElement>
                  <FormElement>
                    <FormLabel>Back</FormLabel>
                    <ImageLoading
                      base64String={transferImages.backImageJpeg}
                      errorMessage="Back image could not be loaded"
                    />
                  </FormElement>
                </EditBox>
              </EditSection>

              <EditSection>
                <EditSectionTitle>
                  <Icon.Code />
                  Transfer Details
                </EditSectionTitle>
                <EditBox oneColumn>
                  <TransferObjectWrapper>
                    <TransferObject>{transfer && loopObject(transfer)}</TransferObject>
                  </TransferObjectWrapper>
                </EditBox>
              </EditSection>
            </EditPage>
          </div>
        </EditPage>
      </Fade>
    </>
  );
};
