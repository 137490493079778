import React, { forwardRef } from 'react';
import styled from 'styled-components';

interface EventBookTransferProps {
  size?: string;
}

const SVG = styled.svg`
  overflow: visible;
`;

export const EventBookTransfer = forwardRef<SVGSVGElement, EventBookTransferProps>((props, ref) => {
  const size = props.size ?? '20px';

  return (
    <SVG
      {...props}
      ref={ref}
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99833 17.5V17.5C5.8575 17.5 2.5 14.1425 2.5 10.0017V9.99833C2.5 5.8575 5.8575 2.5 9.99833 2.5H10.0017C14.1425 2.5 17.5 5.8575 17.5 9.99833V9.99833C17.5 14.1417 14.1417 17.5 9.99833 17.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5742 9.58301L13.3242 8.33301L12.0742 9.58301"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.41675 10.417L6.66675 11.667L7.91675 10.417"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66675 11.667V8.33366C6.66675 7.41283 7.41258 6.66699 8.33341 6.66699H10.8334"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3242 8.33301V11.6663C13.3242 12.583 12.5742 13.333 11.6576 13.333H9.16675"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
});
